<div class="view-order">
    <div class="header-wrapper d-flex justify-content-between align-item">
        <span class="info-text nunito">Information About Request</span>
        <i class="material-icons cursor-pointer" mat-dialog-close>clear</i>
    </div>

    <div class="order-request nunito">
        <strong class="request-heading font-14">Additional Request :</strong>
        <span class="request-data font-14">{{this.message.length > 0?this.message:'N/A'}}</span>
    </div>

    <div class="order-table">

        <table>
            <tr>
                <th>Item</th>
                <th>Qty.</th>
            </tr>
            <tr *ngFor="let item of this.cartItems">
                <td>{{item.Name}}</td>
                <td>{{item.Quantity}}</td>
            </tr>
        </table>

    </div>
</div>