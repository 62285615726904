<div class="inner-loader" *ngIf="showLoader">
  <mat-progress-spinner class="tp-margin" [color]="color" [mode]="mode">
  </mat-progress-spinner>
</div>

<!-- Home list -->
<div class="home-page row" *ngIf="serviceLocationStatus == 'home'">
  <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12"
    *ngFor="let obj of serviceResponseArrayModel.ServiceLists| slice:0:4;">
    <div class="wrapper-list-thumb">
      <div class="room-img-wrapper">
        <a [routerLink]="['/hotel-services',obj.id]">
          <img [src]="obj.ServiceImages[0]" *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
          <ng-template #otherImage>
            <img src="assets/images/No_Image_Available.jpg" />
          </ng-template>
        </a>
        <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

          <ng-container *ngIf="obj.DateTime == '1'">
            <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

              <ng-container *ngIf="service.DaysNames">
                <span *ngFor="let item of service.DaysNames" class="date-from">
                  <ng-container *ngIf="item.Monday">
                    {{'Mon'}}
                  </ng-container>
                  <ng-container *ngIf="item.Tuesday">
                    {{'Tue'}}
                  </ng-container>
                  <ng-container *ngIf="item.Wednesday">
                    {{'Wed'}}
                  </ng-container>
                  <ng-container *ngIf="item.Thursday">
                    {{'Thu'}}
                  </ng-container>
                  <ng-container *ngIf="item.Friday">
                    {{'Fri'}}
                  </ng-container>
                  <ng-container *ngIf="item.Saturday">
                    {{'Sat'}}
                  </ng-container>
                  <ng-container *ngIf="item.Sunday">
                    {{'Sun'}}
                  </ng-container>
                </span>
              </ng-container>

              <span class="time-from">
                <ng-container *ngIf="service.FromTime">
                  {{service.FromTime|timeFormat}} 
                </ng-container>
                <ng-container *ngIf="service.ToTime">
                  - {{service.ToTime|timeFormat}}
                </ng-container>
              </span>
            </span>
          </ng-container>
          <ng-container *ngIf="obj.DateTime == '2'">
            <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
              <span class="date-from">
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateFrom|dateFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateTo|dateFormat}}
                </ng-container>
              </span>
              <span class="time-from">
                <ng-container *ngIf="tour.TimeFrom">
                  {{tour.TimeFrom|timeFormat}} 
                </ng-container>
                <ng-container *ngIf="tour.TimeTo">
                 - {{tour.TimeTo|timeFormat}}
                </ng-container>
              </span>

            </p>
          </ng-container>
        </span>
      </div>

      <div class="list-thumb-content">
        <p class="type-info secondary-text"
          *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department); else otherDepartment">
          <span>{{obj?.Department?.Department[0]?.Value}}</span>
        </p>
        <ng-template #otherDepartment>
          <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
        </ng-template>
        <p class="title-info" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else  otherService">
          <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{obj.ServiceName[0].Value}}</a>
        </p>
        <ng-template #otherService>
          <p class="title-info"><a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{
              'NotAvailable'|translate }}</a></p>
        </ng-template>

        <!-- <span>
          <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
            (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
        </span> -->

      </div>
    </div>
  </div>
</div>

<!-- //Home list -->

<!-- Home Page Menu Page -->
<div class="home-page" *ngIf="serviceLocationStatus == 'homePageMenu'">
  <div class="row mx-0 "
    *ngIf="!globalService.validateIsEmpty(serviceResponseArrayModel.ServiceLists) && serviceResponseArrayModel.ServiceLists.length else otherServiceLists">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12  mb-3"
      *ngFor="let obj of serviceResponseArrayModel.ServiceLists">
      <div class="wrapper-list-thumb position-relative wrapper-list-thumb-manage">
        <div class="delete-image"> <i class="material-icons" (click)="deactivateFromHome(obj.id)"
            [appAccessCheck]="'homepage-menu'" [givenButton]="'delete'">delete</i></div>
        <div class="room-img-wrapper">
          <span *ngIf="obj.ServiceImages && obj.ServiceImages.length" class="span-image-wrapper">
            <img [src]="obj.ServiceImages[0]" />
            <ng-template #otherImage>
              <img src="assets/images/No_Image_Available.jpg" />
            </ng-template>
          </span>
          <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

            <ng-container *ngIf="obj.DateTime == '1'">
              <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                <ng-container *ngIf="service.DaysNames">
                  <span *ngFor="let item of service.DaysNames" class="date-from">
                    <ng-container *ngIf="item.Monday">
                      {{'Mon'}}
                    </ng-container>
                    <ng-container *ngIf="item.Tuesday">
                      {{'Tue'}}
                    </ng-container>
                    <ng-container *ngIf="item.Wednesday">
                      {{'Wed'}}
                    </ng-container>
                    <ng-container *ngIf="item.Thursday">
                      {{'Thu'}}
                    </ng-container>
                    <ng-container *ngIf="item.Friday">
                      {{'Fri'}}
                    </ng-container>
                    <ng-container *ngIf="item.Saturday">
                      {{'Sat'}}
                    </ng-container>
                    <ng-container *ngIf="item.Sunday">
                      {{'Sun'}}
                    </ng-container>
                  </span>
                </ng-container>

                <span class="time-from">
                  <ng-container *ngIf="service.FromTime">
                    {{service.FromTime|timeFormat}} 
                  </ng-container>
                  <ng-container *ngIf="service.ToTime">
                    - {{service.ToTime|timeFormat}}
                  </ng-container>
                </span>
              </span>
            </ng-container>
            <ng-container *ngIf="obj.DateTime == '2'">
              <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                <span class="date-from">
                  <ng-container *ngIf="tour.DateFrom">
                    {{tour.DateFrom|dateFormat}} -
                  </ng-container>
                  <ng-container *ngIf="tour.DateFrom">
                    {{tour.DateTo|dateFormat}}
                  </ng-container>
                </span>
                <span class="time-from">
                  <ng-container *ngIf="tour.TimeFrom">
                    {{tour.TimeFrom|timeFormat}} 
                  </ng-container>
                  <ng-container *ngIf="tour.TimeTo">
                    - {{tour.TimeTo|timeFormat}}
                  </ng-container>
                </span>

              </p>
            </ng-container>
          </span>
        </div>

        <div class="list-thumb-content">
          <p class="type-info secondary-text"
            *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department); else otherDepartment">
            <span>{{obj?.Department?.Department[0]?.Value}}</span>
          </p>
          <ng-template #otherDepartment>
            <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
          </ng-template>
          <p class="title-info" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else  otherService">
            <a class="primary-text" >{{obj.ServiceName[0].Value}}</a>
          </p>
          <ng-template #otherService>
            <p class="title-info"><a class="primary-text">{{
                'NotAvailable'|translate }}</a></p>
          </ng-template>

          <!-- <span>
            <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
              (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
          </span> -->

        </div>
      </div>
    </div>
  </div>
  <ng-template #otherServiceLists>
    <div class="secondary-text">{{'NotAvailable'|translate }}</div>
  </ng-template>
</div>



<!---Home Front View-->
<div class="room-front-view" *ngIf="serviceLocationStatus == 'serviceFrontView'">
  <div class="row w-100">
    <div class="col-lg-12 mb-3 d-flex justify-content-between align-items-center">
      <div class="position-relative w-100 input-custom-outer">
        <input class="input-custom" matInput type="text" [formControl]="searchControl"
          [placeholder]="'Searchservices' | translate" />
        <i class="material-icons position-absolute">search</i>
      </div>
      <div class="view-list align-items-center">
        <label for="" class="font-weight-bold">{{'View'|translate}}</label>
        <ul class="">
          <li><a (click)="view($event)" id="viewId" class="active d-inline-block mx-4"><i
                class="material-icons">view_module</i></a>
          </li>
          <li><a (click)="list($event)" id="listId" class="d-inline-block"><i
                class="material-icons">format_list_bulleted</i></a>
          </li>
        </ul>
      </div>
    </div>
    
  </div>

  <div class="row"
    *ngIf="!globalService.validateIsEmpty(serviceResponseArrayModel.ServiceLists) && serviceResponseArrayModel.ServiceLists.length else otherServiceLists">
    <mat-tab-group class="w-100 booking-service-guest" #tabGroup (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Booking Services" class="booking-service-label ">
        <ng-container *ngIf="frontView == 'view'">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList"> <!--*ngIf="obj.ServiceType != '3'"-->
            <div class="wrapper-list-thumb">
              <div class="room-img-wrapper mb-3">
                <a [routerLink]="['/hotel-services',obj.id]">
                  <img [src]="obj.ServiceImages[0]"
                    *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                  <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                  </ng-template>
                </a>

                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                         - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>

              <div class="list-thumb-content">
                <p class="type-info secondary-text d-flex align-items-center justify-content-between flex-wrap word-wrap"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span class="booking-service-text">{{obj?.Department?.Department[0]?.Value}}</span> 
                  <!-- <span class="paid-service-tag">Paid</span> <span class="unpaid-service-tag">Free</span> -->

                </p>
                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info mb-2" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{obj.ServiceName[0].Value}}
                  </a>
                </p>
                <ng-template #otherService>
                  <p class="title-info  mb-2"> <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{
                      'NotAvailable'|translate }}
                    </a></p>
                </ng-template>
                <!-- <span>
              <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
            </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                  <!-- <span class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
                ---
              </span> -->
                </p>


              </div>

            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="frontView == 'list'">
          <div class="gallery-item-list col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList">
            <div class="wrapper-list-thumb">
              <div class="list-thumb-content">
                <div class="d-flex align-items-center jusitfy-content-start list-view-area-n mb-2">
                  <p class="title-info mr-3 d-flex align-items-center justify-content-between width100 flex-wrap"
                    *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                    <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{obj.ServiceName[0].Value}}
                    </a>
                    <span class="paid-service-tag">Paid</span> <span class="unpaid-service-tag">Free</span>
                  </p>
                  <ng-template #otherService>
                    <p class="title-info  mr-3"><a [routerLink]="['/hotel-services',obj.id]" class="primary-text">{{
                        'NotAvailable'|translate }}</a></p>
                  </ng-template>
                  <p class="type-info secondary-text mb-0 mt-2" style="margin-left: -5px;">
                    ( <span
                      *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">{{obj?.Department?.Department[0]?.Value}}</span>
                    <ng-template #otherDepartment>
                      <span>{{ 'NotAvailable'|translate }}</span>
                    </ng-template>
                    <!-- <span>
                  <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                    (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
                </span> -->)
                  </p>
                </div>


                <!-- <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

              <ng-container *ngIf="obj.DateTime == '1'">
                <span class="secondary-text date-time-list date-time-list-detail mb-2"
                  *ngFor="let service of obj.ServiceAvailability">

                  <ng-container *ngIf="service.DaysNames">
                    <span *ngFor="let item of service.DaysNames" class="date-from">
                      <ng-container *ngIf="item.Monday">
                        {{'Mon'}}
                      </ng-container>
                      <ng-container *ngIf="item.Tuesday">
                        {{'Tue'}}
                      </ng-container>
                      <ng-container *ngIf="item.Wednesday">
                        {{'Wed'}}
                      </ng-container>
                      <ng-container *ngIf="item.Thursday">
                        {{'Thu'}}
                      </ng-container>
                      <ng-container *ngIf="item.Friday">
                        {{'Fri'}}
                      </ng-container>
                      <ng-container *ngIf="item.Saturday">
                        {{'Sat'}}
                      </ng-container>
                      <ng-container *ngIf="item.Sunday">
                        {{'Sun'}}
                      </ng-container>
                    </span>
                  </ng-container>

                  <span class="time-from">
                    <ng-container *ngIf="service.FromTime">
                      {{service.FromTime|timeFormat}} -
                    </ng-container>
                    <ng-container *ngIf="service.ToTime">
                      {{service.ToTime|timeFormat}}
                    </ng-container>
                  </span>
                </span>
              </ng-container>
              <ng-container *ngIf="obj.DateTime == '2'">
                <p class="secondary-text date-time-list  date-time-list-detail mb-2"
                  *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                  <span class="date-from">
                    <ng-container *ngIf="tour.DateFrom">
                      {{tour.DateFrom|dateFormat}} -
                    </ng-container>
                    <ng-container *ngIf="tour.DateFrom">
                      {{tour.DateTo|dateFormat}}
                    </ng-container>
                  </span>
                  <span class="time-from">
                    <ng-container *ngIf="tour.TimeFrom">
                      {{tour.TimeFrom|timeFormat}} -
                    </ng-container>
                    <ng-container *ngIf="tour.TimeTo">
                      {{tour.TimeTo|timeFormat}}
                    </ng-container>
                  </span>

                </p>
              </ng-container>
            </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                </p>

                <!-- <div class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
            ---
        </div> -->
              </div>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Maintenance / Unpaid Services" class="booking-service-label ">
        <ng-container *ngIf="frontView == 'view'">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList"> <!--*ngIf="obj.ServiceType != '3'"-->
            <div class="wrapper-list-thumb">
              <div class="room-img-wrapper mb-3">
                <a [routerLink]="['/hotel-services',obj.id]">
                  <img [src]="obj.ServiceImages[0]"
                    *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                  <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                  </ng-template>
                </a>

                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                         - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>

              <div class="list-thumb-content">
                <p class="type-info secondary-text d-flex align-items-center justify-content-between flex-wrap word-wrap"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span class="booking-service-text">{{obj?.Department?.Department[0]?.Value}}</span>

                </p>
                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info mb-2" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{obj.ServiceName[0].Value}}
                  </a>
                </p>
                <ng-template #otherService>
                  <p class="title-info  mb-2"> <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{
                      'NotAvailable'|translate }}
                    </a></p>
                </ng-template>
                <!-- <span>
              <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
            </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                  <!-- <span class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
                ---
              </span> -->
                </p>


              </div>

            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="frontView == 'list'">
          <div class="gallery-item-list col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList">
            <div class="wrapper-list-thumb">
              <div class="list-thumb-content">
                <div class="d-flex align-items-center jusitfy-content-start list-view-area-n mb-2">
                  <p class="title-info mr-3 d-flex align-items-center justify-content-between width100 flex-wrap"
                    *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                    <a class="primary-text" [routerLink]="['/hotel-services',obj.id]">{{obj.ServiceName[0].Value}}
                    </a>
                    <span class="paid-service-tag">Paid</span> <span class="unpaid-service-tag">Free</span>
                  </p>
                  <ng-template #otherService>
                    <p class="title-info  mr-3"><a [routerLink]="['/hotel-services',obj.id]" class="primary-text">{{
                        'NotAvailable'|translate }}</a></p>
                  </ng-template>
                  <p class="type-info secondary-text mb-0 mt-2" style="margin-left: -5px;">
                    ( <span
                      *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">{{obj?.Department?.Department[0]?.Value}}</span>
                    <ng-template #otherDepartment>
                      <span>{{ 'NotAvailable'|translate }}</span>
                    </ng-template>
                    <!-- <span>
                  <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                    (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
                </span> -->)
                  </p>
                </div>


                <!-- <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

              <ng-container *ngIf="obj.DateTime == '1'">
                <span class="secondary-text date-time-list date-time-list-detail mb-2"
                  *ngFor="let service of obj.ServiceAvailability">

                  <ng-container *ngIf="service.DaysNames">
                    <span *ngFor="let item of service.DaysNames" class="date-from">
                      <ng-container *ngIf="item.Monday">
                        {{'Mon'}}
                      </ng-container>
                      <ng-container *ngIf="item.Tuesday">
                        {{'Tue'}}
                      </ng-container>
                      <ng-container *ngIf="item.Wednesday">
                        {{'Wed'}}
                      </ng-container>
                      <ng-container *ngIf="item.Thursday">
                        {{'Thu'}}
                      </ng-container>
                      <ng-container *ngIf="item.Friday">
                        {{'Fri'}}
                      </ng-container>
                      <ng-container *ngIf="item.Saturday">
                        {{'Sat'}}
                      </ng-container>
                      <ng-container *ngIf="item.Sunday">
                        {{'Sun'}}
                      </ng-container>
                    </span>
                  </ng-container>

                  <span class="time-from">
                    <ng-container *ngIf="service.FromTime">
                      {{service.FromTime|timeFormat}} -
                    </ng-container>
                    <ng-container *ngIf="service.ToTime">
                      {{service.ToTime|timeFormat}}
                    </ng-container>
                  </span>
                </span>
              </ng-container>
              <ng-container *ngIf="obj.DateTime == '2'">
                <p class="secondary-text date-time-list  date-time-list-detail mb-2"
                  *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                  <span class="date-from">
                    <ng-container *ngIf="tour.DateFrom">
                      {{tour.DateFrom|dateFormat}} -
                    </ng-container>
                    <ng-container *ngIf="tour.DateFrom">
                      {{tour.DateTo|dateFormat}}
                    </ng-container>
                  </span>
                  <span class="time-from">
                    <ng-container *ngIf="tour.TimeFrom">
                      {{tour.TimeFrom|timeFormat}} -
                    </ng-container>
                    <ng-container *ngIf="tour.TimeTo">
                      {{tour.TimeTo|timeFormat}}
                    </ng-container>
                  </span>

                </p>
              </ng-container>
            </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                </p>

                <!-- <div class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
            ---
        </div> -->
              </div>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      
    </mat-tab-group>
    <div class="pagination" *ngIf="serviceResponseArrayModel.ServiceLists">
      <span *ngIf="serviceResponseArrayModel.ServiceLists.length">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
        </mat-paginator>
      </span>
    </div>


  </div>
  <ng-template #otherServiceLists>
    <div class="secondary-text">{{ 'NotAvailable'|translate }}</div>
  </ng-template>
</div>


<div class="room-front-view" *ngIf="serviceLocationStatus == 'serviceAdminView'">
  <div class="row"
    *ngIf="!globalService.validateIsEmpty(serviceResponseArrayModel.ServiceLists) && serviceResponseArrayModel.ServiceLists.length else otherServiceLists">
    <mat-tab-group class="w-100 booking-service-guest" #tabGroup (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Booking Services" class="booking-service-label ">
        <ng-container *ngIf="frontView == 'view'">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList"> <!--*ngIf="obj.ServiceType != '3'"-->
            <div class="wrapper-list-thumb">
              <div class="room-img-wrapper mb-3">
                <a [routerLink]="['/portal/admin/service-admin-list',obj.id]">
                  <img [src]="obj.ServiceImages[0]"
                    *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                  <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                  </ng-template>
                </a>

                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                          - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>

              <div class="list-thumb-content">
                <div *ngIf="!obj.InternalService">
                  <p class="type-info secondary-text d-flex align-items-center justify-content-between flex-wrap word-wrap"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span class="booking-service-text">{{obj?.Department?.Department[0]?.Value}}</span> 
                </p>
                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
              </div>
                <p class="title-info mb-2" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{obj.ServiceName[0].Value}}
                  </a>
                </p>
                <ng-template #otherService>
                  <p class="title-info  mb-2"> <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{
                      'NotAvailable'|translate }}
                    </a></p>
                </ng-template>
                <!-- <span>
            <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
              (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
          </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                  <!-- <span class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
              ---
            </span> -->
                </p>


              </div>

            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Maintenance / Unpaid Services" class="booking-service-label ">
        <ng-container *ngIf="frontView == 'view'">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList"> <!--*ngIf="obj.ServiceType != '3'"-->
            <div class="wrapper-list-thumb">
              <div class="room-img-wrapper mb-3">
                <a [routerLink]="['/portal/admin/service-admin-list',obj.id]">
                  <img [src]="obj.ServiceImages[0]"
                    *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                  <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                  </ng-template>
                </a>


                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                          - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                         - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>

              <div class="list-thumb-content">
                <div *ngIf="!obj.InternalService">
                  <p class="type-info secondary-text d-flex align-items-center justify-content-between flex-wrap word-wrap"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span class="booking-service-text">{{obj?.Department?.Department[0]?.Value}}</span>
                  
                </p>
                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
              </div>
                <p class="title-info mb-2" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{obj.ServiceName[0].Value}}
                  </a>
                </p>
                <ng-template #otherService>
                  <p class="title-info  mb-2"> <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{
                      'NotAvailable'|translate }}
                    </a></p>
                </ng-template>
                <!-- <span>
            <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
              (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
          </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                  <!-- <span class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
              ---
            </span> -->
                </p>


              </div>

            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Internal Services">
        <ng-container *ngIf="frontView == 'view'">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4"
            *ngFor="let obj of this.servicesList"> <!--*ngIf="obj.ServiceType != '3'"-->
            <div class="wrapper-list-thumb">
              <div class="room-img-wrapper mb-3">
                <a [routerLink]="['/portal/admin/service-admin-list',obj.id]">
                  <img [src]="obj.ServiceImages[0]"
                    *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                  <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                  </ng-template>
                </a>

                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                          - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>

              <div class="list-thumb-content">
                <div *ngIf="!obj.InternalService">
                  <p class="type-info secondary-text d-flex align-items-center justify-content-between flex-wrap word-wrap"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span class="booking-service-text">{{obj?.Department?.Department[0]?.Value}}</span>
                  
                </p>
                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
              </div>
                <p class="title-info mb-2" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{obj.ServiceName[0].Value}}
                  </a>
                </p>
                <ng-template #otherService>
                  <p class="title-info  mb-2"> <a class="primary-text" [routerLink]="['/portal/admin/service-admin-list',obj.id]">{{
                      'NotAvailable'|translate }}
                    </a></p>
                </ng-template>
                <!-- <span>
            <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
              (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
          </span> -->
                <p class="description-heading-list">
                  <span class="secondary-text ckeditor-front"
                    *ngIf="obj.ServiceDescription && obj.ServiceDescription.length;"
                    [innerHTML]="obj.ServiceDescription[0].Value|slice:0:60|safe: 'html'">
                  </span>
                  <!-- <span class="secondary-text" *ngIf="obj.ServiceDescription && obj.ServiceDescription.length && obj.ServiceDescription[0].Value.length > 60">
              ---
            </span> -->
                </p>


              </div>

            </div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
    <div class="pagination" *ngIf="serviceResponseArrayModel.ServiceLists">
      <span *ngIf="serviceResponseArrayModel.ServiceLists.length">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
        </mat-paginator>
      </span>
    </div>


  </div>
  <ng-template #otherServiceLists>
    <div class="secondary-text">{{ 'NotAvailable'|translate }}</div>
  </ng-template>
</div>

<!-- <div class="pagination">

  <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>

</div> -->

<!---//Home Front View-->


<!---Restaurant Manage Page-->
<div class="room-manage-page row" *ngIf="serviceLocationStatus == 'serviceManagePage'">
  <div class="content-section-outer w-100">
    <div class="content-wrapper">
      <div class="main-content-wrapper row">
        <!-- Home list -->
        <div class="home-page row mx-0  w-100"
          *ngIf="!globalService.validateIsEmpty(serviceResponseArrayModel.ServiceLists)">
          <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12  mb-3"
            *ngFor="let obj of serviceResponseArrayModel.ServiceLists">
            <div class="wrapper-list-thumb position-relative">
              <div class="room-img-wrapper">

                <img [src]="obj.ServiceImages[0]"
                  *ngIf="obj.ServiceImages && obj.ServiceImages.length;else otherImage" />
                <ng-template #otherImage>
                  <img src="assets/images/No_Image_Available.jpg" />
                </ng-template>

                <span *ngIf="!globalService.validateIsEmpty(obj.ServiceAvailability)">

                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let service of obj.ServiceAvailability">

                      <ng-container *ngIf="service.DaysNames">
                        <span *ngFor="let item of service.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                      </ng-container>

                      <span class="time-from">
                        <ng-container *ngIf="service.FromTime">
                          {{service.FromTime|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="service.ToTime">
                         - {{service.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="obj.DateTime == '2'">
                    <p class="secondary-text date-time-list mb-2"
                      *ngFor="let tour of obj.ServiceAvailability| slice:0:1">
                      <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                      <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} 
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          - {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>

                    </p>
                  </ng-container>
                </span>
              </div>
              <span class="restock-warning nunito position-absolute" *ngIf="thresholdCheck(obj.id)" >{{'SoonOutofStock'|translate}}</span>

              <div class="list-thumb-content">
                <p class="title-info" *ngIf="!globalService.validateIsEmpty(obj.ServiceName);else otherService">
                  <a class="primary-text" >{{obj.ServiceName[0].Value}}</a>
                </p>
                <ng-template #otherService>
                  <p class="title-info"><a class="primary-text" >{{
                      'NotAvailable'|translate }}</a></p>
                </ng-template>
                <p class="type-info secondary-text"
                  *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                  <span>{{obj?.Department?.Department[0]?.Value}}</span>
                </p>

                <ng-template #otherDepartment>
                  <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <!-- <span>
                  <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                    class="star_rating_outer"></star-rating> 9 {{'Reviews'|translate}}
                </span> -->
              </div>
              <div class="button-section">
                <button type="button" class="btn btn-primary" (click)="detail(obj.id)"  [appAccessCheck]="'services'"[givenButton]="'view'">
                  <i class="material-icons">remove_red_eye</i> {{'ViewDetail'|translate}}
                </button>
                <button class="btn btn-secondary" type="type" (click)="edit(obj.id)" [appAccessCheck]="'services'" [givenButton]="'edit'">
                  <i class="material-icons">create</i> {{'Edit'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="pagination" *ngIf="serviceResponseArrayModel.ServiceLists">
          <span *ngIf="serviceResponseArrayModel.ServiceLists.length">
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
            </mat-paginator>
          </span>
        </div> -->
      </div>
    </div>
  </div>

  <!-- <div class="pagination" *ngIf="resturantResponseArrayModel.ResturantLists">
        <span *ngIf="resturantResponseArrayModel.ResturantLists.length">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
      </mat-paginator>
    </span>
    </div> -->

    <div class="pagination" *ngIf="serviceResponseArrayModel.ServiceLists">
      <span *ngIf="serviceResponseArrayModel.ServiceLists.length">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
        </mat-paginator>
      </span>
    </div>
</div>
<!---//Restaurant Manage Page-->