<app-loader [ShowLoader]="globalService.showLoader"></app-loader>
<form [formGroup]="addNewResturantType" class="form-wrapper">
    <div class="modal-header clearfix">
        <h3 *ngIf="insertStatus">{{ 'Addnewtype'|translate}}</h3>
        <h3 *ngIf="updateStatus">{{ 'Updatetype'|translate}}</h3>

        <div class="lang-drop-menu">
            <span>{{ 'EditTextFor'|translate }}:</span>
            <app-language-save [popupStatus]='setPopupStatus' (selectedLang)="switchLanguage($event)"></app-language-save>
        </div>
        <button class="material-icons" mat-dialog-close>close</button>
    </div>
    <div class="modal-body-content">
        <mat-form-field class="col-lg-12 pad0">
            <mat-label>{{'Title'|translate}}</mat-label>
            <input matInput type="text" formControlName="ResturantTypeName">
        </mat-form-field>
    </div>
    <div class="modal-footer-content">
        <a (click)="addResturantType(addNewResturantType)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="insertStatus" [appAccessCheck]="'restaurants'" [givenButton]="'add'">{{'Submit'|translate}}</a>

        <a (click)="addResturantType(addNewResturantType)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="updateStatus" [appAccessCheck]="'restaurants'" [givenButton]="'update'">{{'Submit'|translate}}</a>

        <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn" [appAccessCheck]="'restaurants'" [givenButton]="'cancel'">
            <i class="material-icons">close</i>{{'Cancel'|translate}}
        </a>
    </div>
</form>