import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  ElementRef
} from "@angular/core";
import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "@ckeditor/ckeditor5-build-classic/build/translations/de";
// import { StarRatingComponent } from "ng-starrating";

// import { MapsAPILoader } from "@agm/core";

// declare var google;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-ui-kit",
  templateUrl: "./ui-kit.component.html",
  styleUrls: ["./ui-kit.component.scss"]
})
export class UiKitComponent implements OnInit {
  // Multi select
  toppings = new UntypedFormControl();
  toppingList: string[] = [
    "Extra cheese",
    "Mushroom",
    "Onion",
    "Pepperoni",
    "Sausage",
    "Tomato"
  ];

  // currency
  currencye = new UntypedFormControl();
  currencyList: string[] = ["USD", "INR", "URE"];

  // email
  emailFormControl = new UntypedFormControl("", [
    Validators.required,
    Validators.email
  ]);
  matcher = new MyErrorStateMatcher();
  public Editor = ClassicEditor;
  public config = {
    language: "de",
    toolbar: [
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote"
    ]
  };

  title = "My first AGM project";
  lat = 51.678418;
  lng = 7.809007;
  public latitude: number;
  public longitude: number;
  public searchControl: UntypedFormControl;
  public zoom: number;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  constructor(
    /*private mapsAPILoader: MapsAPILoader,*/ private ngZone: NgZone
  ) {}

  ngOnInit() {
    //set google maps defaults
    this.zoom = 4;
    this.latitude = 39.8282;
    this.longitude = -98.5795;
    //create search FormControl
    this.searchControl = new UntypedFormControl();

    //load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   let autocomplete = new google.maps.places.Autocomplete(
    //     this.searchElementRef.nativeElement,
    //     {
    //       types: ["address"]
    //     }
    //   );
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place = autocomplete.getPlace();

    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //       this.latitude = place.geometry.location.lat();
    //       this.longitude = place.geometry.location.lng();
    //       this.zoom = 12;
    //     });
    //   });
    // });
  }

  // onRate($event: {
  //   oldValue: number;
  //   newValue: number;
  //   starRating: StarRatingComponent;
  // }) {
  //   alert(`Old Value:${$event.oldValue}, 
  //     New Value: ${$event.newValue}, 
  //     Checked Color: ${$event.starRating.checkedcolor}, 
  //     Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  // }

  onChooseLocation(event) {
    
  }
}
