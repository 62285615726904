<div class="modal-header clearfix">
    <h3>{{ 'Update'|translate}}</h3>

    <div class="lang-drop-menu">
        <span>{{ 'EditTextFor'|translate }}:</span>
        <app-language-save [popupStatus]='setPopupStatus' (selectedLang)="switchLanguage($event)"></app-language-save>
    </div>
    <button class="material-icons" mat-dialog-close>close</button>
</div>
<div class="modal-body">
    <div class="show-room-list" *ngIf="listRoomtypes.RoomTypeLists && listRoomtypes.RoomTypeLists.length">
        <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">

            <div class="ch-guide-listing" class="example-list">

                <div class="ch-guide-list  block-container" *cdkVirtualFor="let list of listRoomtypes.RoomTypeLists;let i = index">
                    <div class="guideContent">
                        <h3>{{ list.name }}</h3>
                    </div>
                    <div class="ch-guide-action-ic">
                        <a href="javascript:void(0)" (click)="edit(i,list.id)"><i class="material-icons">edit</i></a>
                        <a href="javascript:void(0)" (click)="delete(i,list.id)"><i class="material-icons">delete</i></a>
                    </div>
                </div>

            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>

