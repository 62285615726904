import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmptValueArray'
})
export class FilterEmptValueArrayPipe implements PipeTransform {

  transform(item: any): any {
    return item.filter((i) =>{ return i; });
  }

}
