import { Pipe, PipeTransform } from "@angular/core";
import { MasterService } from "../services/master.service";
import { GlobalService } from "../services/global.service";
import { ManageBrandingModel } from "../models/request/manageBranding.model";
import { Globals } from "../globals";

@Pipe({
  name: "dateFormat"
})
export class DateFormatPipe implements PipeTransform {
  constructor(public globals: Globals) {}

  private monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  formatDate(date) {
    let dateFormat = "";
    let today = "";
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var yy = date
      .getFullYear()
      .toString()
      .substr(-2);
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    if (window.localStorage["DateFormat"]) {
      dateFormat = window.localStorage["DateFormat"];
    } else {
      dateFormat = this.globals.DateFormat;
    }

    if (dateFormat == "DD/MM/YYYY") {
      today = dd + "/" + mm + "/" + yyyy;
    }

    if (dateFormat == "DD/MM/YY") {
      today = dd + "/" + mm + "/" + yy;
    }

    if (dateFormat == "MM/DD/YYYY") {
      today = mm + "/" + dd + "/" + yyyy;
    }
    if (dateFormat == "MM/DD/YY") {
      today = mm + "/" + dd + "/" + yy;
    }

    if (dateFormat == "YYYY/MM/DD") {
      today = yyyy + "/" + mm + "/" + dd;
    }
    if (dateFormat == "YYYY/DD/MM") {
      today = yyyy + "/" + dd + "/" + mm;
    }
    if (dateFormat == "YY/DD/MM") {
      today = yy + "/" + dd + "/" + mm;
    }
    if (dateFormat == "YY/MM/DD") {
      today = yy + "/" + mm + "/" + dd;
    }

    return today;
  }

  transform(item: any): any {
    let timezoneSelect = "";
    if (window.localStorage["Timezone"]) {
      timezoneSelect = window.localStorage["Timezone"];
    } else {
      timezoneSelect = this.globals.Timezone;
    }

    let date1 = new Date(item).toLocaleString("en-US", {
      timeZone: timezoneSelect
    });
    let date = new Date(date1);

    return this.formatDate(date);
    // return (
    //   date.getDate() +
    //   " " +
    //   this.monthNames[date.getMonth()] +
    //   "," +
    //   date.getFullYear()
    // );
  }
}
