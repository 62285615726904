import { Component, OnInit ,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-items-restock-confirmation',
  templateUrl: './items-restock-confirmation.component.html',
  styleUrls: ['./items-restock-confirmation.component.scss']
})
export class ItemsRestockConfirmationComponent implements OnInit {
  public action;
  constructor(@Inject(MAT_DIALOG_DATA
    ) public data: any,private dialog: MatDialog,
    private globalService:GlobalService) { }

  ngOnInit(): void {
    this.action = this.data.action;
    
    
  }
  closeDialog(){
    this.dialog.closeAll();
  //  this.globalService.emitEditServiceView(this.data.serviceId);
  //  this.globalService.emitEditResturantView(this.data.restaurantId);
  }
}
