import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Globals } from "../../globals";
import { MasterService } from "../../services/master.service";
import { TranslateService } from "@ngx-translate/core";
import { patternValidator } from "src/app/pattern-validator/pattern-validator";
import { takeUntil } from "rxjs/operators";
import { InclusionModel } from "../../models/common-request-response";

@Component({
  selector: "app-add-new-inclusion-exclusion",
  templateUrl: "./add-new-inclusion-exclusion.component.html",
  styleUrls: ["./add-new-inclusion-exclusion.component.scss"]
})
export class AddNewInclusionExclusionComponent implements OnInit {
  form: UntypedFormGroup;
  updateStatus: boolean = false;
  insertStatus: boolean = false;
  @Output() onAdd = new EventEmitter<any>(true);
  @Output() onUpdate = new EventEmitter<any>(true);

  showLoader: boolean = false;
  updateId: any = null;
  public lang = this.globals.langSaveProperty;
  public inclusionModel: InclusionModel = new InclusionModel();
  public setPopupStatus = true;
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<AddNewInclusionExclusionComponent>,
    public globals: Globals,
    private masterService: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.data) {
      this.updateId = this.data._id;
      this.updateStatus = true;
      this.editInclusion(this.data);
    } else {
      this.insertStatus = true;
    }
  }

  ngOnDestroy() {}

  initForm() {
    this.form = this.fb.group({
      InclusionName: ["", [Validators.required, patternValidator(/^[^<>%$]*$/)]]
    });
  }
  switchLanguage(lang) {
    this.lang = lang;
    if (this.updateId) {
      this.showLoader = true;
      this.masterService
        .showSingleInclusion({
          DefaultLang: lang,
          InclusionId: this.updateId
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.showLoader = false;
              this.globalService.emitPermissions(response.AdminPermissions);

              // 
              this.inclusionModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                InclusionModel
              );

              // 
              if (
                !this.globalService.validateIsEmpty(
                  this.inclusionModel.InclusionLangs
                )
              ) {
                this.form.setValue({
                  InclusionName: this.inclusionModel.InclusionLangs[0].Value
                });
              } else {
                this.form.setValue({
                  InclusionName: ""
                });
              }
              // this.form.setValue({
              //   InclusionName:this.inclusionModel.InclusionLangs
              // })
              // this.editAmenities(this.amentiesModel);
            } else {
              this.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }
  editInclusion(data) {
    let inclusionName = "";
    if (data.InclusionLangs && data.InclusionLangs.length) {
      inclusionName = data.InclusionLangs[0].Value;
    }

    this.form.patchValue({
      InclusionName: inclusionName
    });
  }

  add(form) {
    if (!form.invalid) {
      this.showLoader = true;

      if (this.insertStatus) {
        this.masterService
          .addInclusion({
            LangSave: this.lang,
            InclusionName: form.value.InclusionName
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.showLoader = false;

                this.globalService.emitPermissions(response.AdminPermissions);
                // 
                if (response.Data) {
                  this.insertStatus = false;
                  this.updateStatus = true;
                  this.updateId = response.Data;
                  this.onAdd.emit({
                    id: response.Data,
                    value: form.value.InclusionName
                  });
                  this.globalService.handleSuccessMessage(response.Message);
                } else {
                  this.onAdd.emit(false); //If Already Exists
                }

                // this.dialogRef.close();
              } else {
                this.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }

      if (this.updateStatus) {
        if (this.updateId) {
          this.masterService
            .updateInclusion({
              LangSave: this.lang,
              InclusionName: form.value.InclusionName,
              InclusionId: this.updateId
            })
            .pipe(takeUntil(this.globalService.componentDestroyed(this)))
            .subscribe(
              response => {
                if (response.Status) {
                  this.showLoader = false;
                  this.globalService.emitPermissions(response.AdminPermissions);
                  // 
                  if (response.Data) {
                    this.onUpdate.emit({
                      id: response.Data,
                      value: form.value.InclusionName
                    });
                    this.globalService.handleSuccessMessage(response.Message);
                  } else {
                    this.onUpdate.emit(false); //If Already Exists
                  }

                  // this.dialogRef.close();
                } else {
                  this.showLoader = false;
                  this.globalService.handleApiError(response);
                }
              },
              error => {
                this.showLoader = false;
                this.globalService.handleApiError(error);
              }
            );
        }
      }
    } else {
      this.globalService.validateFormFields(form.form);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
