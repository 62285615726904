import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter,NullableString } from 'src/app/core/models/essentials';


@JsonObject("HotelBannerLangModel")
export class HotelBannerLangModel {
    @JsonProperty("Lang", NullableString, true) 
    Lang: string = undefined;
    @JsonProperty("BannerTagline",NullableString, true)
    BannerTagline:string = undefined;
    @JsonProperty("BannerTitle",NullableString, true)
    BannerTitle:string = undefined;
    @JsonProperty("BannerButtonText",NullableString, true)
    BannerButtonText:string = undefined;
}

@JsonObject("HotelBannerModel")
export class HotelBannerModel {
    @JsonProperty("HotelBannerLang", [HotelBannerLangModel], true) 
    HotelBannerLang: [HotelBannerLangModel] = undefined;
    @JsonProperty("BannerButtonType", NullableString, true) 
    BannerButtonType: string = undefined;
    @JsonProperty("BannerUrl", NullableString, true) 
    BannerUrl: string = undefined;  
    @JsonProperty("BannerImage", NullableString, true) 
    BannerImage: string = undefined;
} 


