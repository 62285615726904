import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-pdf-upload-modal",
  templateUrl: "./pdf-upload-modal.component.html",
  styleUrls: ["./pdf-upload-modal.component.scss"]
})
export class PdfUploadModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
  yt =
    '<iframe class="w-100" src="https://www.youtube.com/embed/KS76EghdCcY?rel=0&amp;controls=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
  ngOnInit() {
    if (this.data.Url) {
      this.yt =
        '<iframe class="w-100" src="' +
        this.data.Url +
        '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    }
  }
}
