<footer class="footer">
  <div class="main-footer-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12 col-1-footer">
          <img src="assets/images/DATIUM-Logo-WhiteN.png" />
          <p>
            Level 4, 1F Homebush Bay Drive
            Rhodes, NSW 2138
          </p>
          <p>
            <a href="javascript:void(0);">
              info@datiuminsights.com.au</a>
            <br />
            1300 298 151
          </p>
        </div>

        <div class="col-md-3 col-sm-12 col-2-footer">
          <ul>
            <li><a href="javascript:void(0);" routerLink="/dashboard">Products</a></li>
            <li><a href="javascript:void(0);" routerLink="/dashboard">Company</a>
              <ul>
                <li><a href="javascript:void(0);" routerLink="/dashboard">About Us</a></li>
                <li><a href="javascript:void(0);" routerLink="/dashboard">Management Team</a></li>
                <li><a href="javascript:void(0);" routerLink="/dashboard">News</a></li>
                <li><a href="javascript:void(0);" routerLink="/dashboard">Careers</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-sm-12 col-3-footer">
          <ul>
            <li><a href="javascript:void(0);" routerLink="/dashboard">Blog</a></li>
            <li><a href="javascript:void(0);" routerLink="/dashboard">Contact Us</a></li>
          </ul>

          <div class="footer-social">
            <a href="javascript:void(0);" target="_blank" class="gdlr-core-social-network-icon" title="facebook">
              <i class="fa fa-facebook"></i>
            </a>
            <a href="javascript:void(0);" target="_blank" class="gdlr-core-social-network-icon" title="linkedin">
              <i class="fa fa-linkedin"></i>
            </a>
            <a href="javascript:void(0);" target="_blank" class="gdlr-core-social-network-icon" title="twitter">
              <i class="fa fa-twitter"></i>
            </a>
          </div>
        </div>

        <div class="col-md-3 col-sm-12 col-4-footer">
          <h4>NEWSLETTER</h4>
          <div class="form-group signup-group">
            <input class="sign-input" placeholder="Your Email Address" type="text">
          </div>
          <button class="sign-btn" mat-flat-button color="primary">Sign up</button>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-wrapper">
    <div class="container">
      <p class="infinite-copyright-text">Copyright 2017 Datium Insights. All Rights Reserved | 
        <a href="javascript:void(0);" routerLink="/dashboard"> Privacy Policy</a> | <a href="javascript:void(0);" routerLink="/dashboard">Terms &amp; Conditions</a>
      </p>
    </div>
  </div>
</footer>