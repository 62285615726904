<!-- Inner Banner -->
<!-- <section id="hotel-inner-banner" class="content-section-outer clearfix inner-page-heading" *ngIf="innerBannerShow">
    <div class="content-wrapper">
        <app-heading-titles [childHeadingTitle]="headingTitle"></app-heading-titles>
  
        <div class="page-location">{{ 'Home'|translate }} <i class="material-icons">keyboard_arrow_right</i>{{headingTitle}}
        </div>
    </div>
</section> -->
<!--// Inner Banner-->
<!-- About Home -->
<section id="about-info" *ngIf="viewAllShow">
    <div class="content-wrapper">
        <app-heading-titles [childHeadingTitle]="headingTitle"></app-heading-titles>
        <div class="aboutus-description secondary-text" *ngIf="hotelAboutUsLangModel.AboutUsDescription;else other_AboutUsDesc">
            {{ hotelAboutUsLangModel.AboutUsDescription }}
        </div>
        <ng-template #other_AboutUsDesc>
            <div class="aboutus-description secondary-text">{{'Nodescription'|translate}}</div>
        </ng-template>
        <div class="spacer"></div>
        <button class="btn" [class]="buttonColor" [routerLink]="['' ,'aboutUs']" *ngIf="hotelAboutUsLangModel.AboutUsDescription;else other_AboutUsButtonText">{{ hotelAboutUsLangModel.AboutUsButtonText }}</button>
        <ng-template #other_AboutUsButtonText> <button class="btn" [class]="buttonColor" [routerLink]="['' ,'aboutUs']">{{'Nobuttontext'|translate}}</button></ng-template>
    </div>
</section>
<!-- // About Home -->

<!-- About -->
<section id="about-info" *ngIf="aboutUsDetail">
    <div class="content-wrapper">
        <div class="aboutus-description secondary-text" *ngIf="hotelAboutUsLangModel.AboutUsDescription;else other_AboutUsDesc">
            {{ hotelAboutUsLangModel.AboutUsDescription }}
        </div>
        <ng-template #other_AboutUsDesc>
            <div class="aboutus-description secondary-text">{{'Nodescription'|translate}}</div>
        </ng-template>
        <div class="spacer"></div>
    </div>
</section>
<!-- // About -->