import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import {
  UntypedFormGroup,
  FormControl,
  UntypedFormBuilder,
  Validators
} from "@angular/forms";
import { patternValidator } from "src/app/pattern-validator/pattern-validator";
import { GlobalService } from "../../services/global.service";
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AddNewImageModalComponent } from "../add-new-image-modal/add-new-image-modal.component";
import { MasterService } from "../../services/master.service";
import { Globals } from "../../globals";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RoomTypeLangsResponseModel } from "../../models/common-request-response";

@Component({
  selector: "app-add-new-room-type-modal",
  templateUrl: "./add-new-room-type-modal.component.html",
  styleUrls: ["./add-new-room-type-modal.component.scss"]
})
export class AddNewRoomTypeModalComponent implements OnInit {
  addNewRoomtype: UntypedFormGroup;
  // Emitter For Adding Room type
  @Output() onAdd = new EventEmitter<any>(true);
  @Output() onUpdate = new EventEmitter<any>(true);
  //Set Popup Status  Parent  to child Communication
  public setPopupStatus = true;
  updateStatus: boolean = false;
  insertStatus: boolean = false;
  updateId: any = null;

  roomTypeLangsResponseModel: RoomTypeLangsResponseModel = new RoomTypeLangsResponseModel();
  public lang = this.globals.langSaveProperty;
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<AddNewImageModalComponent>,
    private masterService: MasterService,
    public globals: Globals,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();

    if (this.data) {
      this.updateId = this.data._id;
      let roomtypeName = "";
      this.updateStatus = true;

      if (this.data.RoomTypeLangs && this.data.RoomTypeLangs.length) {
        roomtypeName = this.data.RoomTypeLangs[0].Value;
      }

      this.addNewRoomtype.patchValue({
        RoomTypeName: roomtypeName
      });
    } else {
      this.insertStatus = true;
    }
  }

  initForm() {
    this.addNewRoomtype = this.fb.group({
      RoomTypeName: ["", [Validators.required, patternValidator(/^[^<>%$]*$/)]]
    });
  }

  switchLanguage(lang) {
    this.lang = lang;
    this.editRoomType(lang);
  }

  editRoomType(lang) {
    // 
    if (this.updateId) {
      this.masterService
        .showSingleRoomType({
          LangSave: lang,
          RoomTypeId: this.updateId
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.emitPermissions(response.AdminPermissions);
              this.roomTypeLangsResponseModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                RoomTypeLangsResponseModel
              );

              let roomtypeName = "";

              if (
                this.roomTypeLangsResponseModel.RoomTypeLangs &&
                this.roomTypeLangsResponseModel.RoomTypeLangs.length
              ) {
                roomtypeName = this.roomTypeLangsResponseModel.RoomTypeLangs[0]
                  .Value;
              }

              this.addNewRoomtype.patchValue({
                RoomTypeName: roomtypeName
              });

              this.globalService.showLoader = false;
            } else {
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  addRoomType(form) {
    if (!form.invalid) {
      this.globalService.showLoader = true;

      if (this.insertStatus) {
        this.masterService
          .addRoomType({
            LangSave: this.lang,
            RoomTypeName: form.value.RoomTypeName
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.globalService.showLoader = false;
                this.globalService.emitPermissions(response.AdminPermissions);
                // 
                if (response.Data) {
                  this.insertStatus = false;
                  this.updateId = response.Data;
                  this.updateStatus = true;

                  this.onAdd.emit({
                    id: response.Data,
                    name: form.value.RoomTypeName
                  });
                } else {
                  this.onAdd.emit(false); //If Already Exists
                }

                // this.dialogRef.close();
              } else {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }

      if (this.updateStatus) {
        if (this.updateId) {
          this.masterService
            .updateRoomType({
              LangSave: this.lang,
              RoomTypeName: form.value.RoomTypeName,
              RoomTypeId: this.updateId
            })
            .pipe(takeUntil(this.globalService.componentDestroyed(this)))
            .subscribe(
              response => {
                if (response.Status) {
                  this.globalService.showLoader = false;
                  this.globalService.emitPermissions(response.AdminPermissions);
                  if (response.Data) {
                    this.onUpdate.emit({
                      id: response.Data,
                      name: form.value.RoomTypeName
                    });
                  } else {
                    this.onUpdate.emit(false); //If Already Exists
                  }
                  this.globalService.handleSuccessMessage(response.Message);
                } else {
                  this.globalService.showLoader = false;
                  this.globalService.handleApiError(response);
                }
              },
              error => {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(error);
              }
            );
        }
      }
    } else {
      this.globalService.validateFormFields(form.form);
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.dialogRef.close();
  }
}
