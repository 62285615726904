<!-- Inner Banner -->
<!-- <section id="hotel-inner-banner" class="content-section-outer clearfix inner-page-heading" *ngIf="innerBannerShow">
    <div class="content-wrapper">
        <app-heading-titles [childHeadingTitle]="headingTitle"></app-heading-titles>
       
        <div class="page-location">Home <i class="material-icons">keyboard_arrow_right</i>{{headingTitle}}</div>
 
    </div>
</section> -->
<!--// Inner Banner-->

<!-- Hotel Guide -->
<!-- <section id="hotel-guide" class="content-section-outer clearfix">
    <div class="content-wrapper">

    </div>
</section> -->

<div *ngIf="hotelGuideLocationStatus == 'manage'">

    <div class="ch-guide-listing" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <span
            *ngIf="hotelGuideListsArray && hotelGuideListsArray.HotelGuideLists && hotelGuideListsArray.HotelGuideLists.length">
            <div class="ch-guide-list  block-container"
                *ngFor="let list of hotelGuideListsArray.HotelGuideLists;let i = index" cdkDrag>

                <div class="ch-guide-app-ic"> <i class="material-icons">apps</i> </div>
                <div class="guideContent">
                    <h3 *ngIf="list.TitleGuide && list.TitleGuide.length">{{ list.TitleGuide[0].Value }}</h3>
                    <div class="ckeditor-front" [innerHTML]="list.HotelGuideDescription[0].Value| safe: 'html' "
                        *ngIf="list.HotelGuideDescription && list.HotelGuideDescription.length"></div>
                    <div class="d-flex align-items-center custom_pdf_name_icon mt-2">
                        <span class="file-type-img" *ngIf="list.Pdf">
                            <i class="material-icons rotate_icon_attach">attachment</i>
                            <a (click)="openPdf(list.Pdf)"><img src="assets/images/pdf-icon.png" alt="Pdf Icon"></a>
                        </span>
                        <span *ngIf="list.FileName">{{list.FileName}}</span>
                    </div>
                </div>
                <div class="ch-guide-action-ic">
                    <a href="javascript:void(0)" (click)="editHotelGuide(i,list._id)" [appAccessCheck]="'hotelguide'"
                        [givenButton]="'edit'"><i class="material-icons">edit</i></a>
                    <a href="javascript:void(0)" (click)="deleteHotelGuide(i,list._id,list.Pdf)"
                        [appAccessCheck]="'hotelguide'" [givenButton]="'delete'"><i
                            class="material-icons">delete</i></a>
                </div>
            </div>
        </span>
    </div>

</div>

<div class="guide-detail-box" *ngIf="hotelGuideLocationStatus == 'front'">
    <div class="row">
        <div class="col-md-12"
            *ngIf="hotelGuideListsArray && hotelGuideListsArray.HotelGuideLists && hotelGuideListsArray.HotelGuideLists.length">
            <div class="guide_list_inner">
                <div class="row">
                    <div class="ch-guide-list col-md-6 col-sm-12"
                        *ngFor="let list of hotelGuideListsArray.HotelGuideLists| slice:0:halfLength">
                        <h4 *ngIf="list.TitleGuide && list.TitleGuide.length">{{ list.TitleGuide[0].Value }}</h4>
                        <!-- <p *ngIf="list.HotelGuideDescription && list.HotelGuideDescription.length">
                            {{ list.HotelGuideDescription[0].Value }}</p> -->

                        <div class="ckeditor-front" [innerHTML]="list.HotelGuideDescription[0].Value|safe: 'html' "
                            *ngIf="list.HotelGuideDescription && list.HotelGuideDescription.length"></div>
                        <span class="file-type-img" *ngIf="list.Pdf"><a (click)="openPdf(list.Pdf)"><img
                                    src="assets/images/pdf-icon.png" alt="Pdf Icon"></a></span>
                        <span *ngIf="list.FileName">{{list.FileName}}</span>
                    </div>
                    <!-- </div>  
                <div class="row"> -->
                    <div class="ch-guide-list col-md-6 col-sm-12"
                        *ngFor="let list of hotelGuideListsArray.HotelGuideLists| slice:halfLength">
                        <h4 *ngIf="list.TitleGuide && list.TitleGuide.length">{{ list.TitleGuide[0].Value }}</h4>
                        <!-- <p *ngIf="list.HotelGuideDescription && list.HotelGuideDescription.length">
                            {{ list.HotelGuideDescription[0].Value }}
                           <ng-container [inner]></ng-container>
                        
                        </p> -->
                        <div class="ckeditor-front" [innerHTML]="list.HotelGuideDescription[0].Value|safe: 'html'"
                            *ngIf="list.HotelGuideDescription && list.HotelGuideDescription.length"></div>
                        <span class="file-type-img" *ngIf="list.Pdf"><a (click)="openPdf(list.Pdf)"><img
                                    src="assets/images/pdf-icon.png" alt="Pdf Icon"></a></span>
                        <span *ngIf="list.FileName">{{list.FileName}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>