import { Component, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-checkin-modal2',
  templateUrl: './checkin-modal2.component.html',
  styleUrls: ['./checkin-modal2.component.scss']
})
export class CheckinModal2Component implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  checkinServices = null;
  alarm  = null;
  breakfast  = null;
  cleaning  = null;
  dryCleaning  = null;
  hideMyPreference  = null;

  ngOnInit() {
     //
     this.alarm = this.data.Alarm;
     this.breakfast = this.data.Breakfast;
     this.cleaning = this.data.Cleaning;
     this.dryCleaning = this.data.DryCleaning;
     this.hideMyPreference = this.data.HideMyPreference;
  }

}
