// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  host: 'https://cityhotelxs-api.softobiz.net',
  apiUrl: 'https://cityhotelxs-api.softobiz.net',
  langSave: { Property: 'en', Value: 'English' },
  langView: 'en',
  SubDomainLocal: 'cityhotelxs.softobiz.net',
  aiApiUrl: 'https://ai.api.butlermax.plts-dev.com',
  aiApiKey: 'px8K2L9qN5vM3jW7hY4tR1bC6fD0mX8A3pE4s'
};
