import { Component, OnInit } from '@angular/core';
import { ForgetPasswordService } from '../../services/forget-password.service';
import { ResetAuthCheckService } from '../../services/reset-auth-check.service';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../services/global.service';

import { HotelLoginModel } from '../../models/request/hotel-login.model';
import { ResetPasswordService } from '../../services/reset-password.service';
import { JwtService } from '../../services/jwt.service';
import { MasterService } from '../../services/master.service';
import { ManageBrandingModel } from '../../models/request/manageBranding.model';
import { Globals } from '../../globals';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  brandingSubscribe$;
  public forgetPassword:boolean=true;
  public resetAuth:boolean=false;
  public resetPassword:boolean=false;
  public usernameAuth:string;

  public  hotelLoginModel:HotelLoginModel = new HotelLoginModel();
  public  manageBrandingModel:ManageBrandingModel = new ManageBrandingModel();
  public langTranslateDisplay;
  constructor(public globalService:GlobalService,private forgetPasswordService:ForgetPasswordService,private resetAuthCheckService:ResetAuthCheckService,private resetPasswordService:ResetPasswordService,private jwtService:JwtService,private masterService:MasterService,public globals:Globals,public translate: TranslateService) { }

  ngOnInit() {
     // Request Necessary to subscribe manage Branding
     this.masterService.getManageBranding({"Lang":this.globalService.getuserLangTranslate(),"Subdomain":this.globalService.getHostname()});

     //Subscribe to Branding
     this.brandingSubscribe$ = this.masterService.manageBrandingData.subscribe(response =>{
       if(response.Data){
         this.globalService.showLoader=false; 
         this.manageBrandingModel = this.globalService.jsonConvert.deserializeObject(response.Data, ManageBrandingModel);

   
         //Check default language if exists And HotelLangTranslate
         if(this.manageBrandingModel.DefaultLang && !window.localStorage['userLangTranslate']){

           // Fetch Language from Hotel folder 
           let combinedValue = this.globalService.concatenate('hotel',this.manageBrandingModel.DefaultLang);
           this.translate.setDefaultLang(combinedValue);
           this.langTranslateDisplay = this.manageBrandingModel.DefaultLang;
           //In Order to Reflect changes
           this.globals.userLangTranslate = this.manageBrandingModel.DefaultLang;
         }else{

           //Fetch language from Global Service 
           // this.globals.hotelLangTranslate = this.globalService.gethotelLangTranslate();
           // Fetch Language from Hotel folder 
           let combinedValue = this.globalService.concatenate('hotel',this.globalService.getuserLangTranslate());
           this.translate.setDefaultLang(combinedValue);
           this.langTranslateDisplay = this.globalService.getuserLangTranslate();
         } 
       }
     },error=>{
       this.globalService.showLoader = false;
       this.globalService.handleApiError(error);
     }); 
  }
   
  forgetPass(form:NgForm){
      if(!form.invalid){
        this.globalService.showLoader=true;
        this.usernameAuth =  form.value.Username;
        this.forgetPasswordService.forget({"Username":form.value.Username}).subscribe(response=>{
            if(response.Status){ 
              this.globalService.showLoader=false;
              this.forgetPassword = false;
              this.resetAuth = true;
            }else{
              this.globalService.showLoader=false; 
              this.globalService.handleApiError(response); 
            }
        }, error => {
          this.globalService.showLoader=false;
          this.globalService.handleApiError(error);
        })
      }else{
          this.globalService.validateFormFields(form.form);
      }
  }

  verifyCode(form:NgForm){
    if(!form.invalid){
        this.globalService.showLoader=true; 

        this.resetAuthCheckService.checkSecret({'Secret':form.value.Secret}).subscribe(response=>{
            if(response.Status){
              this.globalService.showLoader=false; 
              this.forgetPassword =false;
              this.resetAuth =false;
              this.resetPassword =true;
            }else{
              this.globalService.showLoader=false; 
              this.globalService.handleApiError(response);
            }
        },error =>{
          this.globalService.showLoader=false; 
          this.globalService.handleApiError(error);
        })
    }else{
      this.globalService.validateFormFields(form.form);
    }
  }

  resetPass(form:NgForm){
    if(!form.invalid){
      this.globalService.showLoader=true; 
    
      this.resetPasswordService.reset({"Username":this.usernameAuth,"Password":form.value.Password}).subscribe(response=>{
          if(response.Status){
            this.jwtService.saveToken(response.Data,true);
            this.globalService.showLoader=false;

          }else{
            this.globalService.showLoader=false;
            this.globalService.handleApiError(response);
          }
      },error=>{
          this.globalService.showLoader=false;
          this.globalService.handleApiError(error);
      })

    }else{
      this.globalService.showLoader=false;  
      this.globalService.validateFormFields(form.form)
    }
  }

  resend(){
    this.globalService.showLoader=true;
    if(this.usernameAuth){
      this.forgetPasswordService.forget({"Username":this.usernameAuth}).subscribe(response=>{
        if(response.Status){ 
          this.globalService.showLoader=false;
          this.forgetPassword = false;
          this.resetAuth = true;
        }else{
          this.globalService.showLoader=false; 
          this.globalService.handleApiError(response); 
        }
      }, error => {
        this.globalService.showLoader=false;
        this.globalService.handleApiError(error);
      })
    }
  }
}
