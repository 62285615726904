import { Injectable, Inject, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, NgForm } from "@angular/forms";
import { JsonConvert, ValueCheckingMode } from "json2typescript";
import * as _ from "lodash";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DOCUMENT } from '@angular/common';
import {
  Router,
  NavigationEnd,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment
} from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { WINDOW } from "../window.provider";
import { Globals } from "../globals";

//Only For Language
import { HttpClient } from "@angular/common/http";
import { SharedService } from "src/app/shared.service";
import { environment } from "src/environments/environment";
@Injectable()
export class GlobalService {
  private roomBookingData$ = new BehaviorSubject<any>({});
  currentroomBookingData$ = this.roomBookingData$.asObservable();
  public jsonConvert: JsonConvert = new JsonConvert();
  private browserName: string;
  public showLoader: boolean = false;
  public toggleMenu: boolean = false;
  public hotelName = '';
  public toggleUserMenu: boolean = true;
  public subjectName = new Subject<any>(); //need to create a subject
  private emailSubject = new BehaviorSubject<string>(null);
  email$ = this.emailSubject.asObservable();
  private editServiceViewEmitter = new Subject<number>();
  editServiceViewEmitted$ = this.editServiceViewEmitter.asObservable();
  private editResturantViewEmitter = new Subject<number>();
  editResturantViewEmitted$ = this.editResturantViewEmitter.asObservable();
  private countMessageSource = new BehaviorSubject('default message');
  countMessage = this.countMessageSource.asObservable();
  constructor(
    private router: Router,
    private toaster: ToastrService,
    @Inject(DOCUMENT) private document: any,
    @Inject(WINDOW) private window: Window,
    public globals: Globals,
    private http: HttpClient,
    private sharedService: SharedService
  ) {
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_OBJECT_NULL;
    this.browserName = this.getBrowserName().toLowerCase();
  }

  setRoomBookingData(data: any) {
    this.roomBookingData$.next(data);
  }
  changeMessage(message: any) {
    this.countMessageSource.next(message)
  }
  emitEditServiceView(id: number) {
    this.editServiceViewEmitter.next(id);
  }
  emitEditResturantView(resturantId: number) {
    this.editResturantViewEmitter.next(resturantId);
  }
  getFirstUrlSegment() {
    const tree: UrlTree = this.router.parseUrl(window.location.pathname);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];

    if (g) {
      const s: UrlSegment[] = g.segments;
      return s[0].path;
    } else {
      return "/";
    }
  }

  getHostname(): string {
    if ("localhost" == this.window.location.hostname) {
      //return  'cityhotelxs.softobiz.biz';
      // return  'villlafontera.softobiz.biz';
      return environment.SubDomainLocal;
    } else {
      return this.window.location.hostname;
    }
  }

  getAiChatbotUrl(){
    return environment.aiApiUrl
  }
  getAiChatbotKey(){
    return environment.aiApiKey
  }
  gethotelLangTranslate(): string {
    if (!window.localStorage["hotelLangTranslate"]) {
      return this.globals.hotelLangTranslate;
    } else {
      return window.localStorage["hotelLangTranslate"];
    }
  }

  getuserLangTranslate(): string {
    if (!window.localStorage["userLangTranslate"]) {
      return this.globals.userLangTranslate;
    } else {
      return window.localStorage["userLangTranslate"];
    }
  }

  getLangSaveProperty() {
    if (!window.localStorage["langSaveProperty"]) {
      return this.globals.langSaveProperty;
    } else {
      return window.localStorage["langSaveProperty"];
    }
  }
  getLangSaveValue() {
    if (!window.localStorage["langSaveValue"]) {
      return this.globals.langSaveValue;
    } else {
      return window.localStorage["langSaveValue"];
    }
  }

  getLangAccToUser() {
    var langSelect = "en";

    if (this.HasAuthToken()) {
      // 
      if (this.getFirstUrlSegment() == "portal") {
        langSelect = this.gethotelLangTranslate();
      } else {
        langSelect = this.getuserLangTranslate();
      }
    } else {
      langSelect = this.getuserLangTranslate();
    }

    return langSelect;
  }

  loginFirst(){
    this.toaster.error("Please Login to Book Rooms", null, { timeOut: 5000 });
  }

  NoData(){
    this.toaster.error("Please Enter Data for applying filter", null, { timeOut: 5000 });
  }

  enterValidNumber(){
    this.toaster.error("Please Enter Valid Phone Number", null, { timeOut: 5000 });
  }

  editItemFirst(){
    this.toaster.error("Please save the edit item first...", null, { timeOut: 5000 });
  }

  resendOtp(){
    this.toaster.info("You can resend OTP after 60s", null, { timeOut: 5000 });
  }
  resetComponent() {
    this.router
      .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
      .then(() => this.router.navigate([window.location.pathname]));
  }

  toggleLoader() {
    this.showLoader = !this.showLoader;
  }

  toggleAdminMenuSidenav(data) {
    this.toggleMenu = data;
  }
  toggleUserMenuSidenav(data) {
    this.toggleUserMenu = data;
  }

  getToggleAdminMenuSidenav() {
    return this.toggleMenu;
  }
  getToggleUserMenuSidenav() {
    return this.toggleUserMenu;
  }
    
  setHotelNameUpdate(message: string) {   
    
    this.subjectName.next({ text: message }); //next() will feed the value in Subject
  }

  getHotelNameUpdate(): Observable<any> {     //the receiver component calls this function 
    return this.subjectName.asObservable();   //it returns as an observable to which the receiver funtion will subscribe
  }

  getUrlRest(){
    const url = this.router.url;
    const urlParts = url.split('/');
    if(urlParts.length == 4 && urlParts[1] == 'restaurants' && urlParts[3]=='true'){
      return false;
    }else if(urlParts.length == 5 && urlParts[1] == 'cart' && urlParts[2]=='resturant' && urlParts[4] == 'true'){
      return false;
    }else{
      return true;
    }
  }

  //hideloader
  hideLoader() {
    this.showLoader = false;
  }

  addClassToBody(className) {
    this.document.body.classList.add(className);
  }

  removeClassFromBody(className) {
    this.document.body.classList.remove(className);
  }

  handleErrorMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      this.toaster.error(msg, null, { timeOut: 5000 });
    } else {
      this.toaster.error("Error occured", null, { timeOut: 5000 });
    }
  }

  handleSuccessMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      this.toaster.success(msg, null, { timeOut: 3000 });
    }
  }
  signUpSuccessMessage(msg) {
      this.toaster.success(msg + ' Successfull', null, { timeOut: 3000 });
  }

  onChangeDateGuestNo(){
    this.toaster.info("Please Select Room Type and Room Name again",null,{timeOut:3000});
  }

  categoryNameTooSmall() {
      this.toaster.error( "Category Name must be between 3 to 30 characters", null, { timeOut: 3000 });
  }

  itemNameTooSmall() {
      this.toaster.error( "Item Name must be between 3 to 30 characters", null, { timeOut: 3000 });
  }

  enterValidInput() {
      this.toaster.error( "Please Enter Valid Input in Search", null, { timeOut: 3000 });
  }

  itemQuantityTooSmall() {
      this.toaster.error( "Item Quantity Should be from 1 To 9999. Please Enter Valid Quantity", null, { timeOut: 3000 });
  }

  itemPriceTooSmall() {
      this.toaster.error( "Item Price Should be from 0.1 To 999999. Please Enter Valid Price", null, { timeOut: 3000 });
  }

  noOfGuestsInvalid() {
      this.toaster.error( "No. of guest should be between 1 to 10", null, { timeOut: 3000 });
  }

  inventoryManage() {
      this.toaster.error( "Please check the Track Inventory checkbox for activating Quantity.", null, { timeOut: 3000 });
  }

  alreadyCheckIn(){
    this.toaster.error( "You are already check in!", null, { timeOut: 5000 });
  }

  handleInfoMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      this.toaster.info(msg[this.getLangAccToUser()], null, { timeOut: 3000 });
    }
  }

  handleCanNotDoPaymentMessage() {
    this.toaster.info("Please Select item for Payment", null, {timeOut: 5000});
  }
  handleSearchError(){
    this.toaster.info("Please Select Checkin and Checkout date", null ,{timeOut:3000});
  }

  handleNoAmount() {
    this.toaster.info("No Amount To Pay", null, {timeOut: 5000});
  }

  serviceStatus() {
    this.toaster.info("No Payable Service, Request Status Pending. Please Wait", null, {timeOut: 5000});
  }

  allNotPayable(data) {
    this.toaster.info("All " + data + " bills are not Payable", null, {timeOut: 5000});
  }

  notPayable(data,product) {
    let status;
    if(data.progressStatus == 1){
      status = "Open";
    }else if(data.progressStatus == 5){
      status = "Closed";
    }
    this.toaster.info("This " + product + " bill is not Payable Request Status is " + status , null, {timeOut: 5000});
  }

  // rangeError() {
  //   this.toaster.info("Please add some Range data first", null, {timeOut: 3000});
  // }
  rangeDataExist() {
    this.toaster.error("This Range Already Exists", null, {timeOut: 3000});
  }

  enterCorrectRange() {
    this.toaster.error("Please Enter Correct Range", null, {timeOut: 5000});
  }
  setEmail(email: string) {
    this.emailSubject.next(email);
  }

  NoInput() {
    this.toaster.error("No Input! Please Enter Room no. to add", null, {timeOut: 5000});
  }

  WrongInput() {
    this.toaster.error("No Input! Please select staff for assigning", null, {timeOut: 5000});
  }
  sameRange() {
    this.toaster.error("Same Range!! Please Change the input", null, {timeOut: 5000});
  }

  paymentMethodNotAvailable(method) {
    this.toaster.info("Please Choose another option for Payment, " + method + " is unavailable for now.", null, {timeOut: 5000});
  }

  messageLength(data){
    this.toaster.error("Your Message Exceeds the max length for message by: " + data, null, {timeOut: 5000});
  }

  checkInDateGreater(){
    this.toaster.error("Please Give Valid Check in Date. Check in date cannot be Greater then CheckOut Date", null, {timeOut: 5000});
  }

  checkInDateLessThenCurrent(){
    this.toaster.error("Please Give Valid Check in Date. Check in date already passed today's date", null, {timeOut: 5000});
  }

  guestValidator(Guests){
    this.toaster.error("No of Guest Allowed in this Room is " + Guests + " .Please Enter Correct Guest No." , null, {timeOut: 5000});
  }

  guestConformation(Guests){
    this.toaster.error("Only " + Guests + " Guests are allowed." , null, {timeOut: 5000});
  }

  // messageLength(data){
  //   this.toaster.error("Your Message Exceeds the max length for message by: " + data, null, {timeOut: 5000});
  // }

  paymentSuccessfull(){
    this.toaster.success("Your Payment is Successfull", null, {timeOut: 5000});
  }

  roomAvailable(){
    this.toaster.error("this room is not available on your selected dates", null, {timeOut: 5000});
  }

  estimateTimeNeeded(){
    this.toaster.error("Please enter the estimated Time before moving to preparing", null, {timeOut: 5000});
  }

  assignedStaffNeed(Location){
    this.toaster.error("Please assign Staff before moving to " + Location + '.', null, {timeOut: 5000});
  }

  handleApiError(input, submit: boolean = null) {
    if (
      !this.validateIsEmpty(input) &&
      !this.validateIsEmpty(input.error) &&
      !this.validateIsEmpty(input.error.Errors)
    ) {
      if (submit) {
        for (let i = 0; i < input.error.Errors.length; i++) {
          this.handleErrorMessage(input.error.Errors[i].Message);
        }
      }

      
      // 
      //As we are maintaining two Auth tokens
      // if (!this.HasAuthToken()) {
      //   this.DeleteAuthToken();
      // }

      //Token failed error-----------------
      if (input.error.Message) {
        if (input.error.Message.toString() == "Token Failed") {
          this.DeleteAuthToken();
        }
      }

      //Auth raw errors  500--------------
      if (input.error.Errors.raw) {
        this.handleErrorMessage(input.error.Errors.raw.message);
      } else {
        //TokenExpiredError
        if (input.error.Errors.name) {
          if (input.error.Errors.name.toString() == "TokenExpiredError") {
            this.DeleteAuthToken();
          }
          if (
            input.error.Errors.name.toString() == "CheckInTokenExpiredError"
          ) {
            
            this.deleteGuestAuthToken();
          }
        }
      }

      //Stripe errors-----------------------
      if (input.error.Errors.stripe) {
        
        this.handleErrorMessage(input.error.Errors.stripe);
        return false;
      }

      //Already exists-----------------------
      if (input.Message == "Already Exists") {
        this.handleErrorMessage(input.Message);
      }
    } else if (
      !this.validateIsEmpty(input) &&
      !this.validateIsEmpty(input.error) &&
      !this.validateIsEmpty(input.error.Message)
    ) {
      if (submit) {
        this.handleErrorMessage(input.error.Message);
      }

      if (input.error.Message.toString() == "Token Failed") {
        this.DeleteAuthToken();
      }
      if (input.error.Message.toString() == "Guest Token Failed") {
        this.deleteGuestAuthToken();
      }
      if (input.error.Message.toString() == "Password is Wrong") {
        this.handleErrorMessage(input.error.Message);
      }
      
    } else if (
      !this.validateIsEmpty(input) &&
      !this.validateIsEmpty(input.Errors)
    ) {
      

      if (input.Errors == "AlreadyRegistered") {
        this.handleErrorMessage(input.Message);
        this.router.navigate(["/portal"]);
      } else {
        for (let i = 0; i < input.Errors.length; i++) {
          this.handleErrorMessage(input.Errors[i].Message);
        }
      }
    } else if (
      !this.validateIsEmpty(input) &&
      !this.validateIsEmpty(input.Message)
    ) {
      this.handleErrorMessage(input.Message);
      
    } else {
      

      this.http
        .get("assets/portal/i18n/" + this.getLangAccToUser() + ".json", {
          responseType: "json"
        })
        .subscribe(data =>
          this.handleErrorMessage(data["ServerErrors"]["somethingWentWrong"])
        );
    }
  }

  downloadFileWithURL(fileURL, fileName) {
    let a = document.createElement("a");
    a.setAttribute("style", "display:none");
    a.setAttribute("href", fileURL);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    }, 100);
  }

  downloadFileWithBlob(fileBlob, fileName) {
    let fileURL = URL.createObjectURL(fileBlob);
    let a = document.createElement("a");
    a.setAttribute("style", "display:none");
    a.setAttribute("href", fileURL);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    }, 100);
  }

  cloneData(data, defaultReturn: string = "") {
    if (!this.validateIsEmpty(data)) {
      return _.cloneDeep(data);
    } else {
      return defaultReturn;
    }
  }

  HasAuthToken(): boolean {
    if (
      window.sessionStorage["AUTH_TOKEN"] ||
      window.localStorage["AUTH_TOKEN"]
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasCheckInAuthToken(): boolean {
    if (
      window.sessionStorage["CheckIn_TOKEN"] ||
      window.localStorage["CheckIn_TOKEN"]
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasLoginInAuthToken(): boolean {
    if (
      window.sessionStorage["LogIn_TOKEN"] ||
      window.localStorage["LogIn_TOKEN"]
    ) {
      return true;
    } else {
      return false;
    }
  }

  DeleteAuthToken(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
    
    this.router.navigate(["/portal"]);
  }

  deleteGuestAuthToken(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
    
    this.router.navigate(["/"]);
  }

  deleteCheckInAuthToken() {
    window.localStorage.removeItem("CheckIn_TOKEN");
    this.router.navigate(["/"]);
  }

  deleteLogInAuthToken() {
    // 
    window.localStorage.clear();
    this.router.navigate(["/"]);
  }

  // validateFormFields(formGroup: FormGroup) {
  //   let formErrors = [];
  //   Object.keys(formGroup.controls).forEach(field => {
  //     const control = formGroup.get(field);
  //     if (control instanceof FormControl) {
  //       if (control.invalid) {
  //         const errors = Object.keys(control.errors);
  //         for (let j = 0; j < errors.length; j++) {
  //           formErrors.push({ Field: field, Message: field + " " + errors[j] });
  //         }
  //       }
  //       control.markAsTouched({ onlySelf: true });
  //     }
  //     else if (control instanceof FormGroup) {
  //       this.validateFormFields(control);
  //     }
  //   });
  //   return formErrors;
  // }

  validateFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateFormFields(control);
      }
    });
  }

  containFormError(form: NgForm, ctrlName) {
    let formErrors = [];
    try {
      if (form.form.controls[ctrlName].invalid) {
        const errors = Object.keys(form.form.controls[ctrlName].errors);
        for (let i = 0; i < errors.length; i++) {
          formErrors.push(ctrlName + " " + errors[i]);
        }
      }
      return formErrors;
    } catch (error) {
      return formErrors;
    }
  }

  getActivatedRoute(): Observable<any> {
    return Observable.create(observer => {
      this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          const route = val.urlAfterRedirects.split("/")[
            val.urlAfterRedirects.split("/").length - 1
          ];
          observer.next(route);
        }
      });
    });
  }

  getPreciseAge(date): string {
    if (!this.validateIsEmpty(date)) {
      let currentDate = new Date();
      let dateOfBirth = new Date(date);
      let age: string = "";
      let year = currentDate.getFullYear() - dateOfBirth.getFullYear();
      let month = currentDate.getMonth() - dateOfBirth.getMonth();
      let currentDayDate = currentDate.getDate();
      let birthDayDate = dateOfBirth.getDate();

      if (month == 0 && currentDayDate < birthDayDate) {
        year = year - 1;
        month = 11;
      } else if (month < 0) {
        year = year - 1;
        month = 12 - Math.abs(month);
      }

      if (year != 0) {
        age = age + year.toString() + " Years";
      }

      if (month != 0) {
        if (age != "") {
          age = age + " " + month.toString() + " Months";
        } else {
          age = age + month.toString() + " Months";
        }
      }

      return age;
    } else {
      return "";
    }
  }

  concatFnameLname(fname, lname) {
    if (!this.validateIsEmpty(fname) && !this.validateIsEmpty(lname)) {
      return fname.toString() + " " + lname.toString();
    } else if (!this.validateIsEmpty(fname)) {
      return fname.toString();
    } else {
      return "";
    }
  }

  concatAddress(address, city, zipcode, state, country) {
    let completeAddress = [address, city, zipcode, state, country];
    completeAddress = completeAddress.filter(obj => {
      if (!this.validateIsEmpty(obj)) {
        return obj;
      }
    });
    return completeAddress.join(", ");
  }

  printNameWithPost(fname, lname, post) {
    let fullName: string = "";
    if (this.validateIsEmpty(fname)) {
      return "";
    } else {
      if (!this.validateIsEmpty(fname) && !this.validateIsEmpty(lname)) {
        fullName = fullName + fname + " " + lname;
      } else {
        fullName = fullName + fname;
      }
      if (this.validateIsEmpty(post)) {
        return fullName;
      } else {
        return [fullName, post].join(", ");
      }
    }
  }

  checkBrowserCompatibility(notAllowedBrowsers) {
    return notAllowedBrowsers.filter(browser => {
      if (this.browserName.search(browser) !== -1) {
        return browser;
      }
    });
  }

  getBrowserName() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null)
        return tem
          .slice(1)
          .join(" ")
          .replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  }

  upgradeDateByDays(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setDate(incomingDate.getDate() + counter));
  }

  upgradeDateByMonths(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setMonth(incomingDate.getMonth() + counter));
  }

  upgradeDateByYears(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(
      incomingDate.setFullYear(incomingDate.getFullYear() + counter)
    );
  }

  downgradeDateByDays(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setDate(incomingDate.getDate() - counter));
  }

  downgradeDateByMonths(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setMonth(incomingDate.getMonth() - counter));
  }

  downgradeDateByYears(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(
      incomingDate.setFullYear(incomingDate.getFullYear() - counter)
    );
  }

  convertTimeFormat(oldTimeFormat) {
    if (this.validateIsEmpty(oldTimeFormat)) {
      return;
    } else {
      let newTimeFormat = oldTimeFormat
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [oldTimeFormat];
      if (newTimeFormat.length > 1) {
        newTimeFormat = newTimeFormat.slice(1);
        newTimeFormat[5] = +newTimeFormat[0] < 12 ? " am" : " pm";
        newTimeFormat[0] = +newTimeFormat[0] % 12 || 12;
      }
      let array = Object.values(newTimeFormat);
      array.splice(3, 1);
      return array.join("");
    }
  }

  validateImageSize(file: File, minWidth, minHeight): Observable<any> {
    return Observable.create(observer => {
      let newImage: any = new Image();
      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = function(loadEvent: any) {
        newImage.src = loadEvent.target.result;
        newImage.onload = function() {
          if (newImage.width >= minWidth && newImage.height >= minHeight) {
            observer.next({
              status: true,
              width: newImage.width,
              height: newImage.height,
              image: newImage
            });
          } else {
            observer.next({
              status: false
            });
          }
        };
      };
      fileReader.readAsDataURL(file);
    });
  }

  dataURLtoBlob(dataURI): Observable<any> {
    return Observable.create(observer => {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      observer.next({
        data: blob
      });
    });
  }

  compressImage(
    image,
    fileType,
    maxWidth,
    imageWidth,
    imageHeight
  ): Observable<any> {
    return Observable.create(observer => {
      let originalWidth = imageWidth;
      let originalHeight = imageHeight;
      let ratio = originalHeight / originalWidth;
      if (originalWidth > maxWidth) {
        originalWidth = maxWidth;
        originalHeight = ratio * maxWidth;
      }
      let canvas = document.createElement("canvas");
      let compressedImage = new Image();
      canvas.width = originalWidth;
      canvas.height = originalHeight;
      canvas
        .getContext("2d")
        .drawImage(image, 0, 0, originalWidth, originalHeight);
      compressedImage.src = canvas.toDataURL(fileType, 1);
      observer.next({
        status: true,
        data: {
          image: compressedImage,
          width: originalWidth,
          height: originalHeight
        }
      });
    });
  }

  convertIntIntoFixedDecimal(number: number, range) {
    return number.toFixed(range);
  }

  // Mechanism By Which We Can Simply Solve Issue of (Selected Date - 1)
  // 
  // this.model.DOB.setMinutes((this.model.DOB.getTimezoneOffset() * -1));
  // 

  integralTimePart(time, type) {
    return parseInt(time.split(":")[type]);
  }

  timeSlotIntersection(o1_startTime, o1_endTime, o2_startTime, o2_endTime) {
    let o1_StartTime =
      this.integralTimePart(o1_startTime, 0) * 60 +
      this.integralTimePart(o1_startTime, 1);
    let o1_EndTime =
      this.integralTimePart(o1_endTime, 0) * 60 +
      this.integralTimePart(o1_endTime, 1);
    let o2_StartTime =
      this.integralTimePart(o2_startTime, 0) * 60 +
      this.integralTimePart(o2_startTime, 1);
    let o2_EndTime =
      this.integralTimePart(o2_endTime, 0) * 60 +
      this.integralTimePart(o2_endTime, 1);

    if (
      (o2_StartTime >= o1_StartTime && o2_StartTime < o1_EndTime) ||
      (o2_EndTime > o1_StartTime && o2_EndTime <= o1_EndTime)
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateIsEmpty(input, list: Array<String> = []) {
    if ((typeof input).toLowerCase() == "boolean") {
      return false;
    } else if (input != undefined && input != null && input != "") {
      if (input instanceof Date) {
        return false;
      } else if (Array.isArray(input) && input.length != 0) {
        return false;
      } else if ((typeof input).toLowerCase() == "object") {
        let isedit = false;
        Object.keys(input).map(key => {
          if (
            list.indexOf(key.toString()) == -1 &&
            (input[key] != undefined && input[key] != null && input[key] != "")
          ) {
            isedit = true;
          }
        });
        if (isedit) {
          return false;
        } else {
          return true;
        }
      } else if (
        (typeof input).toLowerCase() == "string" &&
        input.length != 0
      ) {
        return false;
      } else if ((typeof input).toLowerCase() == "number") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  validateObjectIsEmpty(input) {
    if ((typeof input).toLowerCase() == "object") {
      
      
      if (Array.isArray(input) && input.length != 0) {
        
        return false;
      } else {
        
        return true;
      }
    } else {
      return true;
    }
  }

  checkObjectEquality(o1, o2, key = null) {
    let o1PropNames = Object.getOwnPropertyNames(o1);
    let o2PropNames = Object.getOwnPropertyNames(o2);
    if (o1PropNames.length !== o2PropNames.length) {
      return false;
    }
    for (let i = 0; i < o1PropNames.length; i++) {
      if (key != null && o1PropNames[i] == key) {
        continue;
      }
      if (o1[o1PropNames[i]] != o2[o1PropNames[i]]) {
        return false;
      }
    }
    return true;
  }

  getDefaultval() {
    return "N/A";
  }

  validateIsEmptyAndSetDefVal(input) {
    if (this.validateIsEmpty(input)) {
      return this.getDefaultval();
    } else {
      return input;
    }
  }

  componentDestroyed(component: OnDestroy) {
    const oldNgOnDestroy = component.ngOnDestroy;
    const destroyed$ = new ReplaySubject<void>(1);
    component.ngOnDestroy = () => {
      oldNgOnDestroy.apply(component);
      destroyed$.next(undefined);
      destroyed$.complete();
    };
    return destroyed$;
  }

  formatPhoneNumber(phoneNumberString) {
    var match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return +match[1] + "-" + match[2] + "-" + match[3];
    }
    return "";
  }

  formattedPhoneNumber(input) {
    if (Array.isArray(input) && input.length > 0) {
      let primeNumber = input.find(item => item.IsPrimary == 1);
      let formattedNumber = this.formatPhoneNumber(primeNumber.PhoneNumber);
      if (
        !this.validateIsEmpty(primeNumber) &&
        !this.validateIsEmpty(formattedNumber)
      ) {
        return "+" + primeNumber.PhoneCountryCode + "-" + formattedNumber;
      } else {
        return this.getDefaultval();
      }
    } else if (
      (typeof input).toLowerCase() == "object" &&
      !this.validateIsEmpty(input) &&
      !this.validateIsEmpty(this.formatPhoneNumber(input.PhoneNumber))
    ) {
      return (
        "+" +
        input.PhoneCountryCode +
        "-" +
        this.formatPhoneNumber(input.PhoneNumber)
      );
    } else {
      return this.getDefaultval();
    }
  }

  //Truncate String
  truncate(str, no_words) {
    return str
      .split(" ")
      .splice(0, no_words)
      .join(" ");
  }

  //Concatenate path for Lang translate
  concatenate(panel, language) {
    return panel + "-" + language;
  }

  //Emit Broadcast Permission Details---------------------------------
  emitPermissions(bulkPermissions) {
    if (!this.validateIsEmpty(bulkPermissions)) {
      //If not Empty then emit
      // 

      window.localStorage["bulkPermissions"] = JSON.stringify(bulkPermissions);
      this.sharedService.permissionEvent.emit(bulkPermissions);
    }
  }

  emitGuestPermissions(permissions) {
    if (!this.validateIsEmpty(permissions)) {
      window.localStorage["guestPermissions"] = JSON.stringify(permissions);
      this.sharedService.guestPermissionEvent.emit(permissions);
    }
  }

  //Emit Perference Details---------------------------------
  emitPreferenceEvent(perferenceData) {
    this.sharedService.preferenceEvent.emit(perferenceData);
  }
  editPreference(perferenceData) {
    this.sharedService.editPreferenceEvent.emit(perferenceData);
  }
  emitRemovePreference(perferenceData) {
    this.sharedService.emitRemovePreferenceEvent.emit(perferenceData);
  }
}
