import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from "@angular/material/table";
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { CheckedInService } from 'src/app/users/services/checked-in.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.scss']
})
export class PaymentSuccessPageComponent implements OnInit {

  constructor(
    private globalService: GlobalService,
    private checkedInService : CheckedInService,
    private router: Router
  ) { }
  public transactionDate = '';
  public continue = false;
  public cartItems=[];
  public serviceType;
  public logo ="";
  ngOnInit() {
   
    const today = new Date();
    const date = today.getDate();
    const year = today.getFullYear();
    const month = today.toLocaleString('default', { month: 'short' });
    const time = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    this.transactionDate = `${date} ${month}, ${year}`;
    this.cartItems = JSON.parse(localStorage.getItem('serviceCartItems'));
    if(window.localStorage["ServiceType"]){
      this.serviceType = window.localStorage['ServiceType'];
    }
    this.getlogo();
    const currentUrl = window.location.href;
    if(currentUrl.length > 200){
      this.urlBillingInfo(currentUrl);
    }else{
      this.continue = true;
    }
    localStorage.removeItem("serviceCartItems");
    localStorage.removeItem("serviceName");
    localStorage.removeItem("ServiceType");
    localStorage.removeItem("serviceNameId");
    //serviceCartItems serviceName ServiceType serviceNameId
  }

  urlBillingInfo(url){
    let paramaters = (new URL(url)).searchParams;
    const amount = paramaters.get("amount");
    const currency = paramaters.get("currency");
    const paymentBy = paramaters.get("paymentBy");
    const Items= this.cartItems
    const BookingUserId = paramaters.get("BookingUserId");
    const ServiceId = paramaters.get("ServiceId");
    const ServiceType = paramaters.get("ServiceType");
    const RequestType = 'service';
    const guestName = paramaters.get("guestName");
    const Message = paramaters.get("Message");
    const HotelId = paramaters.get("HotelId");
    const PayerID = paramaters.get("PayerID");
    const paymentId = paramaters.get("paymentId");
    const ServiceDateFrom = paramaters.get("ServiceDateFrom");
    const ServiceDateTo = paramaters.get("ServiceDateTo");
    const ServiceTimeFrom = paramaters.get("ServiceTimeFrom");
    const ServiceTimeTo = paramaters.get("ServiceTimeTo");
    const DepartmentId = paramaters.get('DepartmentId');
    const TaxCountryId = paramaters.get('TaxCountryId')
    if(paymentBy != 'stripe'){

      const data = {
        amount: amount,
      currency: currency,
      BookingUserId: BookingUserId,
      HotelId: HotelId,
      Items:Items,
      RequestType : RequestType,
      ServiceDateFrom : ServiceDateFrom,
      ServiceDateTo : ServiceDateTo,
      ServiceId: ServiceId,
      ServiceType: ServiceType,
      ServiceTimeFrom : ServiceTimeFrom,
      ServiceTimeTo : ServiceTimeTo,
      guestName:guestName,
      Message:Message,
      PayerID: PayerID,
      paymentId: paymentId,
      DepartmentId:DepartmentId,
      TaxCountryId:TaxCountryId
    }
    this.checkedInService
    .urlBillingInfo(data)
    .pipe(takeUntil(this.globalService.componentDestroyed(this)))
    .subscribe(
      response => {
        this.router.navigate(['/guest/payment/success']);
        this.globalService.showLoader = true;
        localStorage.removeItem('serviceCartItems')
        this.continue = true;
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
      );
    }
    }
    
    billing(){
    if(this.serviceType){
      this.router.navigate(['hotel-services']);
    }else{
      this.router.navigate(['/guest/billing-history']);
    }
  }

  getlogo(){
    if (window.localStorage["Logo"]){
  this.logo = window.localStorage.getItem('Logo');
    }
  }

  GoToResturantPage(){
    this.router.navigate(['hotel-services'])
  }

  ngOnDestroy(){

  }

}
