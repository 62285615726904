import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ResultModel } from '../models/response/base.model';

@Injectable()
export class UserService {

  constructor(private apiService: ApiService) { }

  signIn(reqModel): Observable<ResultModel> {
    const route = '/account/checkIn';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  checkIn(reqModel): Observable<ResultModel> {
    const route = '/account/checkIn';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
}
