import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter,BooleanConverter,NullableString } from 'src/app/core/models/essentials';


@JsonObject("HotelGalleryTitleLangModel")
export class HotelGalleryTitleLangModel {
    @JsonProperty("Lang", NullableString, true) 
    Lang: string = undefined;
    @JsonProperty("GalleryTitle",NullableString, true)
    GalleryTitle:string = undefined;
    @JsonProperty("GalleryButtonText",NullableString, true)
    GalleryButtonText:string = undefined;
}

@JsonObject("HotelGalleryTitleModel")
export class HotelGalleryTitleModel {
    @JsonProperty("HotelGalleryTitleLang", [HotelGalleryTitleLangModel], true) 
    HotelGalleryTitleLang: [HotelGalleryTitleLangModel] = undefined;
    @JsonProperty("GalleryButtonType", NullableString, true) 
    GalleryButtonType: string = undefined;
} 