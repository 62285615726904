<!-- //header -->
<div class="d-inline-block w-100">
    <!-- page info header -->
    <div class="page-header-section text-center mb-2 d-flex justify-content-center">
        <h1 class="page-title">UI Elements</h1>
    </div>
    <!-- //page info header -->
    <!-- main content -->
    <div class="main-content-area mx-auto" style="float:none;">

        <!-- headings style -->
        <div class="block-container">
            <h4 class="block-title">Headings and paragraph</h4>
            <h1>Manage Branding</h1>
            <h2>Manage Branding</h2>
            <h3>Manage Branding</h3>
            <h4>Manage Branding</h4>
            <hr />
            <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Atque et illo natus nemo, illum reprehenderit magnam. Recusandae asperiores quisquam laboriosam perferendis alias consectetur. Doloremque fugiat possimus sunt cupiditate harum? Nobis. Lorem, ipsum
                dolor sit amet consectetur adipisicing elit. Atque et illo natus nemo, illum reprehenderit magnam. Recusandae asperiores quisquam laboriosam perferendis alias consectetur. Doloremque fugiat possimus sunt cupiditate harum? Nobis. Lorem,
                ipsum dolor sit amet consectetur adipisicing elit. Atque et illo natus nemo, illum reprehenderit magnam. Recusandae asperiores quisquam laboriosam perferendis alias consectetur. Doloremque fugiat possimus sunt cupiditate harum? Nobis.
            </p>
            <br>
            <p class="font-weight-bold">Bold text.</p>
            <p class="font-weight-normal">Normal weight text.</p>
            <p class="font-weight-light">Light weight text.</p>
            <p class="font-italic">Italic text.</p>
            <br>
            <p class="text-primary">.text-primary</p>
            <p class="text-secondary">.text-secondary</p>
            <p class="text-success">.text-success</p>
            <p class="text-danger">.text-danger</p>
            <p class="text-warning">.text-warning</p>
            <p class="text-orange">.text-orange</p>
            <p class="text-info">.text-info</p>
            <p class="text-dark bg-light">.text-light</p>
            <p class="text-dark">.text-dark</p>
            <p class="text-muted">.text-muted</p>
            <p class="text-white bg-dark">.text-white .bg-dark</p>
            <br>
            <div class="p-3 mb-2 bg-primary text-white">.bg-primary</div>
            <div class="p-3 mb-2 bg-secondary text-white">.bg-secondary</div>
            <div class="p-3 mb-2 bg-success text-white">.bg-success</div>
            <div class="p-3 mb-2 bg-danger text-white">.bg-danger</div>
            <div class="p-3 mb-2 bg-warning text-dark">.bg-warning</div>
            <div class="p-3 mb-2 bg-info text-white">.bg-info</div>
            <div class="p-3 mb-2 bg-light text-dark">.bg-light</div>
            <div class="p-3 mb-2 bg-dark text-white">.bg-dark</div>
            <div class="p-3 mb-2 bg-white text-dark">.bg-white</div>

        </div>
        <!-- //headings style -->

        <!-- Form style -->
        <div class="block-container">
            <h4 class="block-title">Form input</h4>
            <form class="form-wrapper">
                <div class="row">
                    <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" placeholder="Name">
                    </mat-form-field>

                    <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <input matInput placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="email" placeholder="Phone Number">
                    </mat-form-field>

                    <mat-form-field class="mb10 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <mat-label>Password</mat-label>
                        <input matInput required type="password" placeholder="Password">
                    </mat-form-field>

                    <mat-form-field class="mb10 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <mat-select placeholder="Choose currency" [formControl]="currencye">
                            <mat-option *ngFor="let currency of currencyList" [value]="currency">{{currency}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="mb10 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <mat-select placeholder="multiple selection" [formControl]="toppings" multiple>
                            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="mb10 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="example-full-width mb10 col-lg-12">
                        <textarea matInput placeholder="Leave a comment"></textarea>
                    </mat-form-field>

                </div>

                <div class="row">
                    <h4 class="block-title col-lg-12">Radio/checkbox and slider</h4>
                    <mat-radio-group class="mb10 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <mat-radio-button value="1" class="col-lg-6">Option 1</mat-radio-button>
                        <mat-radio-button value="2" class="col-lg-6">Option 2</mat-radio-button>
                    </mat-radio-group>
                    <mat-checkbox class="mb10 col-lg-3 col-md-3 col-sm-6 col-xs-12">Check me!</mat-checkbox>
                    <mat-slide-toggle class="mb10 col-lg-3 col-md-3 col-sm-6 col-xs-12">Slide me!</mat-slide-toggle>
                    <mat-slide-toggle class="mb10 col-lg-3 col-md-3 col-sm-6 col-xs-12 custom">Custom Slide me!
                    </mat-slide-toggle>
                </div>
            </form>
        </div>
        <!-- //Form style -->


        <!-- button style -->
        <div class="block-container">
            <h4 class="block-title">Buttons</h4>
            <button type="button" class="btn btn-primary">Primary</button>
            <button type="button" class="btn btn-secondary">Secondary</button>
            <button type="button" class="btn btn-success">Success</button>
            <button type="button" class="btn btn-danger">Danger</button>
            <button type="button" class="btn btn-warning">Warning</button>
            <button type="button" class="btn btn-info">Info</button>
            <button type="button" class="btn btn-light">Light</button>
            <button type="button" class="btn btn-dark">Dark</button>
            <button type="button" class="btn btn-link">Link</button>
            <br>
            <hr>
            <br>
            <h4 class="block-title">Buttons with icon</h4>
            <button type="button" class="btn btn-primary"><i class="material-icons">check</i>Primary</button>
            <button type="button" class="btn btn-secondary"><i class="material-icons">create</i>Secondary</button>
            <button type="button" class="btn btn-success"><i class="material-icons">check_circle</i>Success</button>
            <button type="button" class="btn btn-danger"><i class="material-icons">delete</i>Danger</button>
            <button type="button" class="btn btn-warning"><i class="material-icons">warning</i>Warning</button>
            <button type="button" class="btn btn-info"><i class="material-icons">info</i>Info</button>
            <button type="button" class="btn btn-light"><i class="material-icons">close</i>Light</button>
            <button type="button" class="btn btn-dark"><i class="material-icons">email</i>Dark</button>
            <button type="button" class="btn btn-link"><i class="material-icons">clear</i>Link</button>
            <button type="button" class="btn btn-white-border"><i class="material-icons">filter_list</i>Filter</button>
        </div>
        <!-- //button style -->


        <!-- button style -->
        <div class="block-container">
            <h4 class="block-title">Modal and tooltip</h4>
            <br>
            <br>
            <div class="row">
                <div class="col-lg-3">
                    <button mat-raised-button matTooltip="Info about the action"> Action </button>
                </div>

                <div class="col-lg-3">
                    <button mat-raised-button matTooltip="Info about the action" matTooltipPosition="below"> Action
          </button>
                </div>

                <div class="col-lg-3">
                    <button mat-raised-button matTooltip="Info about the action" matTooltipPosition="left"> Action
          </button>
                </div>

                <div class="col-lg-3">
                    <button mat-raised-button matTooltip="Info about the action" matTooltipPosition="right"> Action
          </button>
                </div>
            </div>
        </div>
        <!-- //button style -->


        <div class="block-container">
            <h4 class="block-title">Page details</h4>
            <!-- detail -->
            <div class="room-info-details row">
                <!-- About room -->
                <div class="col-lg-12 room-name-info">
                    <ul class="review-ul d-flex align-items-center">
                        <li>
                            <p class="secondary-text">Secondary Text</p>
                        </li>
                        <li class="d-flex align-items-center">
                            <div class="mr-2 review-star">
                                <i class="material-icons">star</i>
                                <i class="material-icons">star</i>
                                <i class="material-icons">star</i>
                                <i class="material-icons">star</i>
                                <i class="material-icons">star</i>
                            </div> <span>9 review</span>
                        </li>
                        <li>
                            <p>Paid: $100/unit</p>
                        </li>
                    </ul>
                    <h2 class="primary-text">
                        Title
                    </h2>
                    <span>
            <p class="date-time-list date-time-list-detail">
              <span class="date-from">
                <ng-container>
                  20 Dec, 2018 -
                </ng-container>
                <ng-container>
                  25 Dec, 2018
                </ng-container>
              </span>
                    <span class="time-from">
                <ng-container>
                  9:24 am -
                </ng-container>
                <ng-container>
                  7:59 pm
                </ng-container>
              </span>
                    </p>
                    </span>
                </div>
                <!-- //About room -->

                <div class="col-lg-12 editor-content-wrapper">
                    <div class="secondary-text ckeditor-front">
                        <p><strong>Child care</strong>, otherwise known as <strong>day care</strong>, is the care and supervision of a child or multiple children at a time. Child care is the action or skill of looking after children by a day-care center,
                            nannies, babysitter, teachers or other providers. Child care is a broad topic that covers a wide spectrum of professionals, institutions, contexts, activities, and social and cultural conventions. Early child care is an equally
                            important and often overlooked component of child development. Child care providers can be children's first teachers, and therefore play an integral role in systems of early childhood education. Quality care from a young age
                            can have a substantial impact on the future successes of children. The main focus of childcare is on the development of the child, whether that be mental, social, or psychological.[1]
                        </p>
                    </div>
                </div>

                <!-- tabs for overview and menu  -->
                <div class="block-container col-lg-12 custom-block-container">
                    <mat-tab-group>
                        <mat-tab label="Overview">
                            <ng-container>
                                <div class="secondary-text">
                                </div>
                            </ng-container>
                        </mat-tab>
                        <mat-tab label="Menu">

                        </mat-tab>
                        <mat-tab label="Reviews">
                            <div class="reviews-list">
                                <span>
                  <i class="material-icons">
                    star_rate
                  </i>
                </span>
                                <span>
                  <i class="material-icons">
                    star_rate
                  </i>
                </span>
                                <span>
                  <i class="material-icons">
                    star_rate
                  </i>
                </span>
                                <span>
                  <i class="material-icons">
                    star_rate
                  </i>
                </span>
                                <span>
                  <i class="material-icons">
                    star_rate
                  </i>
                </span>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <!-- tabs for overview and menu  -->
            </div>
        </div>

        <div class="block-container">
            <div class="add-department add-room-page">
                <!-- page info header -->
                <div class="page-header-section clearfix">
                    <h1 class="page-title">
                        <i style="cursor:pointer;" class="material-icons">keyboard_backspace</i>{{ "Add department" | translate }}
                    </h1>
                    <div class="button-wrapper">
                        <div class="lang-drop-menu">
                            <span>{{ "Edit Text For" | translate }}:</span>
                        </div>

                        <button class="btn btn-link pr-4 ml-0" type="button">
              <i class="material-icons">clear</i> {{ "Cancel" | translate }}
            </button>
                        <button class="btn btn-link ml-0 pl-4" type="button">
              <i class="material-icons">clear</i> {{ "Delete" | translate }}
            </button>
                        <button class="btn btn-primary ml-0" type="button">
              <i class="material-icons">check</i>
              <span>{{ "Save" | translate }} </span>
            </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-container">
            <div class="edit-about-bottom upload-image-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 upload-image-left">
                        <!-- <div class="uload-image">
                            <i class="fa fa-trash fa-6 remove-img" style="color:red;" aria-hidden="true" (click)="removeDomImage(imageAboutPageUrl)"></i>
                            <file-upload (imageUpload)="imageUpload($event)" [locationStatus]='parentLocationStatus' [imgUrl]="imageAboutPageUrl"></file-upload>
                        </div> -->
                    </div>
                    <div class="col-md-6 col-sm-6 field-sec-right">
                        <mat-form-field>
                            <mat-label>{{'Images caption'|translate}}</mat-label>
                            <input matInput type="text" formControlName="ImagesCaption" [placeholder]="'Images caption'|translate">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- //main content -->
    <!-- <section>
        <div class="form-group">
            <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl">
        </div>
        <agm-map [latitude]="lat" [longitude]="lng" (mapClick)="onChooseLocation($event)">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
    </section> -->
</div>
<!-- <star-rating value="5" checkedcolor="red" uncheckedcolor="black" size="24px" readonly="false" (rate)="onRate($event)">
</star-rating> -->

<!-- <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time">
<owl-date-time [pickerType]="'timer'" [pickerMode]="'dialog'" #dt1></owl-date-time> -->


<!-- <div #map style=”width:100%;height:400px”></div> -->
<div class="form-group">
    <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl">
</div>
<!-- <p>maps were here(ui-kit.component)</p> -->
<!-- <agm-map [latitude]="lat" [longitude]="lng" (mapClick)="onChooseLocation($event)" #mapAgm>
    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
</agm-map> -->

<mat-form-field>
    <input matInput [matDatepicker]="picker1" placeholder="Choose a date (1)" formControlName="control">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
</mat-form-field>

<mat-form-field>
    <input matInput [matDatepicker]="picker2" placeholder="Choose a date (2)">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
</mat-form-field>