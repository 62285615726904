import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-google-map-common",
  templateUrl: "./google-map-common.component.html",
  styleUrls: ["./google-map-common.component.scss"]
})
export class GoogleMapCommonComponent implements OnInit {
  public lat: any = 51.678418;
  public lng: any = 7.809007;
  constructor() {}

  ngOnInit() {}
}
