import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import {
   MatDialogRef,
   MatDialog,
  MAT_DIALOG_DATA,
 MatDialogConfig
} from "@angular/material/dialog";
import { MasterService } from "../../services/master.service";
import { Globals } from "../../globals";
import { ResturantTypeArrayModel } from "../../models/common-request-response";
import { takeUntil } from "rxjs/operators";
import { AddNewResturantTypeModalComponent } from "../add-new-resturant-type-modal/add-new-resturant-type-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogModalComponent } from "../confirm-dialog-modal/confirm-dialog-modal.component";
import { SharedService } from "src/app/shared.service";

@Component({
  selector: "app-update-resturant-modal",
  templateUrl: "./update-resturant-modal.component.html",
  styleUrls: ["./update-resturant-modal.component.scss"]
})
export class UpdateResturantModalComponent implements OnInit {
  public listResturantTypes: ResturantTypeArrayModel = new ResturantTypeArrayModel();

  public setPopupStatus = true;
  public lang = this.globals.langSaveProperty;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<UpdateResturantModalComponent>,
    private masterService: MasterService,
    public globals: Globals,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.listResturantTypes = this.globalService.jsonConvert.deserializeObject(
      this.data,
      ResturantTypeArrayModel
    );
  }

  ngOnDestroy() {}

  fetchResturantTypes(langSave) {
    this.masterService
      .fetchResturantTypes({ LangSave: langSave })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            let responseObject = { ResturantTypeLists: response.Data };
            this.listResturantTypes = this.globalService.jsonConvert.deserializeObject(
              responseObject,
              ResturantTypeArrayModel
            );
          } else {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          // this.globalService.handleApiError(error);
        }
      );
  }

  switchLanguage(event) {
    this.fetchResturantTypes(event);
    this.lang = event;
  }

  closeModal() {
    this.dialogRef.close();
  }

  edit(index, id) {
    this.masterService
      .showSingleResturantType({
        LangSave: "en",
        ResturantTypeId: id
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            let dialogRef = this.dialog.open(
              AddNewResturantTypeModalComponent,
              {
                width: "440px",
                height: "auto",
                panelClass: "add-image",
                disableClose: true,
                data: response.Data
              }
            );

            const sub = dialogRef.componentInstance.onAdd.subscribe(
              (data: any) => {
                //this.roomTypes.push({'id':5,'value':data});
                // this.updateSingleAmenitie(data);

                // 
                // 
                this.listResturantTypes.ResturantTypeLists[index].value =
                  data.value;

                this.sharedService.updateChangeEvent.emit({
                  id: id,
                  value: data.value
                });
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              sub.unsubscribe();
            });
          } else {
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  delete(index, id) {
    // this.listRoomtypes.RoomTypeLists.splice(index, 1);

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass ='delete-dialog';
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // 
      // 

      if (result) {
        this.masterService
          .deleteResturantType({
            ResturantTypeId: id
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                if (response.Data) {
                  this.globalService.showLoader = false;
                  //this.listRoomtypes.splice(i, 1);
                  // this.listRoomtypes.RoomTypeLists.splice(index, 1);
                  this.fetchResturantTypes(this.lang);
                  //Update the Parent Manage Room List
                  this.sharedService.deleteChangeEvent.emit(id);
                }
              } else {
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }
    });
  }
}
