import { JsonObject, JsonProperty } from 'json2typescript';
import { NullableString, IntConverter } from '../essentials';

@JsonObject("SignUpModel")
export class SignUpModel {
    @JsonProperty("Email", NullableString, true)
    Email: number = undefined;

    @JsonProperty("UserId", IntConverter, true)
    UserId: number = undefined;

    @JsonProperty("Password", NullableString, true)
    Password: number = undefined;
}