<!-- banner -->
<div *ngIf="isLoaded">
<section id="hero-banner" [style.backgroundImage]="'url('+ hotelBannerModel.BannerImage +')'" *ngIf="hotelBannerModel.BannerImage;else other_BannerImage">
    <h4 class="banner-text" *ngIf="hotelBannerLangModel.BannerTagline;else other_BannerTagline">{{ hotelBannerLangModel.BannerTagline }}</h4>
    <!-- <ng-template #other_BannerTagline>
        <h4 class="banner-text">{{'LanguageMissing.Notagline'|translate}}</h4>
    </ng-template> -->
    <h1 class="banner-text" *ngIf="hotelBannerLangModel.BannerTitle;else other_BannerTitle">{{ hotelBannerLangModel.BannerTitle }}</h1>
    <!-- <ng-template #other_BannerTitle>
        <h1 class="banner-text">{{'LanguageMissing.Notagline'|translate}}</h1>
    </ng-template> -->

    <a [attr.href]="hotelBannerModel.BannerUrl"><button class="btn" [ngClass]="hotelBannerModel.BannerButtonType" *ngIf="hotelBannerLangModel.BannerButtonText;else other_BannerButtonText">{{hotelBannerLangModel.BannerButtonText}}</button></a>
    <!-- <ng-template #other_BannerButtonText>
        <a href="#"><button class="btn primary-btn">{{'LanguageMissing.Nobannertext'|translate}}</button></a>
    </ng-template> -->
</section>
<!-- //banner -->

<ng-template #other_BannerImage>
    <section id="hero-banner">
        <h4 class="banner-text" *ngIf="hotelBannerLangModel.BannerTagline;else other_BannerTagline">{{ hotelBannerLangModel.BannerTagline }}</h4>
        <ng-template #other_BannerTagline>
            <h4 class="banner-text">{{'LanguageMissing.Notagline'|translate}}</h4>
        </ng-template>
        <h1 class="banner-text" *ngIf="hotelBannerLangModel.BannerTitle;else other_BannerTitle">{{ hotelBannerLangModel.BannerTitle }}</h1>
        <ng-template #other_BannerTitle>
            <h1 class="banner-text">{{'LanguageMissing.Notagline'|translate}}</h1>
        </ng-template>

        <a [attr.href]="hotelBannerModel.BannerUrl"><button class="btn primary-btn" *ngIf="hotelBannerLangModel.BannerButtonText;else other_BannerButtonText">{{hotelBannerLangModel.BannerButtonText}}</button></a>
        <ng-template #other_BannerButtonText>
            <a href="#"><button class="btn primary-btn">{{'LanguageMissing.Nobannertext'|translate}}</button></a>
        </ng-template>
    </section>
</ng-template>
</div>
