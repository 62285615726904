import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignupModalComponent } from '../../modals/signup-modal/signup-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private dialog: MatDialog) { }
  title2 = "City Hotel";
  ngOnInit() {
  }

  openSignUpModal(): void {
    this.dialog.open(SignupModalComponent, {
      width: '440px',
      height: 'auto',
      panelClass: 'login-modalbox',
      disableClose: true
    });
  }
}
