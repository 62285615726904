import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MasterSuperComponent } from './components/master-super/master-super.component';

const routes: Routes = [{
  path: "",
  component: MasterSuperComponent,
  children: [
    { path: "", component: DashboardComponent },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuperAdminRoutingModule { }
