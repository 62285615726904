import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { CheckedInService } from 'src/app/users/services/checked-in.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pending-bills-payment-success',
  templateUrl: './pending-bills-payment-success.component.html',
  styleUrls: ['./pending-bills-payment-success.component.scss'],
})
export class PendingBillsPaymentSuccessComponent implements OnInit {
  parameters;
  public success = true;
  public transactionDate = '';
  public Items: any;
  constructor(
    private globalService: GlobalService,
    private router: Router,
    private checkedInService: CheckedInService
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const date = today.getDate();
    const year = today.getFullYear();
    const month = today.toLocaleString('default', { month: 'short' });
    const time = today.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    this.transactionDate = `${date} ${month}, ${year} ${time}`;

    const storedItems = localStorage.getItem('Items');

    if (storedItems) {
      // Parse the stored JSON string back into an object
      this.Items = JSON.parse(storedItems);
      // Now you can access the Items object
    }
    const currentUrl = window.location.href;
    this.parameters = new URL(currentUrl).searchParams;
    const amount = this.parameters.get('amount');
    const currency = this.parameters.get('currency');
    const HotelId = this.parameters.get('HotelId');
    const payingMethod = this.parameters.get('paymentBy');
    const failed = this.parameters.get('failed');

    const paymentId = this.parameters.get('paymentId');
    const token = this.parameters.get('token');
    const PayerID = this.parameters.get('PayerID');
    const Items = this.Items;
    if (payingMethod == 'stripe') {
      this.stripePaymentGetDetails(currentUrl);
    } else if (failed) {
      this.paymentFailed();
    } else {
      this.pendingPaypalCheckout(currentUrl);
    }
  }
  paymentFailed() {
    this.success = false;
    this.transactionDate = '----';
    // this.orderId = '----';
    // this.tableNumber = '----'
  }
  stripePaymentGetDetails(url) {
    const currentUrl = window.location.href;
    this.parameters = new URL(currentUrl).searchParams;
    const amount = this.parameters.get('amount');
    const currency = this.parameters.get('currency');
    const HotelId = this.parameters.get('HotelId');
    const payingMethod = this.parameters.get('paymentBy');
    const failed = this.parameters.get('failed');

    const paymentId = this.parameters.get('paymentId');
    const token = this.parameters.get('token');
    const PayerID = this.parameters.get('PayerID');
    const Items = this.Items;
    if (payingMethod != 'stripe') {
      this.pendingPaypalCheckout(url);
    }

    // const data = {

    // Id:UserId,
    // LangKey:this.globalService.gethotelLangTranslate()
    // }
  }

 pendingPaypalCheckout(url) {
    const currentUrl = window.location.href;
    this.parameters = new URL(currentUrl).searchParams;
    const amount = this.parameters.get('amount');
    const currency = this.parameters.get('currency');
    const HotelId = this.parameters.get('HotelId');
    const payingMethod = this.parameters.get('paymentBy');
    const failed = this.parameters.get('failed');

    const paymentId = this.parameters.get('paymentId');
    const token = this.parameters.get('token');
    const PayerID = this.parameters.get('PayerID');
    const Items = this.Items;
    if (payingMethod == 'stripe') {
      this.stripePaymentGetDetails(url);
    }
    const data = {
      amount: amount,
      currency: currency,
      HotelId: HotelId,
      Items: Items,
      token: token,
      paymentId: paymentId,
      payerId: PayerID,
    };
    this.checkedInService
      .PendingPaypalCheckout(data)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        (response) => {
         
          // this.bookingId = response.Data.bookingId;
          this.router.navigate(['/guest/pending-bill-payment/success']);
          this.globalService.showLoader = false;
          // this.continue = true;
        },
        (error) => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }
  GoToServicesPage(){
    this.router.navigate(['hotel-services'])
  }
  ngOnDestroy() {}
}
