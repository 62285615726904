<a href="javascript:void(0);" [matMenuTriggerFor]="lang">
    <span *ngIf="!popupStatus;else other_lang">{{ globals.langSaveValue }}</span>
    <ng-template #other_lang><span>{{ langDisplayPopup }}</span></ng-template>
    <i class="material-icons float-right">arrow_drop_down</i>
</a>


<mat-menu #lang="matMenu" class="menu-dropdown lang-drop-menu">
    <span *ngFor="let language of globals.languageList">
        <span *ngIf="hotelLanguages && hotelLanguages.indexOf(language.key) != -1 ? true : false">
              <button mat-menu-item (click)="switchLanguage({'Property':language.key,'Value':language.value})">{{ language.value }}</button>
        </span>
    </span>
</mat-menu>


<!-- 
<ng-template #otherDefaultList>
  <mat-menu #lang="matMenu" class="menu-dropdown lang-drop-menu">
    <button mat-menu-item (click)="switchLanguage({'Property':'en','Value':'English'})">English</button>
   
  </mat-menu>
</ng-template> -->