import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter,BooleanConverter,NullableString } from 'src/app/core/models/essentials';



@JsonObject("EventModel")
export class EventModel {
    @JsonProperty("Status", Boolean, true) 
    Status: boolean = undefined;
    @JsonProperty("Data", Object, true) 
    Data: Object = undefined;
}
