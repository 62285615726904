<div class="manage-restaurant-guest-wrapper custom-scrollbar">
    <div class="restaurant-guest-header">
        <div class="restaurant-guest-detail-wrapper d-flex">
            <div class="restaurant-guest-detail d-flex">
                <div class="d-flex">
                    <h2 class="guest-name">{{this.orderDetails?.guestName}}</h2>
                <mat-chip class="guest-chip nunito font-12">Restaurant Guest</mat-chip>
                </div>
                
                <span class="nunito guest-phone-number">Phone No. : <strong>{{this.orderDetails?.PhoneNumber}}</strong></span>
            </div>
            <svg (click)='onOrderToggleClick()' class="restro-cancel pointer" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.3125 2.5625L11.875 10L19.3125 17.4375L17.4375 19.3125L10 11.875L2.5625 19.3125L0.6875 17.4375L8.125 10L0.6875 2.5625L2.5625 0.6875L10 8.125L17.4375 0.6875L19.3125 2.5625Z"
                    fill="black" />
            </svg>


        </div>

        <div class="restuarant-assignee-details-wrapper d-flex flex-wrap">
            <p class="asigned-detail nunito">Assisted Staff :  
                <ng-container *ngFor="let staff of orderDetails?.AssignedStaffNames; let last = last">
                    <strong>{{ staff? staff :'Not Assigned' }}{{ !last ? ', ' : '' }}</strong>
                </ng-container>
            
            </p>
            <p class="asigned-detail nunito">Order id : <strong>{{this.orderDetails?.orderId}}</strong></p>
            <p class="asigned-detail nunito">Order no. : <strong>{{this.orderDetails?.orderNo}}</strong></p>
            <p class="asigned-detail nunito">{{this.orderDetails?.tableNumber?'Table no.':'Room no.'}} : <strong>{{this.orderDetails?.tableNumber?this.orderDetails?.tableNumber:this.orderDetails?.roomNumber}}</strong></p>
        </div>

    </div>

    <div class="restaurant-guest-history-wrapper">
        <mat-tab-group class="guest-order-history-wrapper">

            <mat-tab label="Current Order" class="current-order-tab nunito">
                <div class="inventory-table-wrapper">
                    <div class="inventory-table">

                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef>Item Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.Itemname}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef class="d-flex align-items-center"> Price<span class="secondary-text ml-5"
                                    [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span> </th>
                                <td mat-cell *matCellDef="let element"> {{element.Price}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="weight">
                                <th mat-header-cell *matHeaderCellDef> Quantity </th>
                                <td mat-cell *matCellDef="let element"> {{element.Quantity}} </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="symbol">
                                <th mat-header-cell *matHeaderCellDef class="d-flex align-items-center"> Amount <span class="secondary-text ml-5"
                                    [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span> </th>
                                <td mat-cell *matCellDef="let element"> {{element.Amount}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>


                    </div>
                </div>

                <div class="billing-amount d-flex mt-24 nunito">
                    <div class="subtotal d-flex align-items-center justify-content-between nunito font-16 fw-500">
                        <span>Subtotal</span>
                        <span class="d-flex align-items-center"><span class="secondary-text ml-5"
                            [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>{{(this.orderDetails?.TotalPrice)?.toFixed(2)}}</span>
                    </div>
                    <div class="subtotal d-flex align-items-center justify-content-between nunito font-16 fw-500">
                        <span>Tax</span>
                        <span class="d-flex align-items-center"><span class="secondary-text ml-5"
                            [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>00</span>
                    </div>
                    <div class="subtotal d-flex align-items-center justify-content-between nunito font-16 fw-500">
                        <span>Tip</span>
                        <span class="d-flex align-items-center"><span class="secondary-text ml-5"
                            [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>{{(this.orderDetails?.tip)?.toFixed(2)}}</span>
                    </div>
                    <div class="subtotal d-flex align-items-center justify-content-between nunito font-20 fw-700">
                        <strong>Total Amount</strong>
                        <span class="d-flex align-items-center"> <span class="secondary-text ml-5"
                            [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>{{(this.orderDetails?.TotalPrice+this.orderDetails?.tip)?.toFixed(2)}}</span>
                    </div>
                </div>

                <div class="d-flex printing-button">
                    <p class="nunito font-16">Additional request :<strong>{{this.orderDetails?.Message?this.orderDetails?.Message:"NA"}}</strong></p>

                    <button class="print-button pointer" (click)="printOrder(this.orderDetails._id,true)">Print</button>
                </div>
            </mat-tab>


            <mat-tab label="Order History" (click)="OrderHistory()">


                <div class="previous-tab-header-wrapper">
                    <div class="d-flex align-items-center justify-content-between">
                        <span class="tab-text nunito">Last 5 Orders</span>
                        <app-calender-filter class="restaurant-calender" (cancelClicked)="hideCalendarFilter()" (dateSelected)="selectdDateFilter($event)"></app-calender-filter>
                    </div>

                </div>

                <div class="restro-guest-assignee-wrapper"*ngFor="let order of RestaurantOrder ; let i = index">
                    <div class="restro-guest-assignee">
                        <div class="restro-guest">
                            <strong class="nunito guest-assignee-label">Date</strong>
                            <span class="nunito guest-assignee-text">{{ order.CreatedAt| date:'dd MMM, hh:mm a' }}</span>
                        </div>
                        <div class="restro-guest">
                            <strong class="nunito guest-assignee-label">Restaurant Name</strong>
                            <span class="nunito guest-assignee-text">{{ order.ResturantName?order.ResturantName[0].Value:'' }}</span>
                        </div>
                        <div class="restro-guest">
                            <strong class="nunito guest-assignee-label">Payment Status</strong>
                            <span ngClass="{{order.PaymentStatus === 1 ?'order-status-guest d-flex align-items-center mtop-5 nunito font-14':'order-status-guest-status d-flex align-items-center mtop-5 nunito font-14 '}}">{{ order.PaymentStatus === 1 ? 'Paid' : 'Unpaid' }}</span>
                        </div>
                        <div class="restro-guest">
                            <strong class="nunito guest-assignee-label">Assigned Staff</strong>
                            <ng-container *ngFor="let staff of order.AssignedStaffNames; let last = last">
                                <span class="mtop-5 nunito font-14">{{ staff? staff :'Not Assigned' }}{{ !last ? ', ' : '' }}</span>
                            </ng-container>
                        </div>
                    </div>
                    
                    <mat-accordion class="restro-guest-accordion">
                        <mat-expansion-panel class="restro-guest-expansion" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header class="restro-guest-expansion-header">
                                <mat-panel-title class="restro-guest-expansion-title">
                                    <span class="nunito view-mode">View {{ panelOpenState ? 'less' : 'more' }} </span>
                                  </mat-panel-title>
                                <mat-panel-description>
                                    <div class="restro-guest">
                                        <strong class="nunito guest-assignee-label">Order ID</strong>
                                        <span class="nunito guest-assignee-text">{{order.orderId}}</span>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <span class="guest-restro-label nunito">Order Details</span>

                            <div class="inventory-table-wrapper">
                               
                                    <div class="inventory-table ">
                                        <div class="example-container mat-elevation-z8 custom-scrollbar">
                                            <table mat-table [dataSource]="dataSource2">
                                              <!-- Item Column -->
                                              <ng-container matColumnDef="item">
                                                <td mat-cell *matCellDef="let transaction"> {{transaction.item}} </td>
                                                <td mat-footer-cell *matFooterCellDef> Total  </td>
                                              </ng-container>
                                          
                                              <!-- Cost Column -->
                                              <ng-container matColumnDef="cost">
                                                <td mat-cell *matCellDef="let transaction"> <span [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span> {{transaction.price}} </td>
                                                <td mat-footer-cell *matFooterCellDef class="table-footer">  <span [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>{{(order?.TotalPrice+order?.tip)?.toFixed(2)}}</td>
                                              </ng-container>
                                          
                                              <tr mat-row *matRowDef="let row; columns: displayedColumnss;"></tr>
                                              <tr mat-footer-row class="nunito" *matFooterRowDef="displayedColumnss; sticky: true"></tr>
                                            </table>
                                        </div>
                                      </div>
                                      
                                
                                
                                  
                                  
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>




            </mat-tab>
        </mat-tab-group>

    </div>
</div>