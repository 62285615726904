import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';
import { MasterService } from '../../services/master.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-inventory-request-decline-popup',
  templateUrl: './inventory-request-decline-popup.component.html',
  styleUrls: ['./inventory-request-decline-popup.component.scss']
})
export class InventoryRequestDeclinePopupComponent implements OnInit {
  public reasonForm: UntypedFormGroup;
 public  location;
 public order;

  @Output() declineReasonNote: EventEmitter<{ reason: string, note: string }> = new EventEmitter();
  // @Input() element: any;

  constructor( private formBuilder: UntypedFormBuilder,
    public globalService: GlobalService,
    private masterService: MasterService,
    public dialogRef: MatDialogRef<InventoryRequestDeclinePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  
  ) { }

  ngOnInit(): void {
     this.location=this.data.location;
     if(this.location == 'manageOrder'){
      this.order = this.data.order;
     }
    
    this.reasonForm = this.formBuilder.group({
      declinedReason: ["Restocking is not necessary", Validators.required],
      note: [null]
    });

   
  }

  
  onSubmit() {
    if(this.location =='inventoryRequests'){
    const declinedReason = this.reasonForm.get('declinedReason').value;
    const note = this.reasonForm.get('note').value;
    this.declineReasonNote.emit({ reason: declinedReason, note: note });
    this.dialogRef.close();
    if (declinedReason === '' && note.trim() !== '') {
      
      
    } else {
      
      if (this.data && this.data.onDeclineSubmit) {
        this.data.onDeclineSubmit(); // Invoke the callback function
      }

      // if (this.data && this.data.inventoryRequestApproval) {
      //   this.data.inventoryRequestApproval(this.element,2);
      // }
    }
  }
  else if(this.location =='manageOrder'){
    const declinedReason = this.reasonForm.get('declinedReason').value;
    const note = this.reasonForm.get('note').value;
    
    const data = {
      id: this.order._id,
      Status: 5,
      estimatedDeliveryTime: false,
      assignedStaffId: false,
      acceptedTime: false,
      cancelReason: declinedReason === 'other'?note:declinedReason,
    };
    this.globalService.showLoader = true;
    this.masterService
    .updateOrderStatus(data)
    .pipe(takeUntil(this.globalService.componentDestroyed(this)))
    .subscribe(
      response => {
        this.globalService.showLoader = false;
        if(response.Status){
          
          this.declineReasonNote.emit({ reason: declinedReason, note: note });
          this.dialogRef.close({
            orderCancled: response.Status,
          });
        }else{
          this.globalService.showLoader = false;
          this.globalService.handleApiError(response);
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
    
    // this.dialogRef.close();
    // if (declinedReason === 'other' && note.trim() !== '') {
    //   
    //   
    // } else {
    //   
    //   if (this.data && this.data.onDeclineSubmit) {
    //     this.data.onDeclineSubmit(); // Invoke the callback function
    //   }

      // if (this.data && this.data.inventoryRequestApproval) {
      //   this.data.inventoryRequestApproval(this.element,2);
      // }
    // }

  }
  }

  closePopup(){
    this.dialogRef.close();
    this.data.onPopupCanceled();
  }

  ngOnDestroy(){

  }
 
}
