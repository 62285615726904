import { Component, OnInit } from '@angular/core';
import { MatDialog,  MatDialogRef } from '@angular/material/dialog';
import { SigninModalComponent } from '../signin-modal/signin-modal.component';
import { SignUpModel } from '../../models/request/signup.model';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {

  public signUpModel: SignUpModel = new SignUpModel();
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SignupModalComponent>
    ) { }

  ngOnInit() {
  }

  openSignInModal(): void {
    this.closeModal();
    this.dialog.open(SigninModalComponent, {
      width: '440px',
      height: 'auto',
      panelClass: 'login-modalbox',
      disableClose: true
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
