import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-cancel-page',
  templateUrl: './payment-cancel-page.component.html',
  styleUrls: ['./payment-cancel-page.component.scss']
})
export class PaymentCancelPageComponent implements OnInit {

  constructor(
    private router: Router
  ) { }
  public logo ="";

  billing(){
    this.router.navigate(['/guest/billing-history']);
  }

  ngOnInit() {
 this.getlogo();
  }
  
  getlogo(){
    if (window.localStorage["Logo"]){
  this.logo = window.localStorage.getItem('Logo');
    }
  }
}
