import { Component, OnInit, Inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "src/app/shared.service";
import { GlobalService } from "../../services/global.service";
import { takeUntil } from "rxjs/operators";
import { MasterService } from "../../services/master.service";
import {
  HotelGuideListsArray,
  HotelGuideRequestLang
} from "../../models/common-request-response";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatDialogConfig
} from "@angular/material/dialog";
import { AddNewHotelGuideComponent } from "../../modals/add-new-hotel-guide/add-new-hotel-guide.component";

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ConfirmDialogModalComponent } from "../../modals/confirm-dialog-modal/confirm-dialog-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { PdfUploadModalComponent } from "../../modals/pdf-upload-modal/pdf-upload-modal.component";
import { AmazonWebService } from "../../services/amazon-web.service";

@Component({
  selector: "app-hotel-guide",
  templateUrl: "./hotel-guide.component.html",
  styleUrls: ["./hotel-guide.component.scss"]
})
export class HotelGuideComponent implements OnInit {
  deletePdfSubscribe$;
  deletePdfBroadcast$;
  deletSubscribe$;
  @Inject(MAT_DIALOG_DATA) public data: any;
  private dialogRef: MatDialogRef<AddNewHotelGuideComponent>;
  public editIndex: number = 0;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,
    public globalService: GlobalService,
    private masterService: MasterService,
    private translate: TranslateService,
    private amazonWebService: AmazonWebService
  ) {}

  headingTitle = "Hotel Guide";
  headerLangChangeEvent$;

  @Input() hotelGuideListsArray: HotelGuideListsArray;
  @Input() hotelGuideLocationStatus: string;
  @Input() halfLength: number;
  ngOnInit() {
    if (this.hotelGuideListsArray) {
      if (this.halfLength) {
      }
    }

    //Delete Pdf Subscribe---------------------------------
    this.deletePdfSubscribe$ = this.sharedService.deletePdfEvent.subscribe(
      data => {
      }
    );

    //Subscribe to broadcast Pdf Delete message

    this.deletePdfBroadcast$ = this.sharedService.deletePdfBroadcast.subscribe(
      data => {
               let hotelGuideLists = this.hotelGuideListsArray.HotelGuideLists;
        let findIndex = hotelGuideLists.map(item => item._id).findIndex(data);

        if (findIndex) {
          this.hotelGuideListsArray.HotelGuideLists[findIndex].Pdf = "";
          this.hotelGuideListsArray.HotelGuideLists[findIndex].FileName = "";
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.deletePdfSubscribe$) {
      this.deletePdfSubscribe$.unsubscribe();
    }

    if (this.deletePdfBroadcast$) {
      this.deletePdfBroadcast$.unsubscribe();
    }
  }

  ngAfterViewChecked() {
     }

  editHotelGuide(i, id) {
    this.editIndex = i;
    this.masterService
      .showSingleHotelGuide({
        LangSave: this.globalService.getLangSaveProperty(),
        HotelGuideId: id
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.globalService.emitPermissions(response.AdminPermissions);
                       if (response.Data) {
              let hotelGuideRequestLang = this.globalService.jsonConvert.deserialize(
                response.Data,
                HotelGuideRequestLang
              );
              let dialogRef = this.dialog.open(AddNewHotelGuideComponent, {
                width: "440px",
                height: "auto",
                panelClass: "add-image",
                disableClose: true,
                data: {
                  guildeLists: hotelGuideRequestLang,
                  editStatus: true
                }
              });

              const sub = dialogRef.componentInstance.onUpdate.subscribe(
                (data: any) => {
                  this.editResponse(data);
                }
              );

              dialogRef.afterClosed().subscribe(() => {
                sub.unsubscribe();
              });
            }
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  editResponse(data) {
   
    // let singleGuideList = this.hotelGuideListsArray.HotelGuideLists[
    //   this.editIndex
    // ];
       if (data.value.Pdf == "delete") {
      if (this.hotelGuideListsArray.HotelGuideLists[this.editIndex].Pdf) {
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].Pdf = "";
      }

      if (this.hotelGuideListsArray.HotelGuideLists[this.editIndex].FileName) {
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].FileName = "";
      }
    } else {
      if (
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].TitleGuide &&
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].TitleGuide
          .length
      ) {
        this.hotelGuideListsArray.HotelGuideLists[
          this.editIndex
        ].TitleGuide[0].Value = data.value.TitleGuide;
      }

      if (
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex]
          .HotelGuideDescription &&
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex]
          .HotelGuideDescription.length
      ) {
        this.hotelGuideListsArray.HotelGuideLists[
          this.editIndex
        ].HotelGuideDescription[0].Value = data.value.HotelGuideDescription;
      }

      if (data.value.Pdf) {
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].Pdf =
          data.value.Pdf;
      }
      if (data.value.FileName) {
        this.hotelGuideListsArray.HotelGuideLists[this.editIndex].FileName =
          data.value.FileName;
      }
    }
  }

  deleteHotelGuide(i, id, pdf) {
    this.editIndex = i;

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "delete-dialog";
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
                if (result) {
        if (pdf) {
          let splitImgUrl = pdf.split("/");
          let fetchImage = splitImgUrl[splitImgUrl.length - 1];
          let imgType = fetchImage.split(".").pop();
          //Delete Api Request With Aws Signed Url
          this.amazonWebService.deleteObject(
            fetchImage,
            imgType,
            "private",
            "pdfUpload"
          );

          // Subscribe To Delete event after Success Delete Image From Bucket
          this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
            response => {
              if (response.data.Filename) {
                this.globalService.showLoader = false;
                this.sharedService.deletePdfEvent.emit(pdf);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
              return false;
            }
          );
        }

        this.masterService
          .deleteHotelGuide({
            HotelGuideId: id
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.globalService.showLoader = false;
                this.globalService.emitPermissions(response.AdminPermissions);
                               if (response.Data) {
                  this.hotelGuideListsArray.HotelGuideLists.splice(i, 1);
                }
              } else {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (
      this.hotelGuideListsArray.HotelGuideLists &&
      this.hotelGuideListsArray.HotelGuideLists.length
    ) {
      moveItemInArray(
        this.hotelGuideListsArray.HotelGuideLists,
        event.previousIndex,
        event.currentIndex
      );

      var guideListOrder = [];

      let itemArray = this.hotelGuideListsArray.HotelGuideLists.map(
        (item, index) => ({
          id: item._id,
          order: index
        })
      );

      if (itemArray.length) {
        this.globalService.showLoader = true;
        this.masterService
          .rearrangeGuides({ Data: itemArray })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.globalService.showLoader = false;
                this.globalService.emitPermissions(response.AdminPermissions);
              } else {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }
    }
  }

  openPdf(url) {
    let dialogRef = this.dialog.open(PdfUploadModalComponent, {
      width: "100%",
      height: "100vh",
      panelClass: "pdf_iframe_dialog",
      disableClose: true,
      data: {
        Url: url
      }
    });

    // const sub = dialogRef.componentInstance.onUpdate.subscribe(
    //   (data: any) => {
    //     this.editResponse(data);
    //   }
    // );

    dialogRef.afterClosed().subscribe(() => {});
  }
}
