import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Inject, OnInit, ViewChild, inject} from '@angular/core';
import {UntypedFormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteSelectedEvent,MatAutocompleteModule, MatAutocomplete,  MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global.service';
import { MasterService } from '../../services/master.service';
import { HotelAuthService } from 'src/app/hotel-admin/services/hotel-auth.service';
import _moment from "moment";
const moment = _moment;

@Component({
  selector: 'app-assigned-restaurant-staff',
  templateUrl: './assigned-restaurant-staff.component.html',
  styleUrls: ['./assigned-restaurant-staff.component.scss']
})
export class AssignedRestaurantStaffComponent implements OnInit {
  logedInUser;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public AssignSelfId: []=[];
  AssignSelfFirstName;
  AssignSelfLastName;
  AssignedStaffId :any[]=[];
  public staffList :any[]=[];
  public order;
  showOptions: boolean = true; 
  selectedStatusControl: UntypedFormControl = new UntypedFormControl('');
  StaffCtrl = new UntypedFormControl();
  public staffSearch: any[] = []; // An array to hold selected staff members
  public staffCtrl = new UntypedFormControl();
  public selectedStaff: any[] = [];

  public AssignSelfSelected: boolean = false;
  @ViewChild('staffInput') staffInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoStaff', { read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
  // @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
   @ViewChild('auto') matAutocomplete: MatAutocomplete;
  filteredStaff: Observable<string[]>;
  cheffList:any[]=[];
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public globalService: GlobalService,
    private hotelAuthService: HotelAuthService,
    private masterService: MasterService,
    public dialogRef: MatDialogRef<AssignedRestaurantStaffComponent>
    ) {
        this.filteredStaff = this.staffCtrl.valueChanges.pipe(
          startWith(''),
          map((staff: string | null) =>
            staff ? this._filter(staff) : this.staffList.slice()
          )
        );

  }

 

  ngOnInit(): void {
    this.staffList = this.data.staffList
    // 
    if(this.data.order){
      this.order = this.data.order;
      if(this.order.AssignedStaffId.length > 0){
        for(let i=0; this.order.AssignedStaffId.length > i; i++){
          const staffDetail = {
            id: this.order.AssignedStaffId[i],
            staffName: this.order.AssignedStaffNames[i]            
          }
          this.selectedStaff.push(staffDetail);
        }
      }
    }
    
    // for(let staff of this.cheffList ){
      
    //      this.staffList.push(staff.StaffName)
    // }
    // 
    this.getHotelProfile();
  
  }
  

  
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add the selected staff member
    // 
    if ((value || '').trim()) {
      const selectedStaff = this.staffList.find(
        (staff) => staff.StaffName === value.trim()
      );
      
      if (selectedStaff) {
        this.selectedStaff.push(selectedStaff);
        this.StaffCtrl.setValue(this.selectedStaff);
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.StaffCtrl.setValue(null);
  }


  ApplycloseDialog() {
    let StaffId = []
    for(let data of this.selectedStaff){
      StaffId.push(data.id);
    }
    
    const data = {
      id: this.order._id,
      Status: this.order.Status==0?1:false,
      estimatedDeliveryTime: false,
      assignedStaffId: StaffId,
      acceptedTime: this.order.Status==0?moment.utc(new Date()).format():false,
      cancelReason: false,
    };
    this.globalService.showLoader = true;
    this.masterService
    .updateOrderStatus(data)
    .pipe(takeUntil(this.globalService.componentDestroyed(this)))
    .subscribe(
      response => {
        this.globalService.showLoader = false;
        if(response.Status){
          // 
          this.dialogClose(response.Status);
          this.selectedStaff = [];
          this.AssignedStaffId = [];
        }else{
          this.globalService.showLoader = false;
          this.globalService.handleApiError(response);
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    // 
    // 
    
    const selectedStaffId = event.option.value._id;
    const selectedStaff = this.staffList.find((staff)=> staff._id===selectedStaffId);
    // 
    
    // if (!this.staffSearch.includes(selectedStaff)) {
    //   this.staffSearch.push(selectedStaff);
    // }
    if (selectedStaff && !selectedStaff.selected) {
      this.selectedStaff.push({
        staffName: selectedStaff.StaffName,
        id: selectedStaff._id,
      });
     this.AssignedStaffId.push(selectedStaff._id);
       selectedStaff.selected = true; // Mark the staff as selected
   }

    // Reset the input value
    this.staffInput.nativeElement.value = '';
     this.StaffCtrl.setValue(null);

    // Focus out from the input
    this.staffInput.nativeElement.blur();
  }

  remove(staff: any): void {
    const index = this.selectedStaff.indexOf(staff);
    if (index >= 0) {
      this.selectedStaff.splice(index, 1);
      const selectedStaffs =this.staffList.find((staffs)=> staffs._id ===staff.id);
      // 
      if(selectedStaffs){
      selectedStaffs.selected =false;
      }
      const idIndex = this.AssignedStaffId.indexOf(staff._id);
      if (idIndex >= 0) {
        this.AssignedStaffId.splice(idIndex, 1);
      }
      const staffIndex = this.staffSearch.indexOf(staff.StaffName);
    if (staffIndex >= 0) {
      this.staffSearch.splice(staffIndex, 1);
    }
    }
   
  }
  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.staffList.filter(staff => staff.StaffName.toLowerCase().includes(filterValue));
  }

  filterStaff(value: string) {
    
    this.filteredStaff = this.staffCtrl.valueChanges.pipe(
      startWith(value),
      map((staff: string | null) => staff ? this._filter(staff) : this.staffList.slice())
    );
  }

 
assignSelf() {
  const currentUser = {
    staffName: this.logedInUser.FirstName + ' ' + this.logedInUser.LastName,
    id: this.logedInUser._id
  };
  // 
  
  // Check if the user is already selected
  const selfIndex = this.selectedStaff.findIndex((staff) => staff.id === this.logedInUser._id);

  if (selfIndex === -1) {
    // "Assign Self" is not in the list, so add it
    
    this.selectedStaff.push(currentUser);
    this.AssignedStaffId.push(currentUser.id);
    // 
  }
  this.AssignSelfSelected = true; // Mark "Assign Self" as selected
}

getHotelProfile() {
  this.globalService.showLoader = true;
  this.hotelAuthService.gethotelProfile({ param: null }).subscribe(
    response => {
      if (response.Status) {
        this.globalService.showLoader = false;
        this.logedInUser = response.Data;
        
        this.AssignSelfId = this.logedInUser._id;
        this.AssignSelfFirstName = this.logedInUser.FirstName
        this.AssignSelfLastName = this.logedInUser.LastName
      } else {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(response);
      }
    },
    error => {
      this.globalService.showLoader = false;
      this.globalService.handleApiError(error);
    }
  );
}
 
clearSelectedStaff() {
  this.selectedStaff = [];
  this.AssignedStaffId = [];
  for(let staff of this.staffList){
    staff.selected = false;
  }
}

dialogClose(status){
  for(let staff of this.staffList){
    staff.selected = false;
  }
  this.dialogRef.close({
    selectedStaff: this.selectedStaff,
    assignedStaffId: this.AssignedStaffId,
    StaffAssigned: status,
  });
}
  openAutoComplete() {
    if (this.matAutocompleteTrigger && !this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.openPanel();
    }
  }

  ngOnDestroy(){

  }
}
