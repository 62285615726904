import { Pipe, PipeTransform } from "@angular/core";
import { Globals } from "../globals";

@Pipe({
  name: "timeFormat"
})
export class TimeFormatPipe implements PipeTransform {
  constructor(public globals: Globals) {}
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  transform(item: any): any {
    let timezoneSelect = "";
    let timeFormat = "";
  
    // Get the selected timezone from localStorage or fallback to global timezone
    if (window.localStorage["Timezone"]) {
      timezoneSelect = window.localStorage["Timezone"];
    } else {
      timezoneSelect = this.globals.Timezone;
    }
  
    // Convert the date to the selected timezone
    let date1 = new Date(item).toLocaleString("en-US", {
      timeZone: timezoneSelect
    });
    let date = new Date(date1);
  
    // Get the time format from localStorage or fallback to global time format
    if (window.localStorage["TimeFormat"]) {
      timeFormat = window.localStorage["TimeFormat"];
    } else {
      timeFormat = this.globals.TimeFormat;
    }
  
    // Format time based on the user's preference
    if (timeFormat === "hh:mm tt") {
      // 12-hour format with AM/PM
      return this.formatAMPM(date);
    } else {
      // Default to 24-hour format (hh:mm)
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
  }
  
}
