import { Pipe, PipeTransform } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Pipe({
  name: "fetchJson"
})
export class FetchJsonPipe implements PipeTransform {
  private cachedData: any = null;
  private cachedUrl = "";
  private monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  constructor(private http: HttpClient) {}

  transform(item: any): any {
    let date = new Date(item);

    // if (url !== this.cachedUrl) {
    //   this.cachedData = null;
    //   this.cachedUrl = url;
    //   this.http.get(url).subscribe(result => (this.cachedData = result));
    // }

    return (
      date.getDate() +
      " " +
      this.monthNames[date.getMonth()] +
      "," +
      date.getFullYear()
    );
  }
}
