import { JsonObject, JsonProperty } from "json2typescript";
import {
  IntConverter,
  BooleanConverter,
  NullableString,
  NumberStringConvertor
} from "src/app/core/models/essentials";

@JsonObject("GalleryLangModel")
export class GalleryLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ManageGalleryModel")
export class ManageGalleryModel {
  @JsonProperty("_id", NullableString, false)
  _id: string = undefined;
  @JsonProperty("Image", NullableString, true)
  Image: string = undefined;
  @JsonProperty("ImageTitle", [GalleryLangModel], true)
  ImageTitle: [GalleryLangModel] = undefined;
  @JsonProperty("HomePageDisplay", BooleanConverter, true)
  HomePageDisplay: Boolean = undefined;
  @JsonProperty("Order", NumberStringConvertor, true)
  Order: Number = undefined;
  ImageUpload: File = undefined;
}

@JsonObject("ManageGalleryArrayModel")
export class ManageGalleryArrayModel {
  @JsonProperty("GalleryImages", [ManageGalleryModel], false)
  GalleryImages: [ManageGalleryModel] = undefined;
}
