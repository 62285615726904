import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "../../services/master.service";
import { GlobalService } from "../../services/global.service";
import {
  HotelAboutUsModel,
  HotelAboutUsLangModel
} from "../../models/request/hotelAboutUs.model";
import { Subject } from "rxjs";

@Component({
  selector: "app-about-hotel",
  templateUrl: "./about-hotel.component.html",
  styleUrls: ["./about-hotel.component.scss"]
})
export class AboutHotelComponent implements OnInit, OnDestroy {
  langSubscribe$;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public hotelAboutUsModel: HotelAboutUsModel = new HotelAboutUsModel();
  public hotelAboutUsLangModel: HotelAboutUsLangModel = new HotelAboutUsLangModel();
  innerBannerShow: boolean = false;
  viewAllShow: boolean = true;
  aboutUsDetail: boolean = false;
  aboutUsEdit: boolean = false;
  headingTitle = "About us";
  buttonColor = "secondary-btn";
  constructor(
    private router: Router,
    private masterService: MasterService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    let reqModel = {
      Lang: this.masterService.getuserLangTranslate(),
      Subdomain: this.globalService.getHostname()
    };

    this.fetchAboutUs(reqModel);

    //Subscribe to lang Chnge Event
    /* this.langSubscribe$ = this.masterService.userSwitchLang.subscribe(
      (data: string) => {
        let reqModel =  { 
          "Lang": data,
          "Subdomain":this.globalService.getHostname()
        }
        this.fetchAboutUs(reqModel);
    });*/

    if (this.router.url == "/") {
      this.innerBannerShow = false;
      this.viewAllShow = true;
      this.aboutUsDetail = false;
      this.aboutUsEdit = false;
    }

    if (this.router.url == "/aboutUs") {
      this.innerBannerShow = true;
      this.viewAllShow = false;
      this.aboutUsDetail = true; //show full detail
      this.aboutUsEdit = false;
    }

    if (this.router.url == "/portal/admin/manage/about") {
      this.innerBannerShow = false;
      this.viewAllShow = false;
      this.aboutUsDetail = true; //show full detail
      this.aboutUsEdit = true;
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    /*if(this.langSubscribe$){
      this.masterService.userSwitchLang.unsubscribe();
    }*/
  }

  fetchAboutUs(reqModel) {
    this.globalService.showLoader = true;
    this.masterService.getAboutUs(reqModel).subscribe(
      response => {
        if (response.Status) {
          this.globalService.showLoader = false;
          this.hotelAboutUsModel = this.globalService.jsonConvert.deserializeObject(
            response.Data,
            HotelAboutUsModel
          );

          if (this.hotelAboutUsModel.HotelAboutUsLang.length) {
            this.hotelAboutUsLangModel = this.hotelAboutUsModel.HotelAboutUsLang[0];
            if (this.hotelAboutUsLangModel.AboutUsTitle) {
              this.headingTitle = this.hotelAboutUsLangModel.AboutUsTitle;
            }
          }

          if (this.hotelAboutUsModel.AboutUsButtonType) {
            this.buttonColor = this.hotelAboutUsModel.AboutUsButtonType;
          }
        } else {
          this.globalService.showLoader = false;
          // this.globalService.handleApiError(response);
        }
      },
      error => {
        this.globalService.showLoader = false;
        // this.globalService.handleApiError(error);
      }
    );
  }
}
