import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
/*import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { DashboardNavGuard } from './core/guards/dashboard-nav.guard';
import { TenantNavGuard } from './core/guards/tenant-nav.guard';
import { CompanyNavGuard } from './core/guards/company-nav.guard';
import { ConsumerNavGuard } from './core/guards/consumer-nav.guard';*/
import { PageNotFoundComponent } from "./core/components/page-not-found/page-not-found.component";
import { ForgetPasswordComponent } from "./core/components/forget-password/forget-password.component";
import { ResetPasswordComponent } from "./core/components/reset-password/reset-password.component";
import { ResetAuthCheckComponent } from "./core/components/reset-auth-check/reset-auth-check.component";
import { UiKitComponent } from "./ui-kit/ui-kit.component";
import { ActivatePageComponent } from "./core/components/activate-page/activate-page.component";

const routes: Routes = [
  { path: '', loadChildren: ()=> import('./users/users.module').then(m => m.UsersModule)},
  // { path: "", loadChildren: "./users/users.module#UsersModule" },
  { path: "forget", component: ForgetPasswordComponent },
  { path: "uikit", component: UiKitComponent },
  { path: "reset/:secret", component: ResetAuthCheckComponent },
  { path: "resetPassword/:secret", component: ResetPasswordComponent },
  { path: "activate/:userId", component: ActivatePageComponent },
  {
    path: "portal",
    loadChildren: ()=> import('./hotel-admin/hotel-admin.module').then(m => m.HotelAdminModule) //./hotel-admin/hotel-admin.module#HotelAdminModule
  },
  {
    path: "super-admin",
    loadChildren: ()=> import('./super-admin/super-admin.module').then(m => m.SuperAdminModule) /* "./super-admin/super-admin.module#SuperAdminModule" canLoad: [ConsumerNavGuard] */
  },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
