import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultModel } from 'src/app/core/models/response/base.model';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class CheckedInService {

  constructor(private apiService: ApiService) {}

  checkedIn(reqModel): Observable<ResultModel> {
    const route = "/guest/checkIn";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getBookingDetails(reqModel): Observable<ResultModel> {
    const route = "/guest/booking";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  guestEmailActivation(reqModel): Observable<ResultModel> {
    const route = "/hotel/guestEmailActivation";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  guestEmailResendActivation(reqModel): Observable<ResultModel> {
    const route = "/hotel/guestResendActivation";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addRequestServicesAdmin(reqModel): Observable<ResultModel> {
    const route = '/hotel/request-services-admin';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getPreference(reqModel): Observable<ResultModel> {
    const route = "/getCheckInPreferences";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getUserSelectedPreferences(reqModel): Observable<ResultModel> {
    const route = "/guest/getUserSelectedPreferences";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addCheckInUserSelectedPreference(reqModel): Observable<ResultModel> {
    const route = "/guest/addCheckInUserSelectedPreference";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  addGuestPreference(reqModel): Observable<ResultModel> {
    const route = "/guest/add-guest-preferences";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getPerferencesByType(reqModel): Observable<ResultModel> {
    const route = '/guest/preference';
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  // /hotel/showRestaurantOrderLists
  getRestaurantOrderLists(reqModel): Observable<ResultModel> {
    const route = '/hotel/showRestaurantOrderLists';
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  getGuestRestaurantOrderLists(reqModel): Observable<ResultModel> {
    const route = '/guest/get-order-list';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getCheffStaffLists(reqModel): Observable<ResultModel> {
    const route = '/hotel/showRestaurantChefLists';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  listServices(reqModel): Observable<ResultModel> {
    const route = '/hotel/list-services';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getServiceslist(reqModel): Observable<ResultModel> {
    const route = '/hotel/get-service-list';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  signupGuest(reqModel): Observable<ResultModel> {
    const route = '/signup';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  loginGuest(reqModel): Observable<ResultModel> {
    const route = '/login';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  /**
   * Adds request services
   * @param reqModel
   * @returns request services
   */
  addRequestServices(reqModel): Observable<ResultModel> {
    const route = '/hotel/request-services';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  // addRequestServicesAdmin(reqModel): Observable<ResultModel> {
  //   const route = '/hotel/request-services-admin';
  //   return this.apiService.post<ResultModel>(route, reqModel);
  // }

  getrequestServices(reqModel): Observable<ResultModel> {
    const route = '/hotel/request-services';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  sendMessageRequests(reqModel): Observable<ResultModel> {
    const route = '/check-in/request/message';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getMessageRequests(reqModel): Observable<ResultModel> {
    const route = '/check-in/request/message';
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  
  cancelRequest(reqModel):Observable<ResultModel> {
    const route = '/check-in/request/cancel-service';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getGuestDetail(reqModel): Observable<ResultModel> {
    const route = '/check-in/get-guest-detail';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getLoginGuestDetail(reqModel): Observable<ResultModel> {
    const route = '/log-in/get-login-guest-detail';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getRequest(reqModel): Observable<ResultModel> {
    const route = '/guest/get-request';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getHotelName(reqModel): Observable<ResultModel> {
    const route = '/check-in/get-hotel-name';
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  
  getGuestPreferences(reqModel): Observable<ResultModel> {
    const route = '/check-in/get-guest-preferences';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getBillingInfo(reqModel): Observable<ResultModel> {
    const route = '/check-in/get-billing-info';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  urlBillingInfo(reqModel): Observable<ResultModel> {
    const route = '/hotel/paymentMethod/checkout/executePaypalPayment';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  guestCheckoutPaypal(reqModel): Observable<ResultModel> {
    const route = '/hotel/paymentMethod/guestCheckoutPaypal';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getPaymentConnection(reqModel): Observable<ResultModel> {
    const route = "/hotel/paymentMethod/paymentConnection";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getGuestProfile(reqModel): Observable<ResultModel> {
    const route = '/check-in/get-guest-profile';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  postGuestProfile(reqModel): Observable<ResultModel> {
    const route = '/check-in/post-guest-profile';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  checkedOut(reqModel): Observable<ResultModel> {
    const route = "/guest/checkOut";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  fetchRecommendedService(reqModel): Observable<ResultModel> {
    const route = '/check-in/fetch-recommended-service';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  paymentCheckout(reqModel): Observable<ResultModel> {
    const route = '/hotel/paymentMethod/checkout';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  paymentBillCheckout(reqModel): Observable<ResultModel>{
    const route ='/hotel/paymentMethod/checkout/pendingBillsCheckout';
    return this.apiService.post<ResultModel>(route, reqModel);

  }


  bookingPaymentCheckout(reqModel): Observable<ResultModel> {
    const route = '/hotel/paymentMethod/bookingPaymentCheckout';
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  resturantPaymentCheckout(reqModel) :Observable<ResultModel> {
    const route = '/hotel/paymentMethod/resturantCheckout';
    return this.apiService.post<ResultModel>(route, reqModel);
   }
   PendingPaypalCheckout(reqModel) :Observable<ResultModel>{
    const route = '/hotel/paymentMethod/checkout/paypalPendingBillsCheckout';
    return this.apiService.post<ResultModel>(route, reqModel)
   }

   
   PayAtReception(reqModel):Observable<ResultModel>{
    const route = '/hotel/paymentMethod/checkout/payAtReception';
    return this.apiService.post<ResultModel>(route, reqModel)
   }
   resturantPaypalCheckout(reqModel) :Observable<ResultModel>{
    const route = '/hotel/paymentMethod/checkout/resturantPaypalCheckout';
    return this.apiService.post<ResultModel>(route, reqModel)
   }
  
   servicePaymentCheckout(reqModel) :Observable<ResultModel> {
    const route = '/hotel/paymentMethod/checkout';
    return this.apiService.post<ResultModel>(route, reqModel);
   }
  //  servicePaypalCheckout(reqModel) :Observable<ResultModel>{
  //   const route = '/hotel/paymentMethod/checkout/resturantPaypalCheckout';
  //   return this.apiService.post<ResultModel>(route, reqModel)
  //  }
}
