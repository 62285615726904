<app-loader [ShowLoader]="globalService.showLoader"></app-loader>
<form [formGroup]="addNewRoomtype" class="form-wrapper">
    <div class="modal-header clearfix">

        <h3 *ngIf="insertStatus">{{ 'Addnewtype'|translate}}</h3>
        <h3 *ngIf="updateStatus">{{ 'Updatetype'|translate}}</h3>

        <div class="lang-drop-menu">
            <span>{{ 'EditTextFor'|translate }}:</span>
            <app-language-save [popupStatus]='setPopupStatus' (selectedLang)="switchLanguage($event)"></app-language-save>
        </div>
        <button class="material-icons" mat-dialog-close>close</button>
    </div>
    <div class="modal-body-content">
        <mat-form-field class="col-lg-12 pad0">
            <mat-label>{{'Title'|translate}}</mat-label>
            <input matInput type="text" formControlName="RoomTypeName">
        </mat-form-field>
    </div>
    <div class="modal-footer-content">
        <a (click)="addRoomType(addNewRoomtype)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" [appAccessCheck]="'rooms'" [givenButton]="'add'" *ngIf="insertStatus">{{'Submit'|translate}}</a>
        <a (click)="addRoomType(addNewRoomtype)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" [appAccessCheck]="'rooms'" [givenButton]="'update'" *ngIf="updateStatus">{{'Submit'|translate}}</a>

        <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn" [appAccessCheck]="'rooms'" [givenButton]="'cancel'">
            <i class="material-icons">close</i>{{'Cancel'|translate}}
        </a>
    </div>
</form>