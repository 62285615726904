
<div class="inventory-request-decline-wrapper" >
    <div class="popup-header">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="nunito font-16 fw-600 black mb-0">Quantity</h2>
            <svg class="pointer" width="15" height="15" viewBox="0 0 15 15" fill="none" (click)="closePopup()"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.29934 7.50781L14.3638 13.5811L13.5728 14.3721L7.49953 8.30762L1.42629 14.3721L0.635273 13.5811L6.69973 7.50781L0.635273 1.43457L1.42629 0.643555L7.49953 6.70801L13.5728 0.643555L14.3638 1.43457L8.29934 7.50781Z"
                    fill="black" />
            </svg>
        </div>
    </div>

    <form [formGroup]="reasonForm" (ngSubmit)="onSubmit()" >
        <div class="reason-wrapper" *ngIf="this.location =='inventoryRequests'">
          <span class="reason nunito">Reason</span>
          <mat-select disableRipple placeholder="Select Reason" class="dropdown-declined" formControlName="declinedReason">
            <mat-option value="Restocking is not necessary">Restocking is not necessary</mat-option>
            <mat-option value="Restocking is needed">Restocking is needed</mat-option>
            <mat-option value="">Other</mat-option>
          </mat-select>
        </div>

        <div class="reason-wrapper" *ngIf="this.location =='manageOrder'">
          <span class="reason nunito">Reason</span>
          <mat-select disableRipple placeholder="Select Reason" class="dropdown-declined" formControlName="declinedReason">
            <mat-option value="Requirements have been change">Requirements have been change</mat-option>
            <mat-option value="Huge rush ithe Kitchen">Huge rush in the Kitchen</mat-option>
            <mat-option value="Found more affordable one">Found more affordable one</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </div>
    
        <div class="reason-wrapper" *ngIf="reasonForm.get('declinedReason').value === 'other'">
          <span class="reason nunito">Note</span>
          <textarea class="textbox" placeholder="Enter Note" formControlName="note"></textarea>
        </div>
    
        <div class="d-flex justify-end buttons">
          <button type="button" class="decline-button nunito cancel" (click)="closePopup()">Cancel</button>
          <button type="submit" class="decline-button nunito submit"  (ngSubmit)="onSubmit()">Submit</button>
        </div>
      </form>

</div>

<!-- <div class="inventory-request-decline-wrapper" *ngIf="this.location =='manageOrder'">
  <div class="popup-header">
      <div class="d-flex align-items-center justify-content-between">
          <h2 class="nunito font-16 fw-600 black mb-0">Quantity</h2>
          <svg class="pointer" width="15" height="15" viewBox="0 0 15 15" fill="none" (click)="closePopup()"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.29934 7.50781L14.3638 13.5811L13.5728 14.3721L7.49953 8.30762L1.42629 14.3721L0.635273 13.5811L6.69973 7.50781L0.635273 1.43457L1.42629 0.643555L7.49953 6.70801L13.5728 0.643555L14.3638 1.43457L8.29934 7.50781Z"
                  fill="black" />
          </svg>
      </div>
  </div>

  <form [formGroup]="reasonForm" (ngSubmit)="onSubmit()">
      <div class="reason-wrapper">
        <span class="reason nunito">Reason</span>
        <mat-select disableRipple placeholder="Select Reason" class="dropdown-declined" formControlName="declinedReason">
          <mat-option value="Requirements have been change">Requirements have been change</mat-option>
          <mat-option value="Huge rush ithe Kitchen">Huge rush ithe Kitchen</mat-option>
          <mat-option value="Found more affordable one">Found more affordable one</mat-option>
          <mat-option value="">Other</mat-option>
        </mat-select>
      </div>
  
      <div class="reason-wrapper" *ngIf="reasonForm.get('declinedReason').value === ''">
        <span class="reason nunito">Note</span>
        <textarea class="textbox" placeholder="Enter Note" formControlName="note"></textarea>
      </div>
  
      <div class="d-flex justify-end buttons">
        <button type="button" class="decline-button nunito cancel" (click)="closePopup()">Cancel</button>
        <button type="submit" class="decline-button nunito submit"  (ngSubmit)="onSubmit()">Submit</button>
      </div>
    </form>

</div> -->
