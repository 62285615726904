import { Any, JsonObject, JsonProperty } from "json2typescript";
import {
  IntConverter,
  BooleanConverter,
  NullableString
} from "src/app/core/models/essentials";

@JsonObject("ResturantNameLangModel")
export class ResturantNameLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ResturantDescriptionLangModel")
export class ResturantDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ResturantOverviewLangModel")
export class ResturantOverviewLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("ResturantResponse")
export class ResturantResponse {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;

  @JsonProperty("ResturantTypeData", [Object], true)
  ResturantTypeData: Object[] = undefined;

  @JsonProperty("ResturantType", Any, true)
  ResturantType: Any = undefined;

  @JsonProperty("ResturantNameLangs", [ResturantNameLangModel], true)
  ResturantNameLangs: [ResturantNameLangModel] = undefined;

  @JsonProperty(
    "ResturantDescriptionLangs",
    [ResturantDescriptionLangModel],
    true
  )
  ResturantDescriptionLangs: [ResturantDescriptionLangModel] = undefined;

  @JsonProperty("ResturantOverview", [ResturantOverviewLangModel], true)
  ResturantOverview: [ResturantOverviewLangModel] = undefined;

  @JsonProperty("ShowOnHomepage", BooleanConverter, true)
  ShowOnHomepage: boolean = undefined;

  @JsonProperty("ShowNonCheckedInUser", BooleanConverter, true)
  ShowNonCheckedInUser: boolean = undefined;

  @JsonProperty("ResturantMenu", [String], true)
  ResturantMenu: string[] = undefined;

  @JsonProperty("ResturantImages", [String], true)
  ResturantImages: string[] = undefined;
  
  @JsonProperty("ResturantItemMenu", [], true)
  ResturantItemMenu: [] = undefined;
  
  @JsonProperty("Pdf", NullableString, true)
  Pdf: string = undefined;

}

@JsonObject("ResturantResponseArrayModel")
export class ResturantResponseArrayModel {
  @JsonProperty("ResturantLists", [ResturantResponse], false)
  ResturantLists: [ResturantResponse] = undefined;

  @JsonProperty("CountLists", IntConverter, true)
  CountLists: number = undefined;
}
