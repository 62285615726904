import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { MasterService } from '../../services/master.service';
import { HotelBannerModel, HotelBannerLangModel } from '../../models/request/hotelBanner.model';
import { ManageBrandingModel } from '../../models/request/manageBranding.model';
import { Globals } from '../../globals';

@Component({
  selector: 'app-hotel-banner',
  templateUrl: './hotel-banner.component.html',
  styleUrls: ['./hotel-banner.component.scss']
})
export class HotelBannerComponent implements OnInit {
  //langSubscribe$;
  //brandingSubscribe$;
  public  hotelBannerModel:HotelBannerModel = new HotelBannerModel();
  public  hotelBannerLangModel:HotelBannerLangModel =  new HotelBannerLangModel();
  public  manageBrandingModel:ManageBrandingModel =  new ManageBrandingModel();
  constructor(public globalService:GlobalService,private masterService:MasterService,public globals:Globals) { }
  public isLoaded :boolean = false;

  ngOnInit() {
       let reqModel =  { 
      "Lang": this.globals.userLangTranslate,
      "Subdomain":this.globalService.getHostname()
    }

    this.fetchBanner(reqModel);
  }

  ngOnDestroy(){
    /*if(this.langSubscribe$){
      this.masterService.userSwitchLang.unsubscribe();
    }*/
    /*if(this.brandingSubscribe$){
      this.masterService.manageBrandingData.unsubscribe();
    }*/
  }

  fetchBanner(reqModel){
    this.globalService.showLoader = true;
    this.masterService.getBanner(reqModel).subscribe(response => {
      if (response.Status) {
        this.globalService.showLoader = false;
        this.hotelBannerModel = this.globalService.jsonConvert.deserializeObject(response.Data, HotelBannerModel);

        if(this.hotelBannerModel.HotelBannerLang.length){
          this.hotelBannerLangModel = this.hotelBannerModel.HotelBannerLang[0];
        }
        this.isLoaded = true;
      } else {
        this.isLoaded = true;
        this.globalService.showLoader = false;
        this.globalService.handleApiError(response);
      }
    }, error => {
      this.isLoaded = true;
      this.globalService.showLoader = false;
      this.globalService.handleApiError(error);
    });
  }

}
