<div class="d-flex align-items-center justify-content-between px-3 pt-3 pb-0">
    <h3 class="mb-0">Get direction</h3>
    <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn">
        <i class="material-icons">close</i>
    </a>
</div>
<div class="p-4">
    <!-- <p>map was here(google-map.component)</p> -->
    <!-- <agm-map [latitude]="lat" [longitude]="lng">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
    </agm-map> -->
</div>
