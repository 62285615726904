<div class="manage-service-filter-popup">
    <div class="filter-popup-header">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="nunito font-16 fw-600 black mb-0">Quantity</h2>
            <svg (click)="closeDialog()" class="pointer" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.29934 7.50781L14.3638 13.5811L13.5728 14.3721L7.49953 8.30762L1.42629 14.3721L0.635273 13.5811L6.69973 7.50781L0.635273 1.43457L1.42629 0.643555L7.49953 6.70801L13.5728 0.643555L14.3638 1.43457L8.29934 7.50781Z"
                    fill="black" />
            </svg>
        </div>
    </div>
   
    <form [formGroup]="restockForm">
        <div class="requested-quantity" *ngIf="this.threshold !== 'firstInput'">
          <h2 class="nunito font-16 black mb-2">Requested Quantity</h2>
          <div class="d-flex align-items-center justify-content-between">
            <input
              type="number"
              placeholder="Enter"
              class="quantity-filter-popup-input"
              formControlName="requestedQuantity"
            >
            <mat-select class="quantity-dropdown-popup" placeholder="Select option to add or remove" formControlName="quantityAction">
              <mat-option value="Add">Add</mat-option>
              <mat-option value="Remove">Remove</mat-option>
            </mat-select>
          </div>
          <!-- Display validation error message for requestedQuantity -->
        
          <div *ngIf="restockForm.get('requestedQuantity').invalid && restockForm.get('requestedQuantity').touched" class="error-message">
            <div *ngIf="restockForm.get('requestedQuantity').errors.exceedsQuantityLimit" class="error-msg">
              You cannot remove more than {{ quantity - 1 }} items.
            </div>
            <div *ngIf="restockForm.get('requestedQuantity').errors.required" class="error-msg">
              Requested quantity is required.
            </div>
            <div *ngIf="restockForm.get('requestedQuantity').errors.min" class="error-msg">
              Requested quantity cannot be negative or 0.
            </div>
          </div>
          
        </div>
    
        <div class="purpose" *ngIf="this.threshold !== 'firstInput'">
          <h2 class="nunito font-16 black mb-2">Purpose</h2>
          
          <mat-select class="quantity-dropdown-popup" placeholder="Select an option" formControlName="purpose">
            <ng-container *ngIf="restockForm.get('quantityAction').value === 'Add'">
              <mat-option value="Restocked">Restocked</mat-option>
              <mat-option value="New Stock">New Stock</mat-option>
            </ng-container>
            <ng-container *ngIf="restockForm.get('quantityAction').value === 'Remove'">
              <mat-option value="Sold">Sold</mat-option>
              <mat-option value="Expired">Expired</mat-option>
            </ng-container>
          </mat-select>
          <!-- Display validation error message for purpose -->
          <div *ngIf="restockForm.get('purpose').invalid && restockForm.get('purpose').touched" class="error-msg">
            Purpose is required.
          </div>
        </div>
        
        <div class="purpose nunito font-16 black mb-2" *ngIf="this.threshold != 'firstInput'">
          <mat-checkbox formControlName="thresholdOnly">Threshold only</mat-checkbox>
      </div>

        <div class="purpose">
          <h2 class="nunito font-16 black mb-2">Threshold</h2>
        
          
           <mat-select #dropdown class="quantity-dropdown-popup" 
            [placeholder]="getPlaceholder()" 
            formControlName="threshold" 
            [compareWith]="compareWith"
            (selectionChange)="onSelectionChange($event.value)">
            <mat-option [value]="null">No Threshold</mat-option>
    
          <ng-container *ngFor="let option of thresholdOptions">
            <mat-option [value]="option">{{option}}</mat-option>
        </ng-container>
          </mat-select>

        </div>
        
      </form>
    
    <div class="cancel">
      <div *ngIf="restockForm.invalid && (restockForm.dirty || restockForm.touched)" class="error-msg">
        Please fill in all required fields.
      </div>
        <div class="d-flex  justify-content-end align-items-center pop-up-btn-group">
            <button class="pointer" (click)="closeDialog()">Cancel</button>
            <button [disabled]="restockForm.invalid" *ngIf="!this.requestorType" 
            (click) = "itemRestockConfirmationPopup()" class="pointer" [appAccessCheck]="this.requestType === 'restaurant' ? 'Manage-Restaurant-Inventory' || 'Approve-Restaurant-Inventory-Requests' : 'Manage-Serivce-Inventory' || 'Approve-Service-Inventory-Requests'">
            {{this.threshold === 'firstInput'?'Save':'Send Request'}}
          </button> 
            <button class="pointer" [disabled]="restockForm.invalid" *ngIf="this.requestorType" (click)="itemRestockAdmin()">Save</button> 
        </div>
        
    </div>
</div>
