<app-loader [ShowLoader]="globalService.showLoader"></app-loader>
<form #Form="ngForm" class="form-wrapper">

    <div class="modal-header clearfix">
        <h3 *ngIf="!updateButtonStatus;else  updateTitle">{{ 'AddImage'|translate}}</h3>
        <ng-template #updateTitle>
            <h3>{{ 'EditImage'|translate}}</h3>
        </ng-template>
        <div class="lang-drop-menu">
            <span>Editing text for:</span>
            <app-language-save (selectedLang)="switchLanguage($event)"></app-language-save>
        </div>
        <button class="material-icons" mat-dialog-close>close</button>
    </div>


    <div class="modal-body-content">
        <div class="uload-image" *ngIf="!imageReadStatus">
            <div class="upload">
                <span>
                    <i class="material-icons">file_upload</i><br>
                    {{'UploadYourImage'|translate}}<br>
                    <small>{{'DragDrop'|translate}}</small>
                </span>
                <input #file type="file" accept='image/*' (change)="preview(file.files)" />

                <span style="color:red;" *ngIf="message">{{message}}</span>
            </div>
            <mat-error *ngIf="imageRequired">
                {{'Validation.FileRequired'|translate}}
            </mat-error>
        </div>


        <div class="uploaded-image" *ngIf="imageReadStatus">
            <i class="fa fa-trash fa-6" style="color:red;" aria-hidden="true" (click)="removeDomImage()"></i>
            <img [src]="imgURL" height="200" *ngIf="imgURL">
        </div>

        <div class="image-title">
            <mat-form-field class="col-lg-12 pad0">
                <mat-label>{{'Title'|translate}}</mat-label>
                <input matInput type="text" name="Value" placeholder="Tremezzo, walking street" [(ngModel)]="galleryLangModel.Value" required #title="ngModel" pattern="^[a-zA-Z0-9_\-.,'()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñßŸŽžŠš ]*$"
                >

                <mat-error *ngIf="title.errors?.pattern && title.touched">
                    {{'Validation.InvalidString'|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="show-on-homepage">
            <mat-checkbox name="HomePageDisplay" [(ngModel)]="manageGalleryModel.HomePageDisplay">{{'ShowOnhomepage'|translate}}?
            </mat-checkbox>
        </div>

    </div>

    <div class="modal-footer-content">
        <!--<a (click)="gallerySubmit(Form)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="!updateButtonStatus;else  updateButton">Submit</a>-->

        <!--<a (click)="gallerySubmit(Form)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn">Submit</a>-->
        <a (click)="gallerySubmit(Form)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="!updateButtonStatus;else  updateButton">{{'Submit'|translate}}</a>

        <ng-template #updateButton>
            <a (click)="updateGallerySubmit(Form)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn">{{'Update'|translate}}</a>
        </ng-template>
        <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn">
            <i class="material-icons">close</i>{{'Cancel'|translate}}
        </a>
    </div>

</form>