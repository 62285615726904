<div>
  <div>
    <section id="fof">
      <div class="hgroup">
        <h1>
          <span><strong>4</strong></span>
          <span><strong>0</strong></span>
          <span><strong>4</strong></span>
        </h1>
        <h2>
          <br>
          <div>
            {{'Error'|translate}} 
          </div>
          <br>
          <div>
            {{'PageNotFound'|translate}}
          </div>
        </h2>
      </div>
      <p>
        {{'ForSomeReasonThePageYouRequestedCouldNotBeFoundOnOurServer'|translate}}
      </p>
      <p>
        <a href="javascript:history.go(-1)">&laquo; {{'GoBack'|translate}}</a> / <a href="/">{{'GoHome'|translate}} &raquo;</a>
      </p>
    </section>
  </div>
</div>