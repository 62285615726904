import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import moment from 'moment';


@Component({
  selector: 'app-calender-filter',
  templateUrl: './calender-filter.component.html',
  styleUrls: ['./calender-filter.component.scss']
})
export class CalenderFilterComponent implements OnInit {
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger; 
   iconCalender=true;
  public localDataFilter;
  filterForm : UntypedFormGroup;
  selectedDates: Date[] = [];
  public selectedDateRange: string = '';
  hoveredDate: Date | null = null;
  @Output() dateSelected = new EventEmitter();
  fromDate: Date; // Track the start date
  toDate: Date;   // Track the end date
  selectedRange: string; // Track the selected custom range
  hoverDate: Date | null; // Track the date being hovered
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  constructor(private formBuilder: UntypedFormBuilder,  private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.localDataFilter = JSON.parse(localStorage.getItem('viewOrderFilterData'));
    
    this.initForm();
    this.filterMenuTrigger?.menuClosed.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  toggleMenu() {
    if (this.filterMenuTrigger.menuOpen) {
      this.filterMenuTrigger.closeMenu();
    } else {
      this.filterMenuTrigger.openMenu();
    }
  }
  initForm() {
    if(this.localDataFilter !== null){
      this.filterForm = this.formBuilder.group({
        fromDate: this.localDataFilter.fromDate,  
        toDate:this.localDataFilter.toDate
      });
    }else{
      this.filterForm = this.formBuilder.group({
        fromDate:'',  
        toDate:''
      });

    }
  }
  ngAfterViewInit() {
    // Manually trigger change detection after view initialization
    this.cdr.detectChanges();
    this.filterMenuTrigger?.menuClosed.subscribe(() => {
      this.cdr.detectChanges();
    });
  }
  toggleIcon(){
    this.cdr.detectChanges();

    this.iconCalender =!this.iconCalender
    this.cdr.detectChanges();
  }

  displayDateRange(): string {
    const fromDate = this.filterForm.value.fromDate;
    const toDate = this.filterForm.value.toDate;
  
    if (fromDate && toDate) {
      const fromDateFormatted = moment.utc(fromDate).format('MMM D, YYYY');
      const toDateFormatted = moment.utc(toDate).format('MMM D, YYYY');
      return `${fromDateFormatted} - ${toDateFormatted}`;
    } else if (fromDate) {
      return `From ${moment.utc(fromDate).format('MMM D, YYYY')}`;
    } else if (toDate) {
      return `To ${moment.utc(toDate).format('MMM D, YYYY')}`;
    } else {
      return 'Select date';
    }
  }
  
  
  
  handleDateSelection(date: Date): void {
    const currentDate = new Date(); // Get the current date
    this.cdr.detectChanges()
  currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
  const minimumSelectableDate = this.selectedDates[0];
  if (date > currentDate || date < minimumSelectableDate) {
    // Don't allow selection of future dates
    return;
  }
    if (this.selectedDates.length === 0) {
      this.selectedDates.push(date);
      this.filterForm.controls['fromDate'].setValue(this.formatDate(date));
      this.cdr.detectChanges();

    } else if (this.selectedDates.length === 1) {
      this.selectedDates.push(date);
      this.filterForm.controls['toDate'].setValue(this.formatDate(date));
      this.cdr.detectChanges();

    } else {
      this.selectedDates = [date];
      this.filterForm.controls['fromDate'].setValue(this.formatDate(date));
      this.filterForm.controls['toDate'].setValue('');
      this.cdr.detectChanges();

    }
    this.cdr.detectChanges();
  }


  onTabClick(location){
    if(location=='Custom'){
          this.cdr.detectChanges();
    }else if(location=='Present'){
      this.cdr.detectChanges();
    }

  }
  onCancelClick(): void {
    this.cdr.detectChanges(); 

    this.selectedDateRange = ''; // Clear selected date range
    this.cdr.detectChanges(); 
    // Check if both 'fromDate' and 'toDate' have values
    if (this.filterForm?.controls['fromDate']?.value?.length > 1 && 
        this.filterForm?.controls['toDate']?.value?.length > 1) {
      
      this.selectedDates = []; // Clear selected dates array
      this.filterForm.controls['fromDate'].setValue(''); // Reset fromDate field
      this.filterForm.controls['toDate'].setValue(''); 
      this.filterForm.reset();  // Reset toDate field
      this.cdr.detectChanges(); 
      localStorage.removeItem('viewOrderFilterData'); // Remove data from local storage
      
      this.dateSelected.emit({data: false}); // Emit event to notify changes
      
    } else if(this.filterForm?.controls['fromDate']?.value?.length > 1 ){
      this.selectedDates = [];
      this.filterForm?.controls['fromDate'].setValue(''); // Reset fromDate field
      this.filterForm?.controls['toDate'].setValue(''); 
      this.filterForm.reset();  // Reset toDate field
      this.cdr.detectChanges(); 
      localStorage.removeItem('viewOrderFilterData'); // Remove data from local storage
      
      this.dateSelected.emit({data: false});
      this.cdr.detectChanges(); 

    }
    else   {
     // Ensure the menu is closed if the form is successfully reset or there is no action needed
     this.cdr.detectChanges(); 

     this.filterMenuTrigger.closeMenu(); 
     this.cdr.detectChanges(); 

    }
    this.cdr.detectChanges(); 

  }
  
  
  formatDate(date: Date): string {
    return moment.utc(date).format(); // Format the date in UTC using moment.js
  }
  
  onDateHover(date: Date): void {
    this.hoveredDate = date;
  }

  clearHover(): void {
    this.hoveredDate = null;
  }

  applyPreservedFilter(filterData: any) {
    if (filterData) {
      this.filterForm.setValue({
        fromDate: filterData.fromDate,
        toDate: filterData.toDate,
        purpose: filterData.Purpose,
        requestStatus: filterData.RequestStatus
      });
    }
  }


  displayFromDate(): string{
    const fromDate = this.filterForm.value.fromDate;
    if(fromDate){
    const fromDateFormatted = moment(fromDate).format('MMM D, YYYY');
    return fromDateFormatted;
  }else {
    return 'Start Date'
  }
  }
  displayToDate():string{
    const toDate = this.filterForm.value.toDate;
    if(toDate){
    const toDateFormatted = moment(toDate).format('MMM D, YYYY');
    return toDateFormatted;
  } else {
    return 'End Date'
  }
  }

  handleDateRangeSelection(range: string) {
    this.cdr.detectChanges();
    const today = moment.utc();
    const sevenDaysAgo = moment.utc().subtract(7, 'days');
    
    const lastWeekStartDate = moment.utc().subtract(1, 'weeks').startOf('isoWeek');
    const lastWeekEndDate = moment.utc().subtract(1, 'weeks').set({ day: 7, hour: 22, minute: 58, second: 0 });
    
    const last30DaysStartDate = moment.utc().subtract(30, 'days');
    
    const currentMonthStartDate = moment.utc().startOf('month');
    
    const currentYearStartDate = moment.utc().startOf('year');
    
    const lastYearStartDate = moment.utc().subtract(1, 'years').startOf('year');
    const lastYearEndDate = moment.utc().subtract(1, 'years').set({ month: 11, date: 31, hour: 22, minute: 58, second: 0 });
    const allTimeStartDate = moment.utc('2021-01-01'); // Start of 2021
    this.cdr.detectChanges();
    const formatDate = (date: moment.Moment) => date.format('YYYY-MM-DDTHH:mm:ss[Z]');
  
    switch (range) {
      case 'Today':
        this.filterForm.get('fromDate').setValue(formatDate(today));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      case 'Last 7 days':
        this.filterForm.get('fromDate').setValue(formatDate(sevenDaysAgo));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      case 'Last week':
        this.filterForm.get('fromDate').setValue(formatDate(lastWeekStartDate));
        this.filterForm.get('toDate').setValue(formatDate(lastWeekEndDate));
        break;
      case 'Last 30 days':
        this.filterForm.get('fromDate').setValue(formatDate(last30DaysStartDate));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      case 'Current month':
        this.filterForm.get('fromDate').setValue(formatDate(currentMonthStartDate));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      case 'This year':
        this.filterForm.get('fromDate').setValue(formatDate(currentYearStartDate));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      case 'Last year':
        this.filterForm.get('fromDate').setValue(formatDate(lastYearStartDate));
        this.filterForm.get('toDate').setValue(formatDate(lastYearEndDate));
        break;
      case 'All Time':
        this.filterForm.get('fromDate').setValue(formatDate(allTimeStartDate));
        this.filterForm.get('toDate').setValue(formatDate(today));
        break;
      default:
        this.filterForm.get('fromDate').setValue(null);
        this.filterForm.get('toDate').setValue(null);
        break;
    }
    this.selectedDateRange = range;
    this.cdr.detectChanges();
  this.onApplyClick('notApply')
  }

  onApplyClick(location): void {
    const fromDate = this.filterForm.value.fromDate;
    const toDate = this.filterForm.value.toDate;
  
    if (fromDate && toDate && fromDate === toDate) {
      const adjustedFromDate = moment(fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const adjustedToDate = moment(toDate).set({ hour: 22, minute: 58, second: 0 }).format('YYYY-MM-DDTHH:mm:ss[Z]');
      
      this.filterForm.get('fromDate').setValue(adjustedFromDate);
      this.filterForm.get('toDate').setValue(adjustedToDate);
    }
  
    if (location === 'apply') {
      this.applyButtonClick();
    }
  }

  
  applyButtonClick(){
    localStorage.setItem('viewOrderFilterData',JSON.stringify(this.filterForm.value))
    this.filterMenuTrigger.closeMenu()
    this.dateSelected.emit({data:this.filterForm.value});
  }

  isDateDisabled(date: Date): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
  
    const minimumSelectableDate = this.selectedDates[0]; // Use the fromDate as the minimum selectable date

  return date > tomorrow || date < minimumSelectableDate;
  }

  
  isRangeStart(date: Date): boolean {
    return this.selectedDates.length === 1 && date === this.selectedDates[0];
  }

  isRangeEnd(date: Date): boolean {
    return this.selectedDates.length === 2 && date === this.selectedDates[1];
  }

  isRangeHover(date: Date): boolean {
    if (this.selectedDates.length === 1) {
      return (
        this.hoveredDate !== null &&
        date > this.selectedDates[0] &&
        date <= this.hoveredDate
      );
    }
    return false;
  }



  // dateClassCallback = (date: Date): MatCalendarCellCssClasses => {
  //   const disabled = this.isDateDisabled(date);
  //   return disabled ? 'disabled-date' : '';
  // };
  dateClassCallback: MatCalendarCellCssClasses = (date: Date): MatCalendarCellCssClasses => {
    const disabled = this.isDateDisabled(date);
  
    if (this.isRangeStart(date)) {
      return ['selected-range', disabled ? 'disabled-date' : ''];
    } else if (this.isRangeEnd(date)) {
      return ['selected-range', disabled ? 'disabled-date' : ''];
    } else if (this.isRangeHover(date)) {
      return ['selected-range', disabled ? 'disabled-date' : ''];
    } else {
      return disabled ? 'disabled-date' : '';
    }
  };
 
}
