<!-- <app-header [title]="title2"></app-header> -->

<section class="full-wrapper data_driven_di">
    <div class="container">

        <div class="inn_content">
            <h1 translate>Banner</h1>
            <a href="javascript:void(0);" mat-flat-button class="front-btn-design" color="primary"></a>
        </div>
    </div>
</section>

<section class="full-wrapper analytics_advange_di">
    <div class="container">
        <div class="inn_content">
        </div>
    </div>
</section>

<section class="full-wrapper auto_predict_di">
    <div class="container">
        <div class="inn_content">
            <div class="row">
                <div class="col-md-6 col-sm-12 center">
                    <h2>{{'HotelGallery'|translate}}</h2>

                </div>
                <div class="col-md-6 col-sm-12 right-side">

                </div>
            </div>

        </div>
    </div>
</section>

<section class="full-wrapper insight_tool_di">
    <div class="container">
        <div class="inn_content">
            <div class="row">
                <div class="col-md-6 col-sm-12 left-side">
                    <h2>{{'OurRooms'|translate}}</h2>
                </div>
                <div class="col-md-6 col-sm-12 right-side">

                </div>
            </div>
        </div>
    </div>
</section>

<section class="full-wrapper remarketing_di">
    <div class="container">
        <div class="inn_content">
            <div class="row">
                <div class="col-md-12 col-sm-12  left-side">
                    <h2>{{'OurServices'|translate}}</h2>
                </div>
            </div>
            <div class="row">

            </div>
        </div>
    </div>
</section>

<!-- <app-footer></app-footer> -->