import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  ValidationErrors,
  AbstractControl
} from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import { MasterService } from "../../services/master.service";
import { takeUntil, map } from "rxjs/operators";
import {
  RoleListArrayModel,
  DepartmentPermissionModel,
  DepartmentPermissionArrayModel,
  StaffRequestModel,
  StaffListArrayModel,
  StaffResponsePopupModel
} from "../../models/common-request-response";
import { AmazonWebService } from "../../services/amazon-web.service";
import { Globals } from "../../globals";
import { Observable } from "rxjs";

@Component({
  selector: "app-add-staff",
  templateUrl: "./add-staff.component.html",
  styleUrls: ["./add-staff.component.scss"]
})
export class AddStaffComponent implements OnInit {
  uploadSubscribe$;
  form: UntypedFormGroup;
  public departmentList: DepartmentPermissionArrayModel = new DepartmentPermissionArrayModel();
  public departmentListArray;
  public roleListArrayModel: RoleListArrayModel = new RoleListArrayModel();
  public staffRequestModel: StaffRequestModel = new StaffRequestModel();
  public staffListArrayModel: StaffListArrayModel = new StaffListArrayModel();
  public staffResponseModel: StaffResponsePopupModel = new StaffResponsePopupModel();
  public roleListArray;

  currentFileUpload: File;
  currentFileName: string;
  public uploadStatus: boolean = false;
  @Output() onAdd = new EventEmitter<any>(true);
  showLoader: boolean = false;
  parentLocationStatus: string = "imageEdit";
  public imgUrl = "";
  constructor(
    public globalService: GlobalService,
    private fb: UntypedFormBuilder,
    public masterService: MasterService,
    public amazonWebService: AmazonWebService,
    public globals: Globals
  ) {}

  ngOnInit() {
    this.initForm();
    this.getDepartmentList(this.globalService.getLangSaveProperty());
    this.showListOfRoles();
  }

  ngOnDestroy() {}

  initForm() {
    this.form = this.fb.group({
      FirstName: [null, [Validators.required]],
      LastName: [null, [Validators.required]],
      DepartmentId: [null, [Validators.required]],
      RoleId: [null, [Validators.required]],
      Designation: [null, [Validators.required]],
      Email: new UntypedFormControl("", {
        validators: [Validators.required, Validators.email],
        asyncValidators: [
          (control: AbstractControl): Observable<ValidationErrors | null> =>
            this.checkEmailExists(control)
        ],
        updateOn: "blur"
      }),
      // Email: [null, [Validators.required, Validators.email,]],
      PhoneNumber: [
        null,
        // [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]
        [Validators.required, Validators.pattern(/^(?=.*[0-9])[- +()0-9]+$/)]
      ],
      CreateAccount: [""]
    });
  }

  checkEmailExists(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    if (control.value) {
      return this.masterService.matchStaffEmail({ Email: control.value }).pipe(
        map(
          response => {
            
            if (response.Status) {
              
              return null;
            } else {
              
              return { checkEmail: { value: control.value } };
            }
          },
          error => {
            
            return { checkEmail: { value: control.value } };
          }
        )
      );
    }
  }

  showListOfRoles() {
    this.globalService.showLoader = true;
    this.masterService
      .listRoles({
        Lang: this.globalService.gethotelLangTranslate()
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.roleListArrayModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              RoleListArrayModel
            );

            this.roleListArray = this.roleListArrayModel.RoleListArray;

            // 
          } else {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          // this.globalService.handleApiError(error);
        }
      );
  }

  getDepartmentList(lang) {
    this.globalService.showLoader = true;
    this.masterService
      .getDepartmentList({
        Lang: lang
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;

            this.departmentList = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              DepartmentPermissionArrayModel
            );

            this.departmentListArray = this.departmentList.DepartmentPermissionArray;
            
          } else {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          // this.globalService.handleApiError(error);
        }
      );
  }

  imageUpload(file) {
    this.uploadStatus = true;
    this.currentFileUpload = file.item(0);
    this.currentFileName = file[0].name;
  }

  save(form) {
    // 
    this.staffRequestModel = form.value;
    
    if (form.valid) {
      this.showLoader = true;
      if (this.uploadStatus) {
        this.amazonWebService.uploadObject(
          this.currentFileName,
          this.currentFileUpload,
          "private",
          "staff"
        );
        this.uploadSubscribe$ = this.amazonWebService.processCompleted.subscribe(
          response => {
            if (response.data.Location) {
              //update Image Model
              this.staffRequestModel.ProfileImage =
                this.globals.S3Url +
                this.globalService.getHostname() +
                "/staff/" +
                response.data.Location;

              // 
              //   "Binding Models staffRequestModel " +
              //     JSON.stringify(this.staffRequestModel)
              // );

              this.addstaff(this.staffRequestModel);
              this.uploadStatus = false;
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      } else {
        
        
        this.addstaff(this.staffRequestModel);
      }
    } else {
      this.globalService.validateFormFields(form.form);
    }
  }

  addstaff(staffRequestModel) {
    this.showLoader = true;
    //Add staff----------------------------
    this.masterService.addstaff(staffRequestModel).subscribe(
      response => {
        if (response.Status) {
          // 
          this.showLoader = false;
          this.globalService.handleSuccessMessage(response.Message);
          // 

          this.staffResponseModel = this.globalService.jsonConvert.deserializeObject(
            response.Data,
            StaffResponsePopupModel
          );

          if (this.staffResponseModel) {
            this.onAdd.emit({
              id: this.staffResponseModel.id,
              value:
                this.staffResponseModel.FirstName +
                " " +
                this.staffResponseModel.LastName,
              image: this.staffResponseModel.ProfileImage
            });
          } else {
            this.onAdd.emit(false); //If Already Exists
          }
        } else {
          this.showLoader = false;
          this.globalService.handleApiError(response);
        }
      },
      error => {
        this.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }
}
