<header class="main_header">
    <div class="container">
        <div class="row d-flex algin-center justify-content-between align-items-center">
            <div class="col-sm-12 col-md-8 col-lg-4">
                <div class="logo">
                    <a href="javascript:void(0);" routerLink="/"><!--<img src="assets/images/logo.png">-->{{ 'title'}}</a>
                </div>
            </div>
            <div class="col-sm-1 col-md-4 col-lg-8 text-right custom_nav">
                <nav>
                    <div class="nav-wrapper float-right">
                        <a href="javascript:void(0);" class="responsive_menu"><i class="material-icons">menu</i></a>
                        <div class="menu_wrapper d-flex align-items-center justify-content-end" id="menu_wrapper">
                            <ul id="nav-mobile" class="first_menu first_menu_list">
                                <li><a routerLink="/rooms" style="cursor:pointer;">Rooms</a></li>
                                <li><a routerLink="/rooms" style="cursor:pointer;">Hotel Guide</a></li>

                                <li><a href="javascript:void(0);" [matMenuTriggerFor]="appMenu">{{ language }}</a>
                                    <mat-menu #appMenu="matMenu" class="menu-dropdown">
                                        <button mat-menu-item (click)="switchLanguage('en')">es</button>
                                        <button mat-menu-item (click)="switchLanguage('fr')">fr</button>
                                    </mat-menu>
                                </li>

                            </ul>

                            <a class="search_btn" href="javascript:void(0);">
                                <i class="material-icons">search</i>
                                <input matInput placeholder="Favorite food" class="search" value="Sushi">
                            </a>
                            <a href="javascript:void(0);" (click)="openCheckInModal()" class="signup_btn">Check-In</a>
            
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>