import { Component, OnInit, Optional, Inject, ViewChild, ElementRef } from '@angular/core';
import { ItemsRestockConfirmationComponent } from '../items-restock-confirmation/items-restock-confirmation.component';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'; // Import FormBuilder and Validators
import { MasterService } from '../../services/master.service';
import { HotelAuthService } from 'src/app/hotel-admin/services/hotel-auth.service';
import { GlobalService } from '../../services/global.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-items-re-stock-modal',
  templateUrl: './items-re-stock-modal.component.html',
  styleUrls: ['./items-re-stock-modal.component.scss']
})
export class ItemsReStockModalComponent implements OnInit {
  restockForm: UntypedFormGroup; 
  public logedInUser
  public requestType
  public id
  public itemId
  public requestorType
  public bulkPermissions;
  public serviceInventory;
  public restaurantInventory;
  public approveServiceInventoryRequests;
  public approveRestaurantInventoryRequests;
  public catId;
  quantity
  threshold;
  onlythres =false
  thresholdOptions: number[] = Array.from({ length: 20 }, (_, i) => (i + 1) * 5);

  compareWith = (o1: any, o2: any) => o1 === o2;  customThreshold: number | null = null;
  @ViewChild('dropdown', { static: false }) dropdown: ElementRef;
  constructor(    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private masterService: MasterService,
    private globalService: GlobalService,
    private hotelAuthService: HotelAuthService,
    public dialogRef: MatDialogRef<ItemsReStockModalComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      
      this.requestType = data.requestType;
      this.id = data.id
      this.itemId = data.itemId
      this.catId = data.catId
      this.threshold = data.threshold
      this.quantity=data.quantity
     }

  ngOnInit(): void {
    this.initForm();
    this.getAdminPermissions();
    this.getHotelProfile();
    this.restockForm.get('quantityAction').valueChanges.subscribe(action => {
      if (action === 'Remove') {
        this.restockForm.get('requestedQuantity').setValidators([
          Validators.required,
          Validators.min(1),
          this.removeQuantityValidator(this.quantity)
        ]);
      } else {
        this.restockForm.get('requestedQuantity').setValidators([
          Validators.required,
          Validators.min(1)
        ]);
      }
      this.restockForm.get('requestedQuantity').updateValueAndValidity();
    });
  }

  private initForm() {
    if(this.threshold === 'firstInput'){
      this.restockForm = this.formBuilder.group({
        threshold: [this.threshold || null] 
      });
    }else{
      this.restockForm = this.formBuilder.group({
        thresholdOnly: [false], // Initialize checkbox
        requestedQuantity: [null, [Validators.required, Validators.min(1),this.removeQuantityValidator(this.quantity)]], 
        quantityAction: [null, Validators.required], 
        purpose: [null, Validators.required] ,
        threshold: [this.threshold|| null] 
      });
    }
    this.restockForm.get('thresholdOnly')?.valueChanges.subscribe(checked => {
      this.toggleFields(checked);
    });

  }

  private toggleFields(disable: boolean) {
    if (disable) {
      this.restockForm.get('requestedQuantity').disable();
      this.restockForm.get('quantityAction').disable();
      this.restockForm.get('purpose').disable();
      this.onlythres = true
    } else {
      this.restockForm.get('requestedQuantity').enable();
      this.restockForm.get('quantityAction').enable();
      this.restockForm.get('purpose').enable();
      this.onlythres = false

    }
  }

  getPlaceholder() {
    const selectedValue = this.restockForm.get('threshold')?.value;
    return selectedValue === null ? 'No Threshold' : 'Select an option';
}

  onCustomThresholdChange() {
    
    if (this.customThreshold !== null && this.customThreshold !== undefined) {
      this.restockForm.get('threshold')?.setValue(this.customThreshold, { emitEvent: false });
    }
  }

  onSelectionChange(value: any) {
    if (value === null) {
      // Handle the case when 'No Threshold' (null) is selected
      this.customThreshold = null; // Optionally reset custom input
    } else if (typeof value === 'number') {
      // Handle numeric values
      this.customThreshold = null; // Reset custom input if a predefined option is selected
    }
    
    // Update the form control value
    this.restockForm.get('threshold')?.setValue(value, { emitEvent: false });
  }
  

  setDropdownValue(value: number) {
    const dropdownElement = this.dropdown.nativeElement;
    dropdownElement.value = value;
    dropdownElement.focus();
  }

  getHotelProfile() {
    this.globalService.showLoader = true;
    this.hotelAuthService.gethotelProfile({ param: null }).subscribe(
      response => {
        if (response.Status) {
          this.globalService.showLoader = false;
          this.logedInUser = response.Data;
          
          
        } else {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(response);
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }

  itemRestockConfirmationPopup() {
    this.globalService.showLoader = true;
    this.masterService
      .requestInventoryRestock({
        LangSave: this.globalService.getLangSaveProperty(),
        subDomain: this.globalService.getHostname(),
        requestedQuantity: this.restockForm.value.requestedQuantity,
        quantityAction: this.restockForm.value.quantityAction,
        purpose: this.restockForm.value.purpose,
        itemId: this.itemId,
        requestedBy: this.logedInUser._id,
        requestType: this.requestType,
        restaurantId: this.requestType == 'restaurant' ? this.id : null,
        serviceId: this.requestType == 'service' ? this.id : null,
        catId: this.catId,
        threshold: this.restockForm.value.threshold
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            // this.globalService.emitPermissions(response.AdminPermissions);

            let dialogRef = this.dialog.open(ItemsRestockConfirmationComponent, {
              width: "311px",
              height: "259px",
              panelClass: "cm-md-modal",
              disableClose: true,
              data: {
              viewOrder : true,
              action: this.restockForm.value.quantityAction,
              restaurantId: this.requestType == 'restaurant' ? this.id : null,
              serviceId: this.requestType == 'service' ? this.id : null,
              threshold: this.restockForm.value.threshold

              }
            });
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );

  }
  removeQuantityValidator(quantity: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const requestedQuantity = control.value;
      if (control.parent && control.parent.get('quantityAction').value === 'Remove' && requestedQuantity > quantity - 1) {
        return { 'exceedsQuantityLimit': true };
      }
      return null;
    };
  }
  getAdminPermissions(){
    
    this.globalService.showLoader =true;
    const data = {};
    this.hotelAuthService.getAdminPermissions(data).subscribe(
      response => {
        if (response.Status) {
          if(response.hasOwnProperty("AdminPermissions") && !this.globalService.validateIsEmpty(response.AdminPermissions)){
            this.bulkPermissions = response.AdminPermissions;
            for(let i = 0; i<this.bulkPermissions.length; i++){
              if(this.bulkPermissions[i].nameKey == 'Manage-Serivce-Inventory'){
                if(this.bulkPermissions[i].permissions[0].viewOnly === false){
                  this.serviceInventory = false;
                }else{
                  this.serviceInventory = true;
                }
              }
        
              else if(this.bulkPermissions[i].nameKey == 'Manage-Restaurant-Inventory'){
                if(this.bulkPermissions[i].permissions[0].viewOnly === false){
                  this.restaurantInventory = false;
                }else{
                  this.restaurantInventory = false;
                }
              }
            
              else if(this.bulkPermissions[i].nameKey == 'Approve-Service-Inventory-Requests'){
                if(this.bulkPermissions[i].permissions[0].viewOnly === false){
                  this.approveServiceInventoryRequests = false;
                }else{
                  this.approveServiceInventoryRequests = true;
                }
              }
              else if(this.bulkPermissions[i].nameKey == 'Approve-Restaurant-Inventory-Requests'){
                if(this.bulkPermissions[i].permissions[0].viewOnly === false){
                  this.approveRestaurantInventoryRequests = false;
                }else{
                  this.approveRestaurantInventoryRequests = true;
                }
              }
            }
          }else{
            this.approveRestaurantInventoryRequests = true;
            this.approveServiceInventoryRequests = true;
          }
          this.setPermissions();
        } else {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(response);
          this.globalService.toggleLoader();
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }

  setPermissions(){
    if(this.requestType == 'service'){
      if(this.approveServiceInventoryRequests){
        this.requestorType = true;
      }else{
        this.requestorType = false;
      }
    }else if(this.requestType == 'restaurant'){
      if(this.approveRestaurantInventoryRequests){
        this.requestorType = true;
      }else{
        this.requestorType = false;
      }
    }
    
  }

  itemRestockAdmin(){

    
    if(this.threshold === 'firstInput'){
      if(this.restockForm.value.threshold||this.restockForm.value.threshold ==null){
        const data = {
          threshold: this.restockForm.value.threshold
        }
        this.closeDialogs(data);
      }
    }else if(this.onlythres){
      if(this.restockForm.value.threshold||this.restockForm.value.threshold ==null){
        const data = {
          threshold: this.restockForm.value.threshold
        }
        let dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
          data: {
            restockAdmin: true,
            LangSave: this.globalService.getLangSaveProperty(),
            subDomain: this.globalService.getHostname(),
            requestedQuantity: null,
            quantityAction: null,
            purpose: null,
            itemId: this.itemId,
            requestedBy: this.logedInUser._id,
            requestType: this.requestType,
            restaurantId: this.requestType == 'restaurant' ? this.id : null,
            serviceId: this.requestType == 'service' ? this.id : null,
            catId: this.catId,
            threshold: this.restockForm.value.threshold
          }
      });
        // this.closeDialogs(data);
        dialogRef.afterClosed().subscribe(result => {
          if(result.event){
            this.closeDialogs(result);
          }
        });
        
      }
    }else{
      
      let dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
        data: {
          restockAdmin: true,
          LangSave: this.globalService.getLangSaveProperty(),
          subDomain: this.globalService.getHostname(),
          requestedQuantity: this.restockForm.value.requestedQuantity,
          quantityAction: this.restockForm.value.quantityAction,
          purpose: this.restockForm.value.purpose,
          itemId: this.itemId,
          requestedBy: this.logedInUser._id,
          requestType: this.requestType,
          restaurantId: this.requestType == 'restaurant' ? this.id : null,
          serviceId: this.requestType == 'service' ? this.id : null,
          catId: this.catId,
          threshold: this.restockForm.value.threshold
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.event){
        this.closeDialogs(result);
      }
    });
  }
}

  closeDialogs(data){
    this.dialogRef.close({event:true, data:data});
  
  }

  closeDialog(){
    this.dialogRef.close();
  
  }

  ngOnDestroy(){

  }
}
