import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter,NullableString } from 'src/app/core/models/essentials';


@JsonObject("HotelAboutUsLangModel")
export class HotelAboutUsLangModel {
    @JsonProperty("Lang", NullableString, true) 
    Lang: string = undefined;
    @JsonProperty("AboutUsTitle",NullableString, true)
    AboutUsTitle:string = undefined;
    @JsonProperty("AboutUsDescription",NullableString, true)
    AboutUsDescription:string = undefined;
    @JsonProperty("AboutUsButtonText",NullableString, true)
    AboutUsButtonText:string = undefined;
}

@JsonObject("HotelAboutUsModel")
export class HotelAboutUsModel {
    @JsonProperty("HotelAboutUsLang", [HotelAboutUsLangModel], true) 
    HotelAboutUsLang: [HotelAboutUsLangModel] = undefined;
    @JsonProperty("AboutUsButtonType", NullableString, true) 
    AboutUsButtonType: string = undefined;
} 


