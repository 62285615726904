<div class="billing-history-header">
  <div class="billing-header-contents d-flex justify-between align-item">
    <div class="billing-header-logo mr-3">
      <img [src]="logo" alt="">
    </div>
  </div>
</div>
<div class="payment-success-popup">
  <div class="payment-success">
    <div class="payment-header">
      <div class="header-contents d-flex flex-column">
        <div class="check-icon">
          <img src="../../../../assets/images/failed-payment.webp" alt="">
        </div>
        <span class="font-20 font-bold mt-3 failedtext">{{'Failed'|translate}}</span>

      </div>
    </div>
    <div class="payment-body">
      <p class="font-20 medium-600 text-center"> {{'OooopsomethingWrong'|translate}}</p>
      <div class="button-success d-flex justify-center mt-4">
        <button mat-raised-button id="tryagainbutton" (click)="billing()">{{'TryAgain'|translate}}</button>
      </div>

    </div>
  </div>
</div>