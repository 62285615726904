import { Component, OnInit } from '@angular/core';
import { HotelLoginModel } from '../../models/request/hotel-login.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { NgForm } from '@angular/forms';
import { ResetPasswordService } from '../../services/reset-password.service';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public  hotelLoginModel:HotelLoginModel = new HotelLoginModel();
  public urlParam:string;
  constructor(public globalService:GlobalService,private route:ActivatedRoute,private router:Router,private   resetPasswordService:ResetPasswordService,private jwtService:JwtService) { }

  ngOnInit() {
  
  }

  resetPass(form:NgForm){
      if(!form.invalid){
        this.globalService.showLoader=true; 
        this.urlParam = this.route.snapshot.paramMap.get('secret');
        this.resetPasswordService.reset({"Secret":this.urlParam,"Password":form.value.Password}).subscribe(response=>{
            if(response.Status){
              this.jwtService.saveToken(response.Data,true);
            }else{
              this.globalService.showLoader=false;
              this.globalService.handleApiError(response);
            }
        },error=>{
            this.globalService.showLoader=false;
            this.globalService.handleApiError(error);
        })

      }else{
        this.globalService.showLoader=false;  
        this.globalService.validateFormFields(form.form)
      }
  }

}
