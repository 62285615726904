import { Injectable, EventEmitter } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, throwError } from "rxjs";
import { ResultModel } from "src/app/core/models/response/base.model";
import { HttpEvent, HttpRequest, HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { JwtService } from "./jwt.service";

import { HttpHeaders } from "@angular/common/http";
import { Globals } from "../globals";
import { catchError } from "rxjs/operators";
import { UserMenuService } from "src/app/users/services/user-menu.service";
import { GlobalService } from "./global.service";
@Injectable()
export class MasterService {
  galleryData = new EventEmitter<any>();
  profileData = new EventEmitter<any>();
  userSwitchLang = new EventEmitter<any>();
  manageBrandingData = new EventEmitter<any>();
  langCustomizeEmit = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,
    public globals: Globals,
    private userMenuService: UserMenuService,
    private globalService: GlobalService
  ) {}

  private setAwsHeaders(): Headers {
    const headersConfig = {
      "Content-Disposition": "attachment",
      "x-amz-server-side-encryption": "AES256"
    };
    return new Headers(headersConfig);
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  gethotelLangTranslate(): string {
    if (!window.localStorage["hotelLangTranslate"]) {
      return this.globals.hotelLangTranslate;
    } else {
      return window.localStorage["hotelLangTranslate"];
    }
  }

  getuserLangTranslate(): string {
    if (!window.localStorage["userLangTranslate"]) {
      return this.globals.userLangTranslate;
    } else {
      return window.localStorage["userLangTranslate"];
    }
  }

  getLangSaveProperty() {
    if (!window.localStorage["langSaveProperty"]) {
      return this.globals.langSaveProperty;
    } else {
      return window.localStorage["langSaveProperty"];
    }
  }
  getLangSaveValue() {
    if (!window.localStorage["langSaveValue"]) {
      return this.globals.langSaveValue;
    } else {
      return window.localStorage["langSaveValue"];
    }
  }

  getManageBranding(req) {
    this.userMenuService.fetchManageBrand(req).subscribe(
      response => {
        if (response.Status) {
          this.manageBrandingData.emit({
            error: null,
            Data: response.Data
          });
        } else {
          this.globalService.handleApiError(response);
        }
      },
      error => {
        //
        this.globalService.handleApiError(error);
        this.manageBrandingData.emit({
          error: error,
          Data: null
        });
      }
    );
  }

  deleteLogoImage(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteLogoImage";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  getTimezones(data = null): Observable<ResultModel> {
    const route = "/getTimezones";
    return this.apiService.get<ResultModel>(route, data);
  }

  getAwsSignedUrl(data): Observable<ResultModel> {
    const route = "/hotel/getAwsSignedUrl";
    return this.apiService.post<ResultModel>(route, data);
  }

  putObjectIntoBucket(data: any, file: any): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append("file", file);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": file.type,
        "x-amz-acl": "private",
        responseType: "text",
        observe: "response"
      })
    };
    const req = new HttpRequest("PUT", data, file, httpOptions);

    return this.http.request(req);
    /*return this.http.put(data, formdata, httpOptions)
    .pipe(catchError(this.formatErrors));*/
    //return this.http.put(data, formdata, httpOptions);
  }

  deleteObjectFromBucket(data: any, imgType: string): Observable<{}> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": imgType,
        "x-amz-acl": "private",
        responseType: "text",
        observe: "response"
      })
    };
    // const req = new HttpRequest('DELETE',data,httpOptions);

    // return this.http.request(req);

    return this.http.delete(data).pipe(catchError(this.formatErrors));
  }

  /*pushFileToStorage(file): Observable<HttpEvent<{}>> {
    
    const formdata: FormData = new FormData();
    const authToken = this.jwtService.getToken();
    
    formdata.append('file', file);

    

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'my-auth-token'
      })
    };
    const route = '/hotel/uploadImage';
    const req = new HttpRequest('PUT',`${environment.apiUrl}${route}`, formdata,httpOptions);
 
    return this.http.request(req);
  }*/

  getHotelBanner(reqModel): Observable<ResultModel> {
    const route = "/hotel/getHotelBanner";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getBanner(reqModel): Observable<ResultModel> {
    const route = "/getBanner";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getHotelAboutUs(reqModel): Observable<ResultModel> {
    const route = "/hotel/getHotelAboutUs";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getAboutUs(reqModel): Observable<ResultModel> {
    const route = "/getAboutUs";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addHotelBanner(reqModel): Observable<ResultModel> {
    const route = "/hotel/addHotelBanner";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  addHotelAboutUs(reqModel): Observable<ResultModel> {
    const route = "/hotel/addHotelAboutUs";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  matchCurrentPass(reqModel): Observable<ResultModel> {
    const route = "/matchCurrentPass";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  changePass(reqModel): Observable<ResultModel> {
    const route = "/changePass";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getCustomizeDetail(reqModel): Observable<ResultModel> {
    const route = "/getCustomizeDetail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateCustomizeDetail(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateCustomizeDetail";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getGalleryTitle(reqModel): Observable<ResultModel> {
    const route = "/getGalleryTitle";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addGalleryTitle(reqModel): Observable<ResultModel> {
    const route = "/hotel/addGalleryTitle";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  /**-------Language  Save ---------------------------------------------------------- */

  getHotelLangSetUp(reqModel): Observable<ResultModel> {
    const route = "/getHotelLangSetUp";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateHotelLangSetUp(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateHotelLangSetUp";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  /**-------Home Page Banner ---------------------------------------------------------- */
  deleteBannerImage(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteBannerImage";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /**-------Rooms Service---------------------------------------------------------- */
  addRoomType(reqModel): Observable<ResultModel> {
    const route = "/hotel/addRoomType";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  updateRoomType(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateRoomType";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  fetchRoomtypes(reqModel): Observable<ResultModel> {
    const route = "/hotel/fetchRoomtypes";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  fetchRoomBookings(reqModel): Observable<ResultModel> {
    const route = "/hotel/showBookings";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  deleteRoomType(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteRoomType";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  addAmentie(reqModel): Observable<ResultModel> {
    const route = "/hotel/addAmentie";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  addGroupAmenties(reqModel): Observable<ResultModel> {
    const route = "/hotel/addGroupAmenties";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  fetchAmenitiesList(reqModel): Observable<ResultModel> {
    const route = "/hotel/fetchAmenitiesList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateAmentie(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateAmentie";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  addRoom(reqModel): Observable<ResultModel> {
    const route = "/hotel/addRoom";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  updateRoom(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateRoom";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  showRoomListsAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/showRoomLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showRoomLists(reqModel): Observable<ResultModel> {
    const route = "/showRoomLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleRoomShow(reqModel): Observable<ResultModel> {
    const route = "/singleRoomShow";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleRoomBooking(reqModel): Observable<ResultModel> {
    const route = "/singleRoomBooking";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  suggestedRoomShow(reqModel): Observable<ResultModel> {
    const route = "/getSugestedRooms";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleRoomEdit(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleRoomEdit";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleRoomDelete(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleRoomDelete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  checkRoomTypeAlreadyChoosed(reqModel): Observable<ResultModel> {
    const route = "/hotel/checkRoomTypeAlreadyChoosed";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleRoomType(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleRoomType";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showAmenitiesIcons(reqModel): Observable<ResultModel> {
    const route = "/showAmenitiesIcons";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleAmentie(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleAmentie";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  /**-------Resturant Service---------------------------------------------------------- */
  addResturantType(reqModel): Observable<ResultModel> {
    const route = "/hotel/addResturantType";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  fetchResturantTypes(reqModel): Observable<ResultModel> {
    const route = "/hotel/fetchResturantTypes";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addResturant(reqModel): Observable<ResultModel> {
    const route = "/hotel/addResturant";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  showResturantLists(reqModel): Observable<ResultModel> {
    const route = "/showResturantLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showResturantListsAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/showResturantLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleResturantEdit(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleResturantEdit";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateResturant(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateResturant";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  singleResturantShow(reqModel): Observable<ResultModel> {
    const route = "/singleResturantShow";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  singleResturantDelete(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleResturantDelete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /**Hotel Guide */
  addHotelGuide(reqModel): Observable<ResultModel> {
    const route = "/hotel/addHotelGuide";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  fetchHotelGuidesListAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/fetchHotelGuidesList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  fetchHotelGuidesList(reqModel): Observable<ResultModel> {
    const route = "/fetchHotelGuidesList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleHotelGuide(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleHotelGuide";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateHotelGuide(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateHotelGuide";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  deleteHotelGuide(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteHotelGuide";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  rearrangeGuides(reqModel): Observable<ResultModel> {
    const route = "/hotel/rearrangeGuides";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  /**-------Home Page Room ---------------------------------------------------------- */
  addRoomTitle(reqModel): Observable<ResultModel> {
    const route = "/hotel/addRoomTitle";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  getRoomTitle(reqModel): Observable<ResultModel> {
    const route = "/getRoomTitle";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateRoomHomeStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateRoomHomeStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  updateRoomImages(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateRoomImages";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  /**-------Home Page Resturant---------------------------------------------------------- */
  addResturantTitle(reqModel): Observable<ResultModel> {
    const route = "/hotel/addResturantTitle";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  getResturantTitle(reqModel): Observable<ResultModel> {
    const route = "/getResturantTitle";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleResturantType(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleResturantType";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateResturantType(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateResturantType";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  deleteResturantType(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteResturantType";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  updateResturantHomeStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateResturantHomeStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  updateResturantImages(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateResturantImages";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  /**-------About Us detail---------------------------------------------------------- */

  addAboutUsDetail(reqModel): Observable<ResultModel> {
    const route = "/hotel/addAboutUsDetail";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getAboutUsDetailHotel(reqModel): Observable<ResultModel> {
    const route = "/hotel/getAboutUsDetail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getAboutUsDetail(reqModel): Observable<ResultModel> {
    const route = "/getAboutUsDetail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateAboutUsDetail(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateAboutUsDetail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleAmenitiesDelete(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleAmenitiesDelete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /**-------Roles Get AdminPermissions---------------------------------------------------------- */

  getAdminPermissions(reqModel): Observable<ResultModel> {
    const route = "/hotel/getAdminPermissions";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  saveRole(reqModel): Observable<ResultModel> {
    const route = "/hotel/saveRole";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  listRoles(reqModel): Observable<ResultModel> {
    const route = "/hotel/listRoles";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  editRole(reqModel): Observable<ResultModel> {
    const route = "/hotel/editRole";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateRole(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateRole";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  /**-------Get Department List---------------------------------------------------------- */
  getDepartmentList(reqModel): Observable<ResultModel> {
    const route = "/hotel/getDepartmentList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  /**-------Add staff-------------------------------------------------------------------- */
  addstaff(reqModel): Observable<ResultModel> {
    const route = "/hotel/addstaff";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  updatestaff(reqModel): Observable<ResultModel> {
    const route = "/hotel/updatestaff";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  checkActivate(reqModel): Observable<ResultModel> {
    const route = "/hotel/checkActivate";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getStaffList(reqModel): Observable<ResultModel> {
    const route = "/hotel/getStaffList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getStaff(reqModel): Observable<ResultModel> {
    const route = "/showAsignStaffList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleStaffShow(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleStaffShow";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  staffResendInvitation(reqModel): Observable<ResultModel> {
    const route = "/hotel/staffResendInvitation";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  setStaffPassword(reqModel): Observable<ResultModel> {
    const route = "/hotel/setStaffPassword";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  deleteStaff(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteStaff";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  deleteRole(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteRole";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /**-------Guest Permission List-------------------------------------------------------------------- */
  guestPermissionList(reqModel): Observable<ResultModel> {
    const route = "/hotel/guestPermissionList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  saveGuestRole(reqModel): Observable<ResultModel> {
    const route = "/hotel/saveGuestRole";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getGuestPermission(reqModel): Observable<ResultModel> {
    const route = "/hotel/getGuestPermission";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  filterStaff(reqModel): Observable<ResultModel> {
    const route = "/hotel/filterStaff";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  manageOrder(reqModel): Observable<ResultModel> {
    const route = "/hotel/manageOrder";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  matchStaffEmail(reqModel): Observable<ResultModel> {
    const route = "/hotel/matchStaffEmail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  // 

  manageRestaurantGuest(reqModel): Observable<ResultModel> {
    const route = "/hotel/manageRestaurantGuest";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  /**-------Department-------------------------------------------------------------------- */

  addDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/addDepartment";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  editDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/editDepartment";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateDepartment";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  deleteDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteDepartment";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /**-------Inclusion /Exclusion-------------------------------------------------------------------- */
  addInclusion(reqModel): Observable<ResultModel> {
    const route = "/hotel/addInclusion";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  showSingleInclusion(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleInclusion";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateInclusion(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateInclusion";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  updateOrderStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/manageOrderStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  fetchInclusionList(reqModel): Observable<ResultModel> {
    const route = "/hotel/fetchInclusionList";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  singleInclusionDelete(reqModel): Observable<ResultModel> {
    const route = "/hotel/singleInclusionDelete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  addService(reqModel): Observable<ResultModel> {
    const route = "/hotel/addService";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  requestInventoryRestock(reqModel): Observable<ResultModel> {
    const route = "/hotel/inventoryRestock";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  inventoryRestockAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/inventoryRestockAdmin";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getInventoryRequest(reqModel): Observable<ResultModel> {
    const route = "/getInventoryRequest";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  inventoryRequestApproval(reqModel): Observable<ResultModel> {
    const route = "/hotel/inventoryRequestApproval";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  updateService(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateService";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  getBillingInfo(reqModel): Observable<ResultModel> {
    const route = '/hotel/get-billing-info';
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  guestCheckoutPayment(reqModel): Observable<ResultModel> {
    const route = '/hotel/paymentMethod/checkout/fullPayment';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
  deleteService(reqModel): Observable<ResultModel> {
    const route = "/hotel/service/delete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  showServiceListsAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/showServiceLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showServiceLists(reqModel): Observable<ResultModel> {
    const route = "/showServiceLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showServiceIds(reqModel): Observable<ResultModel> {
    const route = "/hotel/showServiceIds";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getHotelRequests(reqModel): Observable<ResultModel> {
    const route = "/hotel/showHotelRequest";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  addPayment(reqModel): Observable<ResultModel> {
    const route = "/hotel/paymentMethod/add";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  deleteAccount(reqModel): Observable<ResultModel> {
    const route = "/hotel/paymentMethod/updatePaymenytConnection";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getPaymentMethod(reqModel): Observable<ResultModel> {
    const route = "/hotel/paymentMethod/paymentMethodDetails";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleServiceAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/showSingleService";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleService(reqModel): Observable<ResultModel> {
    const route = "/showSingleService";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  /************************Tours*****************************/
  addTour(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/add";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  updateTour(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/update";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  showToursListsAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/showToursLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  showToursLists(reqModel): Observable<ResultModel> {
    const route = "/tours/showToursLists";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  showSingleTour(reqModel): Observable<ResultModel> {
    const route = "/tours/showSingleTour";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showSingleTourAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/showSingleTour";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateTourHomeStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/updateTourHomeStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  deleteTour(reqModel): Observable<ResultModel> {
    const route = "/hotel/tour/delete";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  /***********Stripe************/
  addStripe(reqModel): Observable<ResultModel> {
    const route = "/hotel/stripe/add";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  stripeDetail(reqModel): Observable<ResultModel> {
    const route = "/hotel/stripe/stripeDetail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  /**************Get service title***********/
  addServiceTitle(reqModel): Observable<ResultModel> {
    const route = "/hotel/addServiceTitle";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getServiceTitle(reqModel): Observable<ResultModel> {
    const route = "/getServiceTitle";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  updateServiceHomeStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/service/updateServiceHomeStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }

  /**************Get service title***********/
  addTourTitle(reqModel): Observable<ResultModel> {
    const route = "/hotel/tours/addTourTitle";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getTourTitle(reqModel): Observable<ResultModel> {
    const route = "/tours/getTourTitle";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  /*******************Contact US***********************/
  addContact(reqModel): Observable<ResultModel> {
    const route = "/hotel/contact/add";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getContact(reqModel): Observable<ResultModel> {
    const route = "/hotel/contact/get";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  //Department Services
  showServicesDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/services-department/get";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  showRestaurantDepartment(reqModel): Observable<ResultModel> {
    const route = "/hotel/show-restaurant-department";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getHotelName(reqModel): Observable<ResultModel> {
    const route = "/showHotelLists";
     return this.apiService.get<ResultModel>(route, reqModel);
     }

    //  deleteHotel(reqModel): Observable<ResultModel> {
    //   const route = "/singleHotelDelete";
    //  return this.apiService.delete<ResultModel>(route, reqModel);
    //  }


   getGuestUpdatedList(reqModel): Observable<ResultModel> {
      const route = "/check-in/getGuestUpdatedDetails";
       return this.apiService.get<ResultModel>(route, reqModel);
       } 


  getBillingInfoAdminService(reqModel): Observable<ResultModel> {
    const route ="/hotel/get-billing-info";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getRestaurantOrderDetail(reqModel): Observable<ResultModel> {
    const route ="/hotel/orderDetails";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getRestaurantOrderList(reqModel): Observable<ResultModel> {
    const route ="/guest/get-order-list";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  getRestaurantUserDetail(reqModel): Observable<ResultModel> {
    const route ="/guest/getGuestdata";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  
  RestaurantguestSignup(reqModel): Observable<ResultModel> {
    const route = "/guest/guestSignup";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getMessageNotificationCount(reqModel): Observable<ResultModel> {
    const route ="/hotel/get-all-message-count";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getMessageNotificationCountIndividual(reqModel): Observable<ResultModel> {
    const route ="/hotel/get-all-message-count-individual";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  AvailCheckinIdRequest(reqModel){
    const route = "/guest/requestCheckinId";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  getCheckinrequestDetails(reqModel){
    const route ="/guest/getCheckingIdRequestDetails";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  
  getCheckinrequestList(reqModel){
    const route ="/hotel/guest-check-in-list";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
 
  gethotelList(reqModel){
    const route ="/hotel/room-list";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  getHotelTypeList(reqModel){
    const route ="/hotel/room-type-list";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
 
  getCheckinGuestView(reqModel){
    const route ="/hotel/guest-check-in-datail";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  approveRejectGuest(reqModel){
    const route ="/admin/confirmGuestCheckinRequest";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  resendCheckinDetails(reqModel){
    const route ="/admin/resendGuestCheckinDetails";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  pendingCheckinRequestCount(reqModel){
    const route ="/hotel/panding-guest-check-in-count";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  
  
}
