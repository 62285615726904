import { JsonObject, JsonProperty } from "json2typescript";
import {
  IntConverter,
  BooleanConverter,
  NullableString
} from "src/app/core/models/essentials";

@JsonObject("ListAmenitiesIcons")
export class ListAmenitiesIcons {
  @JsonProperty("name", NullableString, true)
  name: string = undefined;
  @JsonProperty("ligature", NullableString, true)
  ligature: string = undefined;
}

@JsonObject("ListAmenitiesIconsModel")
export class ListAmenitiesIconsModel {
  @JsonProperty("ListIcons", [ListAmenitiesIcons], false)
  ListIcons: [ListAmenitiesIcons] = undefined;
}
