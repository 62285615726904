import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResultModel } from "../models/response/base.model";
import { ApiService } from "./api.service";

@Injectable()
export class ManageGalleryService {
  constructor(private apiService: ApiService) {}

  getSingleImageGallery(reqModel): Observable<ResultModel> {
    const route = "/hotel/getSingleImageGallery";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  getImageGalleryAdmin(reqModel): Observable<ResultModel> {
    const route = "/hotel/getImageGallery";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  getImageGallery(reqModel): Observable<ResultModel> {
    const route = "/getImageGallery";
    return this.apiService.get<ResultModel>(route, reqModel);
  }
  addImageGallery(reqModel): Observable<ResultModel> {
    const route = "/hotel/addImageGallery";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  updateImageGallery(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateImageGallery";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  deleteGalleryImage(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteGalleryImage";
    return this.apiService.delete<ResultModel>(route, reqModel);
  }

  updateHomeGalleryStatus(reqModel): Observable<ResultModel> {
    const route = "/hotel/updateHomeGalleryStatus";
    return this.apiService.put<ResultModel>(route, reqModel);
  }
}
