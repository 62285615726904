<div class="thank-you-page">
  <div class="thank-you-img d-flex justify-center">
    <img src="../../../../../../assets/images/thank-you.png" alt="" />
  </div>
  <span class="font-24 font-bold black display-block d-flex justify-center">Thank you</span>
  <span class="font-26 font-bold black display-block d-flex justify-center mt-4">Your service has been booked sucessfully</span>
  <span class="font-26 font-bold black display-block d-flex justify-center mt-4 text-center">You can check your Service status and more in message</span>
  <div class="d-flex justify-center mt-4">
  <!-- <div class="booking-id d-flex justify-content-between">
    <span class="font-16 medium-600 d-flex align-item">Booking ID</span>
    <div class=" d-flex align-item" (click)="openCheckInModal()">
    <span class="font-16 medium-600">{{bookingId}}</span>
    <mat-icon>chevron_right</mat-icon>
  </div>
</div> -->
</div>
<div class="d-flex justify-center mt-4">
<div class="transaction-status d-flex justify-content-between">
  <div class="billing-left-side">
    <span class="font-16 medium-600 black display-block">Booking date</span>
    <span  class="font-16 medium-600 black display-block mt-4">Booking Status</span>
    <!-- <span  class="font-16 medium-600 black display-block mt-4">Transaction ID</span> -->
  </div>
  <div class="billing-right-side">
    <span class="font-16 medium-600 black display-block">{{transactionDate}}</span>
    <span  class="font-16 medium-600 green-color display-block mt-4">{{'Success'}}</span>
    <!-- <span  class="font-16 medium-600 black display-block mt-4">PO521452</span> -->
    </div>
    <div class="dot-design d-flex">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
   </div>
  </div>

  <button class="back-service-btn nunito pointer" (click)="GoToResturantPage()">Go Back to Services</button>

</div>
