<div *ngIf="hotelDetailLocation == 'manage'">
    <!-- room Information -->
    <div class="block-container">
        <div class="space-botom"></div>
        <div class="row">
            <!-- title -->
            <div class="col-lg-12 room-name-info">
                <!-- <p *ngIf="hotelAboutDetailModel.AboutTitle && hotelAboutDetailModel.AboutTitle.length">
                {{ hotelAboutDetailModel.AboutTitle[0].Value }}</p> -->
                <h2 *ngIf="hotelAboutDetailModel.AboutTitle  && hotelAboutDetailModel.AboutTitle.length">
                    {{ hotelAboutDetailModel.AboutTitle[0].Value }}
                </h2>
            </div>
            <!-- //About room -->

            <!-- editor content -->
            <div class="col-lg-12 editor-content-wrapper">
                <div class="ckeditor-front" [innerHTML]="hotelAboutDetailModel.AboutDescription[0].Value| safe: 'html'" *ngIf="hotelAboutDetailModel.AboutDescription  && hotelAboutDetailModel.AboutDescription.length"></div>
            </div>
            <!-- //editor content -->

            <!-- editor content -->
            <!-- <div class="col-lg-12 editor-content-wrapper">
                <div [innerHTML]="hotelAboutDetailModel.AboutDescription[0].Value" *ngIf="hotelAboutDetailModel.AboutDescription  && hotelAboutDetailModel.AboutDescription.length"></div>
            </div> -->
            <!-- //editor content -->
            <!--images -->
            <div class="col-md-5">
                <h4 class="block-title infront-title">{{'Images'|translate}}</h4>
                <ul *ngIf="hotelAboutDetailModel.AboutImage">
                    <img [src]="hotelAboutDetailModel.AboutImage" />
                </ul>
            </div>

            <div class="col-md-7 caption-col-right">
                <h4 class="block-title infront-title">{{'ImagesCaption'|translate}}</h4>
                <div *ngIf="hotelAboutDetailModel.ImagesCaption && hotelAboutDetailModel.ImagesCaption.length">
                    {{ hotelAboutDetailModel.ImagesCaption[0].Value }}
                </div>
            </div>
            <!-- //images -->
        </div>
    </div>
</div>
<div *ngIf="hotelDetailLocation == 'frontView'" class="content-section-outer about-detail-view">
    <!-- Information -->
    <div class="content-wrapper">
        <div class="space-botom"></div>
        <div class="row">
            <!-- Title -->
            <div class="col-lg-8 room-name-info">
                <!-- <p *ngIf="hotelAboutDetailModel.AboutTitle && hotelAboutDetailModel.AboutTitle.length">
                {{ hotelAboutDetailModel.AboutTitle[0].Value }}</p> -->
                <h2 *ngIf="hotelAboutDetailModel.AboutTitle  && hotelAboutDetailModel.AboutTitle.length"  class="primary-text">
                    {{ hotelAboutDetailModel.AboutTitle[0].Value }}
                </h2>
            </div>
            <!-- //Title-->

            <!-- editor content -->
            <div class="col-lg-8 editor-content-wrapper">
                <div class="secondary-text ckeditor-front"[innerHTML]="hotelAboutDetailModel.AboutDescription[0].Value" *ngIf="hotelAboutDetailModel.AboutDescription  && hotelAboutDetailModel.AboutDescription.length"></div>
            </div>
            <!-- //editor content -->

            <!--images -->
            <div class="col-md-4">
                <div class="image-wrapper-inner-bg">
                    <!-- <h4 class="block-title infront-title">{{'Images'|translate}}</h4> -->
                    <div *ngIf="hotelAboutDetailModel.AboutImage">
                        <img [src]="hotelAboutDetailModel.AboutImage" />
                    </div>
                    <!-- <h4 class="block-title infront-title">{{'Imagescaption'|translate}}</h4> -->
                    <div *ngIf="hotelAboutDetailModel.ImagesCaption && hotelAboutDetailModel.ImagesCaption.length" class="about-image-caption">
                        {{ hotelAboutDetailModel.ImagesCaption[0].Value }}
                    </div>
                </div>
            </div>
            <!-- //images -->
        </div>
    </div>
</div>