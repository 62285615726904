<div class="confirmation-popup-service" *ngIf="!this.restockAdmin">
    
    <div class="cancel-btn">
            <i class="material-icons pointer" mat-dialog-close >cancel</i>
        </div>
    <div class="delete-img">
        <img src="../../../../../../assets/images/delete-confirmation.svg" alt="">
    </div>

    <h1 matDialogTitle>Confirmation</h1>
    <span matDialogContent>Are you sure you want to delete.</span>

    <div class="confirmation-btn-grp">
        <button class="cancel-confirmation pointer" mat-dialog-close>No</button>
        <button class="accept-confirmation pointer" [mat-dialog-close]="true">Yes</button>
        
    </div>
</div>

<div class="confirmation-popup-service " *ngIf="this.restockAdmin">

    <i class="material-icons d-block text-right pointer" (click)="closeDialog()">cancel</i>
    <span matDialogContent class="fw-600 lh-30">Are you sure?</span>
    <span class="black font-14 fw-400">You want to save changes</span>

    <div class="confirmation-btn-grp">
        <button class="cancel-confirmation" (click)="closeDialog()" mat-dialog-close>No</button>
        <button class="accept-confirmation" (click)="itemRestockAdmin()">Yes</button>
    </div>
</div>