import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter,BooleanConverter,NullableString } from 'src/app/core/models/essentials';

@JsonObject("ManageBrandingModel")
export class ManageBrandingModel {
    @JsonProperty("Name", NullableString, true) 
    Name: string = undefined;
    @JsonProperty("Location", NullableString, true) 
    Location: string = undefined;
    @JsonProperty("Timezone", NullableString, true) 
    Timezone: string = undefined;
    @JsonProperty("DateFormat", NullableString, true)
    DateFormat: string = undefined;    
    @JsonProperty("TimeFormat", NullableString, true) 
    TimeFormat: string = undefined;
    @JsonProperty("ChooseCurrency", NullableString, true) 
    ChooseCurrency: string = undefined;
    @JsonProperty("CountryCode", NullableString, true) 
    CountryCode: string = '+41';
    @JsonProperty("Logo", NullableString, true)
    Logo: string = undefined;
    @JsonProperty('BackgroundColor', String, true)
    BackgroundColor: string = '#535149';
    @JsonProperty('SelectedBackgroundColor', String, true)
    SelectedBackgroundColor: string = '#Ae9913';
    @JsonProperty('TextColor', String, true)
    TextColor: string = '#Cabf8a';
    @JsonProperty("PrimaryTextColor", NullableString, true)
    PrimaryTextColor: string = '#Ab983c';
    @JsonProperty("SecondaryTextColor", NullableString, true)
    SecondaryTextColor: string = '#535042';
    @JsonProperty("PrimaryButtonBackColor", NullableString, true)
    PrimaryButtonBackColor: string = '#535042';
    @JsonProperty("PrimaryButtonTextColor", NullableString, true)
    PrimaryButtonTextColor: string = '#F7efcb';
    @JsonProperty("SecondaryButtonBackColor", NullableString, true)
    SecondaryButtonBackColor: string = '#A0924f';
    @JsonProperty("SecondaryButtonTextColor", NullableString, true)
    SecondaryButtonTextColor: string = '#F3eccd';
    @JsonProperty("BannerTextColor", NullableString, true)
    BannerTextColor: string = '#Dfe2e3';
    @JsonProperty("FacebookUrl", NullableString, true)
    FacebookUrl: string = undefined;
    @JsonProperty("TwitterUrl", NullableString, true)
    TwitterUrl: string = undefined;
    @JsonProperty("GoogleplusUrl", NullableString, true)
    GoogleplusUrl: string = undefined;
    @JsonProperty("LinkedInUrl", NullableString, true)
    LinkedInUrl: string = undefined;
    @JsonProperty("DefaultLang", NullableString, true) 
    DefaultLang: string = undefined;
    @JsonProperty("Languages",[String], true)
    Languages:string[] = [undefined];
}