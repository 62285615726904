import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter, NullableString,BooleanConverter } from '../essentials';


@JsonObject("HotelLoginModel")
export class HotelLoginModel {
    @JsonProperty("Username", NullableString, true)
    Username: string = undefined;

    @JsonProperty("Password", NullableString, true)
    Password: string = undefined; 

    @JsonProperty("Subdomain", NullableString, true)
    Subdomain: string = undefined;

    @JsonProperty("Secret", NullableString, true)
    Secret: string = undefined;

    @JsonProperty("RememberMe", BooleanConverter, true)
    RememberMe: boolean = undefined;
}
