<div class="manage-service-filter-popup">
    <div class="filter-popup-header">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="nunito font-16 fw-600 black mb-0">Print Order</h2>
            <svg (click)="closeDialog()" class="pointer" width="15" height="15" viewBox="0 0 15 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.29934 7.50781L14.3638 13.5811L13.5728 14.3721L7.49953 8.30762L1.42629 14.3721L0.635273 13.5811L6.69973 7.50781L0.635273 1.43457L1.42629 0.643555L7.49953 6.70801L13.5728 0.643555L14.3638 1.43457L8.29934 7.50781Z"
                    fill="black" />
            </svg>
        </div>
    </div>



</div>
<div class="print-order-wrapper" id="contentToConvert">
    <div class="assigned-staff-wrapper">
        <span class="print-order-labels nunito">Assigned Staff</span>
        <span class="print-order-assignee nunito">{{this.orderDetails?.AssignedStaffNames[0] != null?this.orderDetails.AssignedStaffNames[0]:'Not Assigned'}}</span>
    </div>

    <div class="d-flex align-center justify-content-between print-assigned-wrapper">
        <div class="print-assigned">
            <span class="print-order-labels nunito">Order Id</span>
            <span class="print-order-assignee nunito">{{this.orderDetails?.orderId}}</span>
        </div>
        <div class="print-assigned">
            <span class="print-order-labels nunito">Order No</span>
            <span class="print-order-assignee nunito">{{this.orderDetails?.orderNo}}</span>
        </div>
        <div class="print-assigned">
            <span class="print-order-labels nunito">{{this.orderDetails?.tableNumber.length > 0?'Table No':'Room
                No'}}</span>
            <span class="print-order-assignee nunito"
                *ngIf="this.orderDetails?.tableNumber.length > 0">{{this.orderDetails?.tableNumber}}</span>
            <span class="print-order-assignee nunito"
                *ngIf="this.orderDetails?.roomNumber.length > 0">{{this.orderDetails?.roomNumber}}</span>
        </div>
    </div>

    <div class="print-order-table-wrapper custom-scrollbar">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef> Items </th>
                <td mat-cell *matCellDef="let element"> {{element.item}} </td>
            </ng-container>

            <ng-container matColumnDef="qty">
                <th mat-header-cell *matHeaderCellDef> Quantity </th>
                <td mat-cell *matCellDef="let element"> {{element.qty}} </td>
            </ng-container>

            <tr mat-header-row class="font-16 nunito" *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="font-14 nunito" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
      

</div>

<div class="print-button-wrapper" >
    <button class="print-button nunito pointer" (click)="closeDialog()">Cancel</button>
    <button class="print-button nunito pointer" (click)="convetToPDF()">Print</button>
</div>