import { Any, JsonObject, JsonProperty } from "json2typescript";
import {
  IntConverter,
  BooleanConverter,
  NullableString,
  ObjectConverter,
  FloatConverter
} from "src/app/core/models/essentials";

export class CommonRequestResponse {}

/*****Lang Model */
@JsonObject("CommonLangModel")
export class CommonLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

/***********************/
/** *Hotel Guide**************************************************************************************/
/***********************/
//Request-------------------------------------------------
@JsonObject("HotelGuideRequest")
export class HotelGuideRequest {
  @JsonProperty("TitleGuide", NullableString, true)
  TitleGuide: string = undefined;

  @JsonProperty("HotelGuideDescription", NullableString, true)
  HotelGuideDescription: string = undefined;

  @JsonProperty("Pdf", NullableString, true)
  Pdf: string = undefined;

  @JsonProperty("FileName", NullableString, true)
  FileName: string = undefined;

  @JsonProperty("GuideOrder", IntConverter, true)
  GuideOrder: Number = undefined;
}

//Response-------------------------------------------------

@JsonObject("TitleGuideLangModel")
export class TitleGuideLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("HotelGuideDescriptionLangModel")
export class HotelGuideDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("HotelGuideRequestLang")
export class HotelGuideRequestLang {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;

  @JsonProperty("TitleGuide", [TitleGuideLangModel], true)
  TitleGuide: [TitleGuideLangModel] = undefined;

  @JsonProperty("HotelGuideDescription", [HotelGuideDescriptionLangModel], true)
  HotelGuideDescription: [HotelGuideDescriptionLangModel] = undefined;

  @JsonProperty("Pdf", NullableString, true)
  Pdf: string = undefined;

  @JsonProperty("FileName", NullableString, true)
  FileName: string = undefined;

  @JsonProperty("GuideOrder", IntConverter, true)
  GuideOrder: Number = undefined;
}
@JsonObject("HotelGuideListsArray")
export class HotelGuideListsArray {
  @JsonProperty("HotelGuideLists", [HotelGuideRequestLang], false)
  HotelGuideLists: HotelGuideRequestLang[] = undefined; //In order to assign empty Array
}

/***********************/
/*******Amenties*********************************************************************************/
/***********************/

@JsonObject("AmenitiesLangsModel")
export class AmenitiesLangsModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("AmentiesModel")
export class AmentiesModel {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;
  @JsonProperty("AmenitiesLangs", [AmenitiesLangsModel], false)
  AmenitiesLangs: [AmenitiesLangsModel] = undefined;

  @JsonProperty("AmenitiesIcon", NullableString, true)
  AmenitiesIcon: string = undefined;
}

/***********************/
/*******Room Title Model*********************************************************************************/
/***********************/
@JsonObject("HotelRoomTitleLangModel")
export class HotelRoomTitleLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("RoomTitle", NullableString, true)
  RoomTitle: string = undefined;
  @JsonProperty("RoomButtonText", NullableString, true)
  RoomButtonText: string = undefined;
}

@JsonObject("HotelRoomTitleModel")
export class HotelRoomTitleModel {
  @JsonProperty("HotelRoomTitleLang", [HotelRoomTitleLangModel], true)
  HotelRoomTitleLang: [HotelRoomTitleLangModel] = undefined;
  @JsonProperty("RoomButtonType", NullableString, true)
  RoomButtonType: string = undefined;
}

/***********************/
/*******Resturant Title Model*********************************************************************************/
/***********************/
@JsonObject("HotelResturantTitleLangModel")
export class HotelResturantTitleLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("ResturantTitle", NullableString, true)
  ResturantTitle: string = undefined;
  @JsonProperty("ResturantButtonText", NullableString, true)
  ResturantButtonText: string = undefined;
}

@JsonObject("HotelResturantTitleModel")
export class HotelResturantTitleModel {
  @JsonProperty("HotelResturantTitleLang", [HotelResturantTitleLangModel], true)
  HotelResturantTitleLang: HotelResturantTitleLangModel[] = undefined;
  @JsonProperty("ResturantButtonType", NullableString, true)
  ResturantButtonType: string = undefined;
}

/***********************/
/*******Hotel About Detail Model*********************************************************************************/
/***********************/

@JsonObject("ImageCaptionLangModel")
export class ImageCaptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("AboutDescriptionLangModel")
export class AboutDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("AboutTitleLangModel")
export class AboutTitleLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("HotelAboutDetailModel")
export class HotelAboutDetailModel {
  @JsonProperty("_id", NullableString, true)
  id: any = undefined;
  @JsonProperty("AboutTitle", [AboutTitleLangModel], true)
  AboutTitle: [AboutTitleLangModel] = undefined;
  @JsonProperty("AboutDescription", [AboutDescriptionLangModel], true)
  AboutDescription: [AboutDescriptionLangModel] = undefined;
  @JsonProperty("ImagesCaption", [ImageCaptionLangModel], true)
  ImagesCaption: [ImageCaptionLangModel] = undefined;
  @JsonProperty("AboutImage", NullableString, true)
  AboutImage: string = undefined;
}

////Request Model/////////////////////////////////
@JsonObject("HotelAboutRequestModel")
export class HotelAboutRequestModel {
  @JsonProperty("AboutTitle", NullableString, true)
  AboutTitle: string = undefined;
  @JsonProperty("AboutDescription", NullableString, true)
  AboutDescription: string = undefined;
  @JsonProperty("ImagesCaption", NullableString, true)
  ImagesCaption: string = undefined;
  @JsonProperty("AboutImage", NullableString, true)
  AboutImage: string = undefined;
}

/***********************/
/*******Room Type Model*********************************************************************************/
/***********************/
@JsonObject("RoomTypeModel")
export class RoomTypeModel {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("name", NullableString, true)
  name: string = undefined;
}

@JsonObject("RoomTypeArrayModel")
export class RoomTypeArrayModel {
  @JsonProperty("RoomTypeLists", [RoomTypeModel], true)
  RoomTypeLists: RoomTypeModel[] = undefined;
}

@JsonObject("RoomTypeLangValue")
export class RoomTypeLangValue {
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

//Response Model
@JsonObject("RoomTypeLangsResponseModel")
export class RoomTypeLangsResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("RoomTypeLangs", [RoomTypeLangValue], true)
  RoomTypeLangs: RoomTypeLangValue[] = undefined;
}

/***********************/
/*******Resturant Type Model*********************************************************************************/
/***********************/
@JsonObject("ResturantTypeModel")
export class ResturantTypeModel {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("value", NullableString, true)
  value: string = undefined;
}

@JsonObject("ResturantTypeArrayModel")
export class ResturantTypeArrayModel {
  @JsonProperty("ResturantTypeLists", [ResturantTypeModel], true)
  ResturantTypeLists: ResturantTypeModel[] = undefined;
}

//Response Model
@JsonObject("ResturantTypeLangValue")
export class ResturantTypeLangValue {
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ResturantTypeLangsResponseModel")
export class ResturantTypeLangsResponseModel {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;
  @JsonProperty("ResturantTypeLangs", [ResturantTypeLangValue], true)
  ResturantTypeLangs: ResturantTypeLangValue[] = undefined;
}

/***********************/
/*******Admin Permission List Model*********************************************************************************/
/***********************/

@JsonObject("AdminPermissionListLangModel")
export class AdminPermissionListLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("AdminPermissionListModel")
export class AdminPermissionListModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Name", [AdminPermissionListLangModel], true)
  Name: [AdminPermissionListLangModel] = undefined;
  @JsonProperty("NameKey", NullableString, true)
  NameKey: string = undefined;
  @JsonProperty("Permissions", Object, true)
  Permissions: Object = undefined;
}

@JsonObject("PermissionModel")
export class PermissionModel {
  @JsonProperty("full", BooleanConverter, true)
  full: boolean = undefined;
  @JsonProperty("manage", BooleanConverter, true)
  manage: boolean = undefined;
  @JsonProperty("viewOnly", BooleanConverter, true)
  viewOnly: boolean = undefined;
  @JsonProperty("bookOnly", BooleanConverter, true)
  bookOnly: boolean = undefined;
}

@JsonObject("RoleNameLangModel")
export class RoleNameLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("User_infosModel")
export class User_infosModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("PhoneNumber", NullableString, true)
  PhoneNumber: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
}

@JsonObject("RoleListModel")
export class RoleListModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("RoleName", [RoleNameLangModel], true)
  RoleName: [RoleNameLangModel] = undefined;
  @JsonProperty("user_infos", [User_infosModel], true)
  user_infos: [User_infosModel] = undefined;
}

@JsonObject("RoleListArrayModel")
export class RoleListArrayModel {
  @JsonProperty("RoleListArray", [RoleListModel], true)
  RoleListArray: [RoleListModel] = undefined;
}

@JsonObject("AdminPermissionResponseModel")
export class AdminPermissionResponseModel {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("nameKey", NullableString, true)
  nameKey: string = undefined;
  @JsonProperty("permissions", [Object], true)
  permissions: [Object] = undefined;
}

/*******Department Permission List*************/
@JsonObject("DepartmentPermissionListLangModel")
export class DepartmentPermissionListLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("DepartmentPermissionModel")
export class DepartmentPermissionModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Department", [DepartmentPermissionListLangModel], true)
  Name: [DepartmentPermissionListLangModel] = undefined;
  // @JsonProperty("NameKey", NullableString, true)
  // NameKey: string = undefined;
  @JsonProperty("Permissions", Object, true)
  Permissions: Object = undefined;
}

@JsonObject("DepartmentPermissionArrayModel")
export class DepartmentPermissionArrayModel {
  @JsonProperty("DepartmentPermissionArray", [DepartmentPermissionModel], true)
  DepartmentPermissionArray: [DepartmentPermissionModel] = undefined;
}

/*******Staff************************/
@JsonObject("StaffRequestModel")
export class StaffRequestModel {
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("DepartmentId", NullableString, true)
  DepartmentId: string = undefined;
  @JsonProperty("RoleId", NullableString, true)
  RoleId: string = undefined;
  @JsonProperty("Designation", NullableString, true)
  Designation: string = undefined;
  @JsonProperty("Email", NullableString, true)
  Email: string = undefined;
  @JsonProperty("CountryCode", NullableString, true)
  CountryCode: string = undefined;
  @JsonProperty("PhoneNumber", NullableString, true)
  PhoneNumber: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
  @JsonProperty("CreateAccount", BooleanConverter, true)
  CreateAccount: boolean = undefined;
  @JsonProperty("ValidFrom", NullableString, true)
  ValidFrom: string = undefined;
  @JsonProperty("ValidTill", NullableString, true)
  ValidTill: string = undefined;
  
  
}

/*******Staff Response Model************************/
@JsonObject("StaffResponseModel")
export class StaffResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("DepartmentId", Any, true)
  DepartmentId: any = undefined;
  @JsonProperty("RoleId", Any, true)
  RoleId: any = undefined;
  @JsonProperty("Designation", NullableString, true)
  Designation: string = undefined;
  @JsonProperty("Username", NullableString, true)
  Email: string = undefined;
  @JsonProperty("CountryCode", NullableString, true)
  CountryCode: string = undefined;
  @JsonProperty("PhoneNumber", NullableString, true)
  PhoneNumber: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
  @JsonProperty("UserStatus", NullableString, true)
  UserStatus: string = undefined;
  @JsonProperty("ValidFrom", NullableString, true)
  ValidFrom: string = undefined;
  @JsonProperty("ValidTill", NullableString, true)
  ValidTill: string = undefined;
  @JsonProperty("IsEmailVerified", BooleanConverter, true)
  IsEmailVerified: boolean = undefined;
  @JsonProperty("IsPhoneNumberVerified", BooleanConverter, true)
  IsPhoneNumberVerified: boolean = undefined;
}

@JsonObject("StaffListArrayModel")
export class StaffListArrayModel {
  @JsonProperty("StaffListArray", [StaffResponseModel], true)
  StaffListArray: [StaffResponseModel] = undefined;
}

@JsonObject("StaffResponsePopupModel")
export class StaffResponsePopupModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
}

/*******Guest Permission Model************************/
@JsonObject("LangModel")
export class LangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("GuestPermissionResponseModel")
export class GuestPermissionResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Name", [LangModel], true)
  Name: [LangModel] = undefined;
  @JsonProperty("NameKey", NullableString, true)
  NameKey: string = undefined;
  @JsonProperty("Permissions", Object, true)
  Permissions: Object = undefined;
}

/*****Date **************************/
@JsonObject("DateModel")
export class DateModel {
  @JsonProperty("DateFrom", NullableString, true)
  DateFrom: string = undefined;
  @JsonProperty("DateTo", NullableString, true)
  DateTo: string = undefined;
  @JsonProperty("TimeFrom", NullableString, true)
  TimeFrom: string = undefined;
  @JsonProperty("TimeTo", NullableString, true)
  TimeTo: string = undefined;
}

@JsonObject("DaysModel")
export class DaysModel {
  @JsonProperty("Monday", BooleanConverter, true)
  Monday: boolean = undefined;
  @JsonProperty("Tuesday", BooleanConverter, true)
  DateTuesdayTo: boolean = undefined;
  @JsonProperty("Wednesday", BooleanConverter, true)
  Wednesday: boolean = undefined;
  @JsonProperty("Thursday", BooleanConverter, true)
  Thursday: boolean = undefined;
  @JsonProperty("Friday", BooleanConverter, true)
  Friday: boolean = undefined;
  @JsonProperty("Saturday", BooleanConverter, true)
  Saturday: boolean = undefined;
  @JsonProperty("Sunday", BooleanConverter, true)
  Sunday: boolean = undefined;
}

@JsonObject("DaysTimeModel")
export class DaysTimeModel {
  @JsonProperty("DaysNames", [DaysModel], true)
  DaysNames: DaysModel[] = undefined;
  @JsonProperty("FromTime", NullableString, true)
  FromTime: string = undefined;
  @JsonProperty("ToTime", NullableString, true)
  ToTime: string = undefined;
}

/*******Department Response ************************/
@JsonObject("DepartmentLangModel")
export class DepartmentLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("StaffsListModel")
export class StaffsListModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
}

@JsonObject("ManagersListModel")
export class ManagersListModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("FirstName", NullableString, true)
  FirstName: string = undefined;
  @JsonProperty("LastName", NullableString, true)
  LastName: string = undefined;
  @JsonProperty("ProfileImage", NullableString, true)
  ProfileImage: string = undefined;
}

@JsonObject("DepartmentResponseModel")
export class DepartmentResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Department", [DepartmentLangModel], true)
  Department: [DepartmentLangModel] = undefined;
  @JsonProperty("resturantManegement", BooleanConverter, true)
  resturantManegement: boolean = undefined;
  @JsonProperty("ResturantId", NullableString, true)
  ResturantId: string = undefined;
  @JsonProperty("Staffs", [], true)
  Staffs: [] = undefined;
  @JsonProperty("Managers", [ManagersListModel], true)
  Managers: [ManagersListModel] = undefined;
  @JsonProperty("Restaurant", [], true)
  Restaurant: [] = undefined; // Changed from array syntax to correct format

  @JsonProperty("Service", [], true)
  Service: [] = undefined; //
}

@JsonObject("DepartmentAddResponseModel")
export class DepartmentAddResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Department", [DepartmentLangModel], true)
  Department: [DepartmentLangModel] = undefined;
}

@JsonObject("DepartmentListsResponseModel")
export class DepartmentListsResponseModel {
  @JsonProperty("DepartmentPermissionArray", [DepartmentResponseModel], true)
  DepartmentPermissionArray: [DepartmentResponseModel] = undefined;
}

/*******Department  Single Response ************************/

// @JsonObject
// export class DepartmentSingleResponseModel {
//   @JsonProperty("_id", NullableString, true)
//   id: string = undefined;
//   @JsonProperty("Department", [DepartmentLangModel], true)
//   Department: [DepartmentLangModel] = undefined;
//   @JsonProperty("Staffs", [String], true)
//   Staffs: string[] = undefined;
//   @JsonProperty("Managers", [String], true)
//   Managers: string[] = undefined;
// }

/***********Inclusion/Exclusion***************/
@JsonObject("InclusionLangsModel")
export class InclusionLangsModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("InclusionModel")
export class InclusionModel {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;
  @JsonProperty("InclusionLangs", [InclusionLangsModel], false)
  InclusionLangs: [InclusionLangsModel] = undefined;
}

@JsonObject("InclusionFormat")
export class InclusionFormat {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("value", NullableString, false)
  value: string = undefined;
}
@JsonObject("InclusionResponseModel")
export class InclusionResponseModel {
  @JsonProperty("InclusionResponseArray", [InclusionFormat], true)
  InclusionResponseArray: InclusionFormat[] = []; }

/***********Service***************/

@JsonObject("ServiceLangModel")
export class ServiceLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ServiceDescriptionLangModel")
export class ServiceDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ServiceDepartmentLangModel")
export class ServiceDepartmentLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ServiceDepartmentModel")
export class ServiceDepartmentModel {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Department", [ServiceDepartmentLangModel], true)
  Department: [ServiceDepartmentLangModel] = undefined;
}

@JsonObject("ServiceRequestModel")
export class ServiceRequestModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("ServiceType", NullableString, true)
  ServiceType: string = undefined;
  @JsonProperty("ServiceName", NullableString, true)
  ServiceName: string = undefined;
  @JsonProperty("ServiceItemMenu", NullableString, true)
  ServiceItemMenu: string[] = undefined;
  @JsonProperty("Department", NullableString, true)
  Department: string = undefined;
  @JsonProperty("ServiceDescription", NullableString, true)
  ServiceDescription: string = undefined;
  @JsonProperty("Inclusions", [String], true)
  Inclusions: string[] = undefined;
  @JsonProperty("Exclusions", [String], true)
  Exclusions: string[] = undefined;
  @JsonProperty("CheckedInGuestOnly", BooleanConverter, true)
  CheckedInGuestOnly: boolean = undefined;

  // @JsonProperty("IsQuantityRequired", BooleanConverter, true)
  // IsQuantityRequired: boolean = undefined;
  @JsonProperty("InternalService", BooleanConverter, true)
  InternalService: boolean = undefined;
  @JsonProperty("IsTimeRequired", BooleanConverter, true)
  IsTimeRequired: boolean = undefined;
  @JsonProperty("PriceToggle", BooleanConverter, true)
  PriceToggle: boolean = undefined;
  @JsonProperty("PaidPrice", NullableString, true)
  PaidPrice: string = undefined;
  @JsonProperty("DaysToggle", BooleanConverter, true)
  DaysToggle: boolean = undefined;
  @JsonProperty("DateTime", IntConverter, true)
  DateTime: Number = undefined;
  @JsonProperty("ServiceAvailability", [Object], true)
  ServiceAvailability: object[] = undefined;
  @JsonProperty("ServiceImages", [String], true)
  ServiceImages: [String] = undefined;
  @JsonProperty("ShowOnHomePage", BooleanConverter, true)
  ShowOnHomePage: boolean = undefined;
  @JsonProperty("HotService", BooleanConverter, true)
  HotService: boolean = undefined;
  @JsonProperty("Pdf", NullableString, true)
  Pdf: string = undefined;
  @JsonProperty("TaxCountryId", NullableString, true)
  TaxCountryId: string = undefined;
  
}

@JsonObject("ServiceResponseModel")
export class ServiceResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("ServiceType", NullableString, true)
  ServiceType: string = undefined;
  @JsonProperty("ServiceName", [ServiceLangModel], true)
  ServiceName: [ServiceLangModel] = undefined;
  @JsonProperty("Department", ObjectConverter, true)
  Department: any = undefined;
  @JsonProperty("DepartmentId", NullableString, true)
  DepartmentId: string = undefined;
  @JsonProperty("ServiceDescription", [ServiceDescriptionLangModel], true)
  ServiceDescription: [ServiceDescriptionLangModel] = undefined;
  @JsonProperty("Inclusions", [InclusionModel], true)
  Inclusions: [InclusionModel] = undefined;
  @JsonProperty("Exclusions", [InclusionModel], true)
  Exclusions: [InclusionModel] = undefined;
  @JsonProperty("CheckedInGuestOnly", BooleanConverter, true)
  CheckedInGuestOnly: boolean = undefined;
  // @JsonProperty("IsQuantityRequired", BooleanConverter, true)
  // IsQuantityRequired: boolean = undefined;
  @JsonProperty("InternalService", BooleanConverter, true)
  InternalService: boolean = false;
  @JsonProperty("IsTimeRequired", BooleanConverter, true)
  IsTimeRequired: boolean = undefined;
  @JsonProperty("PriceToggle", BooleanConverter, true)
  PriceToggle: boolean = undefined;
  @JsonProperty("PaidPrice", NullableString, true)
  PaidPrice: string = undefined;
  @JsonProperty("DaysToggle", BooleanConverter, true)
  DaysToggle: boolean = undefined;
  @JsonProperty("DateTime", IntConverter, true)
  DateTime: Number = undefined;
  @JsonProperty("ServiceAvailability", ObjectConverter, true)
  ServiceAvailability: object[] = undefined;
  @JsonProperty("ServiceImages", [String], true)
  ServiceImages: [String] = undefined;
  @JsonProperty("ShowOnHomePage", BooleanConverter, true)
  ShowOnHomePage: boolean = undefined;
  @JsonProperty("HotService", BooleanConverter, true)
  HotService: boolean = undefined;
  @JsonProperty("Reviews", ObjectConverter, true)
  Reviews: object[] = undefined;
  @JsonProperty("Pdf", NullableString, true)
  Pdf: string = undefined;
  @JsonProperty("ServiceItemMenu", [], true)
  ServiceItemMenu: [] = undefined;
  @JsonProperty("TaxCountryId", NullableString, true)
  TaxCountryId: string = undefined;
  @JsonProperty("DefaultTaxType", NullableString, true)
  DefaultTaxType: string = undefined;
}

@JsonObject("ServiceResponseArrayModel")
export class ServiceResponseArrayModel {
  @JsonProperty("ServiceLists", [ServiceResponseModel], false)
  ServiceLists: [ServiceResponseModel] = undefined;

  @JsonProperty("CountLists", IntConverter, true)
  CountLists: number = undefined;
}

@JsonObject("TourLangModel")
export class TourLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("TourDescriptionLangModel")
export class TourDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ResponseDepartmentLangModel")
export class ResponseDepartmentLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("ResponseDepartmentModel")
export class ResponseDepartmentModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("Department", [ServiceDepartmentLangModel], true)
  Department: [ServiceDepartmentLangModel] = undefined;
}

@JsonObject("TourRequestModel")
export class TourRequestModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("TourType", NullableString, true)
  TourType: string = undefined;
  @JsonProperty("TourName", NullableString, true)
  TourName: string = undefined;
  @JsonProperty("Department", NullableString, true)
  Department: string = undefined;
  @JsonProperty("TourDescription", NullableString, true)
  TourDescription: string = undefined;
  @JsonProperty("CheckedInGuestOnly", BooleanConverter, true)
  CheckedInGuestOnly: boolean = undefined;
  @JsonProperty("PriceToggle", BooleanConverter, true)
  PriceToggle: boolean = undefined;
  @JsonProperty("PaidPrice", NullableString, true)
  PaidPrice: string = undefined;
  @JsonProperty("DaysToggle", BooleanConverter, true)
  DaysToggle: boolean = undefined;
  @JsonProperty("DateTime", IntConverter, true)
  DateTime: Number = undefined;
  @JsonProperty("TourAvailability", [Object], true)
  TourAvailability: object[] = undefined;
  @JsonProperty("TourImages", [String], true)
  TourImages: [String] = undefined;
  @JsonProperty("ShowOnHomePage", BooleanConverter, true)
  ShowOnHomePage: boolean = undefined;
  @JsonProperty("TourItemMenu", NullableString, true)
  TourItemMenu: string[] = undefined;
}
@JsonObject("TourResponseModel")
export class TourResponseModel {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("TourType", NullableString, true)
  TourType: string = undefined;
  @JsonProperty("TourName", [TourLangModel], true)
  TourName: [TourLangModel] = undefined;
  @JsonProperty("Department", ObjectConverter, true)
  Department: object[] = undefined;
  @JsonProperty("Location", NullableString, true)
  Location: string = undefined;
  @JsonProperty("LocationLatLng", ObjectConverter, true)
  LocationLatLng: object[] = undefined;
  @JsonProperty("TourDescription", [TourDescriptionLangModel], true)
  TourDescription: [TourDescriptionLangModel] = undefined;
  @JsonProperty("CheckedInGuestOnly", BooleanConverter, true)
  CheckedInGuestOnly: boolean = undefined;
  @JsonProperty("PriceToggle", BooleanConverter, true)
  PriceToggle: boolean = undefined;
  @JsonProperty("PaidPrice", NullableString, true)
  PaidPrice: string = undefined;
  @JsonProperty("DaysToggle", BooleanConverter, true)
  DaysToggle: boolean = undefined;
  @JsonProperty("DateTime", IntConverter, true)
  DateTime: Number = undefined;
  @JsonProperty("TourAvailability", ObjectConverter, true)
  TourAvailability: object[] = undefined;
  @JsonProperty("TourImages", [String], true)
  TourImages: [String] = undefined;
  @JsonProperty("ShowOnHomePage", BooleanConverter, true)
  ShowOnHomePage: boolean = undefined;
  @JsonProperty("TourItemMenu", [], true)
  TourItemMenu: [] = undefined;
}
@JsonObject("TourResponseArrayModel")
export class TourResponseArrayModel {
  @JsonProperty("TourLists", [TourResponseModel], false)
  TourLists: [TourResponseModel] = undefined;

  @JsonProperty("CountLists", IntConverter, true)
  CountLists: number = undefined;
}

/**********Payment Request***************/
@JsonObject("PaymentResponseModel")
export class PaymentResponseModel {
  @JsonProperty("PublishableKey", NullableString, true)
  PublishableKey: string = undefined;
  @JsonProperty("Secret", NullableString, true)
  Secret: string = undefined;
  @JsonProperty("AccountId", NullableString, true)
  AccountId: string = undefined;
  @JsonProperty("Connection", BooleanConverter, true)
  Connection: boolean = undefined;
}

/*****Map Lat Lng**************************/
@JsonObject("GoogleMapParamModel")
export class GoogleMapParamModel {
  @JsonProperty("Lat", FloatConverter, true)
  Lat: any = undefined;
  @JsonProperty("Lng", FloatConverter, true)
  Lng: any = undefined;
}

/***********************/
/*******Service Title Model*********************************************************************************/
/***********************/
@JsonObject("HotelServiceTitleLangModel")
export class HotelServiceTitleLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("ServiceTitle", NullableString, true)
  ServiceTitle: string = undefined;
  @JsonProperty("ServiceButtonText", NullableString, true)
  ServiceButtonText: string = undefined;
}

@JsonObject("HotelServiceTitleModel")
export class HotelServiceTitleModel {
  @JsonProperty("HotelServiceTitleLang", [HotelServiceTitleLangModel], true)
  HotelServiceTitleLang: HotelServiceTitleLangModel[] = undefined;
  @JsonProperty("ServiceButtonType", NullableString, true)
  ServiceButtonType: string = undefined;
}

/***********************/
/*******Tour Title Model*********************************************************************************/
/***********************/
@JsonObject("HotelTourTitleLangModel")
export class HotelTourTitleLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("TourTitle", NullableString, true)
  TourTitle: string = undefined;
  @JsonProperty("TourButtonText", NullableString, true)
  TourButtonText: string = undefined;
}

@JsonObject("HotelTourTitleModel")
export class HotelTourTitleModel {
  @JsonProperty("HotelTourTitleLang", [HotelTourTitleLangModel], true)
  HotelTourTitleLang: HotelTourTitleLangModel[] = undefined;
  @JsonProperty("TourButtonType", NullableString, true)
  TourButtonType: string = undefined;
}

/***********************/
/*******ContactUs*********************************************************************************/
/***********************/
@JsonObject("HotelContactUsModel")
export class HotelContactUsModel {
  @JsonProperty("Title", [CommonLangModel], true)
  Title: CommonLangModel[] = undefined;
  @JsonProperty("Latitude", FloatConverter, true)
  Latitude: any = undefined;
  @JsonProperty("Longitude", FloatConverter, true)
  Longitude: any = undefined;
  @JsonProperty("Location", NullableString, true)
  Location: string = undefined;
  @JsonProperty("Description", [CommonLangModel], true)
  Description:  CommonLangModel[] = undefined;
  @JsonProperty("Email", NullableString, true)
  Email: string = undefined;
  @JsonProperty("CountryCode", NullableString, true)
  CountryCode: string = undefined;
  @JsonProperty("Phone", NullableString, true)
  Phone: string = undefined;
}

/***********************/
/*******Stripe*********************************************************************************/
/***********************/
@JsonObject("StripeResponseModel")
export class StripeResponseModel {
  @JsonProperty("id", NullableString, true)
  id: string = undefined;
  @JsonProperty("object", NullableString, true)
  object: string = undefined;
  @JsonProperty("email", NullableString, true)
  email: string = undefined;
  @JsonProperty("payouts_enabled", NullableString, true)
  payouts_enabled: any = undefined;
}

/***********************/
/*******ServiceDepartment************************************************************************/
/***********************/
@JsonObject("ServiceDepartmentList")
export class ServiceDepartmentList {
  @JsonProperty("_id", NullableString, true)
  id: string = undefined;
  @JsonProperty("ServiceName", [ServiceLangModel], true)
  ServiceName: [ServiceLangModel] = undefined;
  @JsonProperty("CreatedAt", NullableString, true)
  CreatedAt: string = undefined;
}

@JsonObject("ServiceDepartmentListArray")
export class ServiceDepartmentListArray {
  @JsonProperty("ServiceResponseArray", [ServiceDepartmentList], true)
  ServiceResponseArray: [ServiceDepartmentList] = undefined;
}

/***********************/
/*******Restaurant Department************************************************************************/
/***********************/

@JsonObject("ResturantNameLangModel")
export class ResturantNameLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}
@JsonObject("ResturantResponse")
export class ResturantResponse {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;
  @JsonProperty("ResturantNameLangs", [ResturantNameLangModel], true)
  ResturantNameLangs: [ResturantNameLangModel] = undefined;
  @JsonProperty("CreatedAt", NullableString, true)
  CreatedAt: string = undefined;
}

@JsonObject("ResturantDepartmentListArray")
export class ResturantDepartmentListArray {
  @JsonProperty("RestaurantResponseArray", [ResturantResponse], true)
  RestaurantResponseArray: [ResturantResponse] = undefined;
}

/***********************/
/*******Check-In Preference************************************************************************/
/***********************/

@JsonObject("CheckinPreferenceResponseModel")
export class CheckinPreferenceResponseModel {
  @JsonProperty("PreCheckin", IntConverter, true)
  PreCheckin: number = undefined;
  @JsonProperty("PostCheckout", IntConverter, true)
  PostCheckout: number = undefined;
  @JsonProperty("PickUpService", BooleanConverter, true)
  PickUpService: boolean = undefined;
  @JsonProperty("LateCheckOut",NullableString, true)
  LateCheckOut: string = undefined;
  @JsonProperty("Parking", BooleanConverter, true)
  Parking: boolean = undefined;
  @JsonProperty("Bed", BooleanConverter, true)
  Bed: boolean = undefined;
  @JsonProperty("Allergy", BooleanConverter, true)
  Allergy: boolean = undefined;
  @JsonProperty("SpecialMenu", BooleanConverter, true)
  SpecialMenu: boolean = undefined;
  @JsonProperty("SpecialRequest", BooleanConverter, true)
  SpecialRequest: boolean = undefined;
  @JsonProperty("BeforeStayArray", ObjectConverter, true)
  BeforeStayArray: object[] = undefined;
  @JsonProperty("WakeUpCall", BooleanConverter, true)
  WakeUpCall: boolean = undefined;
  @JsonProperty("RoomCleaning", BooleanConverter, true)
  RoomCleaning: boolean = undefined;
  @JsonProperty("MenuExtras", BooleanConverter, true)
  MenuExtras: boolean = undefined;
  @JsonProperty("DuringStayArray", ObjectConverter, true)
  DuringStayArray: object[] = undefined;
  @JsonProperty("Surveys", BooleanConverter, true)
  Surveys: boolean = undefined;
  @JsonProperty("Souvenirs", BooleanConverter, true)
  Souvenirs: boolean = undefined;
  @JsonProperty("Reviews", BooleanConverter, true)
  Reviews: boolean = undefined;
  @JsonProperty("AfterStayArray", ObjectConverter, true)
  AfterStayArray: object[] = undefined;
}
