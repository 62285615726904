<div class="image-large-view">
  <button class="material-icons close-popup" mat-dialog-close>close</button>
  <!-- <slideshow #slideshow [imageUrls]="imageUrlArray" [height]="height" [minHeight]="minHeight" [arrowSize]="arrowSize" [showArrows]="showArrows" [disableSwiping]="disableSwiping" [autoPlay]="autoPlay" [stopAutoPlayOnSlide]="stopAutoPlayOnSlide" [backgroundSize]="backgroundSize"
      [backgroundPosition]="backgroundPosition" [backgroundRepeat]="backgroundRepeat" [showDots]="showDots" [dotColor]="dotColor" [showCaptions]="showCaptions" [captionColor]="captionColor" [captionBackground]="captionBackground" [lazyLoad]="lazyLoad" [hideOnNoSlides]="hideOnNoSlides"
      [fullscreen]="fullscreen" (onFullscreenExit)="onFullscreenExit($event)">
  </slideshow> -->
  <ngb-carousel *ngIf="imageUrlArray.length > 2;else Other_carousel">
      <ng-template ngbSlide *ngFor="let image of imageUrlArray">
          <div class="imageWrapper"><img [src]="image.url"></div>
      </ng-template>
  </ngb-carousel>

  <ng-template #Other_carousel>
      <ngb-carousel *ngIf="imageUrlArray.length" class="single-image">
          <ng-template ngbSlide *ngFor="let image of imageUrlArray">
              <div class="imageWrapper"><img [src]="image.url"></div>
          </ng-template>
      </ngb-carousel>
      <!-- <owl-carousel-o [options]="customOptions" class="single-image">
          <ng-container *ngFor="let image of imageUrlArray; let i = index">
            <ng-template carouselSlide>
              <img [src]="image.url" [alt]="'Slide ' + (i + 1)" title="Image {{ i + 1 }}">
            </ng-template>
          </ng-container>
       </owl-carousel-o> -->
  </ng-template>
</div>