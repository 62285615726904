import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  langSaveProperty: string = "en";
  langSaveValue: string = "English";
  hotelLangTranslate: string = "en";
  userLangTranslate: string = "en";
  specialCharacterPattern: string = "^[^`~!@#$%^&*()_+={}|[]\\:';]*$";
  S3Url: string = "https://cityhotelprofile.s3.amazonaws.com/";
  languageList: LanguageClass[] = [
    { key: "en", value: "English" },
    { key: "fr", value: "French" },
    { key: "de", value: "German" },
    { key: "it", value: "Italian" },
    { key: "es", value: "Spanish" }
  ];
  DateFormat: string = "DD/MM/YYYY";
  TimeFormat: string = "hh:mm tt";
  Timezone: string = "Asia/Calcutta";
  timeFormat() {
    if (window.localStorage["TimeFormat"]) {
      return window.localStorage["TimeFormat"];
    } else {
      return this.TimeFormat;
    }
  }
  dateFormat() {
    if (window.localStorage["DateFormat"]) {
      return window.localStorage["DateFormat"];
    } else {
      return this.DateFormat;
    }
  }
}

export class LanguageClass {
  key: string;
  value: string;
}
