import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { MasterService } from "../../services/master.service";
import { GlobalService } from "../../services/global.service";
import { HotelAboutDetailModel } from "../../models/common-request-response";
import { ManageBrandingModel } from "../../models/request/manageBranding.model";

@Component({
  selector: "app-about-hotel-detail-page",
  templateUrl: "./about-hotel-detail-page.component.html",
  styleUrls: ["./about-hotel-detail-page.component.scss"]
})
export class AboutHotelDetailPageComponent implements OnInit {
  brandingSubscribe$;
  @Input() hotelAboutDetailModel;
  public manageBrandingModel: ManageBrandingModel = new ManageBrandingModel();

  @Input() hotelDetailLocation;
  constructor(
    private masterService: MasterService,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    this.brandingSubscribe$ = this.masterService.manageBrandingData.subscribe(
      response => {
        if (response.Data) {
          this.globalService.showLoader = false;
          this.manageBrandingModel = this.globalService.jsonConvert.deserializeObject(
            response.Data,
            ManageBrandingModel
          );    
          if(!this.globalService.validateIsEmpty(this.manageBrandingModel.Logo)){
            window.localStorage["Logo"] = this.manageBrandingModel.Logo
          }

        
          this.appendCss(null);

          //Check default language if exists And HotelLangTranslate
          if (
            this.manageBrandingModel.DefaultLang &&
            !window.localStorage["userLangTranslate"]
          ) {
            // Fetch Language from Hotel folder
            let combinedValue = this.globalService.concatenate(
              "portal",
              this.manageBrandingModel.DefaultLang
            );

            // this.translate.setDefaultLang(combinedValue);
            // this.langTranslateDisplay = this.manageBrandingModel.DefaultLang;

            // //In Order to Reflect changes
            // this.globals.userLangTranslate = this.manageBrandingModel.DefaultLang;
          } else {
            //Fetch language from Global Service
            // this.globals.hotelLangTranslate = this.globalService.gethotelLangTranslate();
            // Fetch Language from Hotel folder
            // let combinedValue = this.globalService.concatenate(
            //   "hotel",
            //   this.globals.userLangTranslate
            // );

            // this.translate.setDefaultLang(combinedValue);

            // this.langTranslateDisplay = this.globals.userLangTranslate;
          }

          // this.fetchMenu(this.globals.userLangTranslate); //Fetch Menu
        } else {
          this.globalService.handleApiError(response.error);

          //If Branding Response Error
          // let combinedValue = this.globalService.concatenate(
          //   "hotel",
          //   this.globals.userLangTranslate
          // );
          // this.translate.setDefaultLang(combinedValue);
          // this.langTranslateDisplay = this.globals.userLangTranslate;
        }
      },
      error => {
        this.globalService.showLoader = false;
        this.globalService.handleApiError(error);

        //If Branding Server Error
        // let combinedValue = this.globalService.concatenate(
        //   "hotel",
        //   this.globals.userLangTranslate
        // );
        // this.translate.setDefaultLang(combinedValue);
        // this.langTranslateDisplay = this.globals.userLangTranslate;
      }
    );
  }



  appendCss(customData) {
    // let text = '.custom-form-1 {background-image: url("`+customData.background_image+`");}';

    if (this.manageBrandingModel) {
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = "";
      /*if(this.manageBrandingModel.PrimaryTextColor){
            css.innerHTML = ".black{ color: "+this.manageBrandingModel.PrimaryTextColor+" }";
          }*/

      if (this.manageBrandingModel.SecondaryTextColor) {
        css.innerHTML =
          ".secondary-text{color:" +
          this.manageBrandingModel.SecondaryTextColor +
          "!important}";
      }

      if (this.manageBrandingModel.PrimaryButtonTextColor) {
        css.innerHTML +=
          ".primary-btn{color:" +
          this.manageBrandingModel.PrimaryButtonTextColor +
          "!important}";
      }

      if (this.manageBrandingModel.PrimaryButtonBackColor) {
        css.innerHTML +=
          ".primary-btn{background:" +
          this.manageBrandingModel.PrimaryButtonBackColor +
          "!important}";
      }
      if (this.manageBrandingModel.SecondaryButtonTextColor) {
        css.innerHTML +=
          ".secondary-btn{color:" +
          this.manageBrandingModel.SecondaryButtonTextColor +
          "!important}";
      }
      if (this.manageBrandingModel.SecondaryButtonBackColor) {
        css.innerHTML +=
          ".secondary-btn{background:" +
          this.manageBrandingModel.SecondaryButtonBackColor +
          "!important}";
      }
      if (this.manageBrandingModel.BackgroundColor) {
        css.innerHTML +=
          ".header-background-color{background:" +
          this.manageBrandingModel.BackgroundColor +
          "!important}";
      }

      if (this.manageBrandingModel.SelectedBackgroundColor) {
        css.innerHTML +=
          ".menu-select-item-color{background-color:" +
          this.manageBrandingModel.SelectedBackgroundColor +
          "!important}";
      }

      if (this.manageBrandingModel.TextColor) {
        css.innerHTML +=
          ".menu-item-text-color{color:" +
          this.manageBrandingModel.TextColor +
          "!important}";
      }

      if (this.manageBrandingModel.PrimaryTextColor) {
        css.innerHTML +=
          ".primary-text{color:" +
          this.manageBrandingModel.PrimaryTextColor +
          "!important}";
      }

      if (this.manageBrandingModel.BannerTextColor) {
        css.innerHTML +=
          ".banner-text{color:" +
          this.manageBrandingModel.BannerTextColor +
          "!important}";
      }

      document.body.appendChild(css);
    }
  }
  ngOnDestroy() {}
}
