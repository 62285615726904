<div class="sign_area d-flex justify-content-center align-items-center" style="height:100vh">
    <form #loginForm="ngForm" style="width:400px; margin:auto;">
        <!-- <img src="assets/images/logo.png" style="margin:30px auto; display:block;"> -->
        <div class="signbox_area ">
            <div class="sign_inner">
                <p>Welcome Inside Flora</p>
                <button class="close-btn" mat-dialog-close>
          <i class="material-icons">close</i>
        </button>
                <div class="form-group" *ngIf="dryCleaning">
                    <mat-form-field>
                        <input matInput placeholder="First Name" type="text" name="alarm" required>
                        <!-- <mat-error *ngFor="let error of globalService.containFormError(loginForm, 'UserId')">
              {{error}}
            </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field>
                        <input matInput placeholder="Last name" type="text" name="lastname" required>
                        <!-- <mat-error *ngFor="let error of globalService.containFormError(loginForm, 'Password')">
              {{error}}
            </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field>
                        <input matInput placeholder="Booking id" type="text" name="bookingid" required>
                        <!-- <mat-error *ngFor="let error of globalService.containFormError(loginForm, 'Password')">
                {{error}}
              </mat-error> -->
                    </mat-form-field>
                </div>

                <small>Need help? <a href="javascript:void(0);">Contact Support</a></small>
            </div>
            <!-- <div class="sign-bottom">
        <h4>Don't have account?</h4>
        <button (click)="openSignUpModal()" mat-flat-button class="">Sign Up</button>
      </div> -->
        </div>
    </form>
</div>