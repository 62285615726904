import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SharedService {
  siteLogoData = new EventEmitter<any>();
  BrandingData = new EventEmitter<any>();
  headerLangChangeEvent = new EventEmitter<any>(); //Event to send amenitiesData
  deleteChangeEvent = new EventEmitter<any>();
  updateChangeEvent = new EventEmitter<any>();
  permissionEvent = new EventEmitter<any>();
  preferenceEvent = new EventEmitter<any>();
  editPreferenceEvent = new EventEmitter<any>();
  emitRemovePreferenceEvent = new EventEmitter<any>();
  guestPermissionEvent = new EventEmitter<any>();
  deletePdfEvent = new EventEmitter<any>();
  deletePdfBroadcast = new EventEmitter<any>();
  deleteSingleImage = new EventEmitter<any>();
  constructor() {}
}
