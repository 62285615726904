import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { patternValidator } from "src/app/pattern-validator/pattern-validator";
import { Globals } from "../../globals";
import { MasterService } from "../../services/master.service";
import { takeUntil, take } from "rxjs/operators";
import { ListAmenitiesIconsModel } from "../../models/response/list-amenities-icons";
import { AmentiesModel } from "../../models/common-request-response";
import { Subject, ReplaySubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-new-amenities",
  templateUrl: "./add-new-amenities.component.html",
  styleUrls: ["./add-new-amenities.component.scss"]
})
export class AddNewAmenitiesComponent implements OnInit {
  addNewAmmenties: UntypedFormGroup;
  updateStatus: boolean = false;
  insertStatus: boolean = false;
  @Output() onAdd = new EventEmitter<any>(true);
  @Output() onUpdate = new EventEmitter<any>(true);
  public setPopupStatus = true;
  public listAmenitiesIconsModel: ListAmenitiesIconsModel = new ListAmenitiesIconsModel();
  public amentiesModel: AmentiesModel = new AmentiesModel();

  /** control for the MatSelect filter keyword */
  public amenitiesIconFilterCtrl: UntypedFormControl = new UntypedFormControl(); //Control For search

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  /** list of Amenties types filtered by search keyword */
  public filteredAmentiesIconModel: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);
  /** Label of the search placeholder */
  placeholderLabel = "select";
  showLoader: boolean = false;
  updateId: any = null;
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<AddNewAmenitiesComponent>,
    public globals: Globals,
    private masterService: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}
  public lang = this.globals.langSaveProperty;
  ngOnInit() {
    this.placeholderLabel = this.translate.instant("select");
    this.initForm();

    if (this.data) {
      this.updateId = this.data._id;
      this.updateStatus = true;
      this.editAmenities(this.data);
    } else {
      this.insertStatus = true;
    }

    this.showAmenitiesIcons(); //Show Icons Initialize Search Filter

    // listen for search field value changes
    this.amenitiesIconFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filteredAmentiesIcon();
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initForm() {
    this.addNewAmmenties = this.fb.group({
      amentiesName: ["", [Validators.required, patternValidator(/^[^<>%$]*$/)]],
      AmenitiesIcon: ["", [Validators.required]]
    });
  }

  //Form Controls for Html
  get f() {
    return this.addNewAmmenties.controls;
  }

  editAmenities(data) {
    var amentiesName = "";
    var amenitiesIcon = "";

    if (data.AmenitiesLangs && data.AmenitiesLangs.length) {
      amentiesName = data.AmenitiesLangs[0].Value;
    }

    if (data.AmenitiesIcon) {
      amenitiesIcon = data.AmenitiesIcon;
    }

    this.addNewAmmenties.patchValue({
      amentiesName: amentiesName,
      AmenitiesIcon: amenitiesIcon
    });
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  private setInitialValue() {
    this.filteredAmentiesIconModel
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        // this.singleSelect.compareWith = (
        //   a: RestuarantTypeInterface,
        //   b: RestuarantTypeInterface
        // ) => a.id === b.id;
      });
  }

  private filteredAmentiesIcon() {
    if (!this.listAmenitiesIconsModel.ListIcons.length) {
      return;
    }
    // get the search keyword
    let search = this.amenitiesIconFilterCtrl.value;
    if (!search) {
      this.filteredAmentiesIconModel.next(
        this.listAmenitiesIconsModel.ListIcons.slice()
      );
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the item
    this.filteredAmentiesIconModel.next(
      this.listAmenitiesIconsModel.ListIcons.filter(
        item => item.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  switchLanguage(lang) {
    this.lang = lang;

    if (this.updateId) {
      this.showLoader = true;
      this.masterService
        .showSingleAmentie({
          DefaultLang: lang,
          AmentieId: this.updateId
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.showLoader = false;
              this.globalService.emitPermissions(response.AdminPermissions);
              this.amentiesModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                AmentiesModel
              );
              this.editAmenities(this.amentiesModel);
            } else {
              this.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  add(form) {
    if (!form.invalid) {
      this.showLoader = true;

      if (this.insertStatus) {
        this.masterService
          .addAmentie({
            LangSave: this.lang,
            AmentiesName: form.value.amentiesName,
            AmenitiesIcon: form.value.AmenitiesIcon
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.showLoader = false;

                this.globalService.emitPermissions(response.AdminPermissions);
                // 
                if (response.Data) {
                  this.insertStatus = false;
                  this.updateStatus = true;
                  this.updateId = response.Data;
                  this.onAdd.emit({
                    id: response.Data,
                    value: form.value.amentiesName,
                    icon: form.value.AmenitiesIcon
                  });
                  this.closeModal()
                } else {
                  this.onAdd.emit(false); //If Already Exists
                }

                // this.dialogRef.close();
              } else {
                this.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }

      if (this.updateStatus) {
        if (this.updateId) {
          this.masterService
            .updateAmentie({
              LangSave: this.lang,
              AmentiesName: form.value.amentiesName,
              AmenitiesIcon: form.value.AmenitiesIcon,
              AmentieId: this.updateId
            })
            .pipe(takeUntil(this.globalService.componentDestroyed(this)))
            .subscribe(
              response => {
                if (response.Status) {
                  this.showLoader = false;
                  this.globalService.emitPermissions(response.AdminPermissions);
                  // 
                  if (response.Data) {
                    this.onUpdate.emit({
                      id: response.Data,
                      value: form.value.amentiesName,
                      icon: form.value.AmenitiesIcon
                    });
                    this.closeModal()
                  } else {
                    this.onUpdate.emit(false); //If Already Exists
                  }

                  // this.dialogRef.close();
                } else {
                  this.showLoader = false;
                  this.globalService.handleApiError(response);
                }
              },
              error => {
                this.showLoader = false;
                this.globalService.handleApiError(error);
              }
            );
        }
      }
    } else {
      this.globalService.validateFormFields(form.form);
    }
  }

  showAmenitiesIcons() {
    this.showLoader = true; //Set To True
    this.masterService
      .showAmenitiesIcons({ Data: null })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            
            this.showLoader = false;
            // this.listIcons = response.Data;
            this.listAmenitiesIconsModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              ListAmenitiesIconsModel
            );

            // load the initial Room  list (1)
            this.filteredAmentiesIconModel.next(
              this.listAmenitiesIconsModel.ListIcons.slice()
            );
          } else {
            this.showLoader = false;
            // this.globalService.handleApiError(response);
          }
        },
        error => {
          this.showLoader = false;
          // this.globalService.handleApiError(error);
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
