import { Injectable } from '@angular/core';
import { ResultModel } from '../models/response/base.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
@Injectable()
export class ResetAuthCheckService {

  constructor(public apiService:ApiService) { }

  checkSecret(reqModel):Observable<ResultModel>{
    const route = '/hotel/checkSecret';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
}
