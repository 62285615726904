import { JsonObject, JsonProperty } from "json2typescript";
import {
  IntConverter,
  BooleanConverter,
  NullableString
} from "src/app/core/models/essentials";

@JsonObject("RoomNameLangModel")
export class RoomNameLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("RoomDescriptionLangModel")
export class RoomDescriptionLangModel {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("RoomTypeLangs")
export class RoomTypeLangs {
  @JsonProperty("Lang", NullableString, true)
  Lang: string = undefined;
  @JsonProperty("Value", NullableString, true)
  Value: string = undefined;
}

@JsonObject("RoomType")
export class RoomType {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;
  @JsonProperty("RoomTypeLangs", [RoomTypeLangs], true)
  RoomTypeLangs: [RoomTypeLangs] = undefined;
}

@JsonObject("RoomResponse")
export class RoomResponse {
  @JsonProperty("_id", NullableString, true)
  _id: string = undefined;

  // @JsonProperty("RoomType", [Object], true)
  // RoomType: Object[] = undefined;
  @JsonProperty("RoomType", [Object], true)
  RoomType: Object[] = undefined;

  @JsonProperty("RoomNameLangs", [RoomNameLangModel], true)
  RoomNameLangs: [RoomNameLangModel] = undefined;

  @JsonProperty("RoomPriceNight", NullableString, true)
  RoomPriceNight: string = undefined;

  @JsonProperty("RoomDescriptionLangs", [RoomDescriptionLangModel], true)
  RoomDescriptionLangs: [RoomDescriptionLangModel] = undefined;

  @JsonProperty("AmenitiNames", [Object], true)
  AmenitiNames: object[] = undefined;

  @JsonProperty("ShowOnHomepage", BooleanConverter, true)
  ShowOnHomepage: boolean = undefined;

  @JsonProperty("Bookings", [Object], true)
  Bookings: object[] = undefined;

  @JsonProperty("Guests", Number, true)
  Guests: Number = undefined;

  @JsonProperty("RoomsNumber", [Object], true)
  RoomsNumber: object[] = undefined;

  @JsonProperty("ShowNonCheckedInUser", BooleanConverter, true)
  ShowNonCheckedInUser: boolean = undefined;

  @JsonProperty("RoomImages", [String], true)
  RoomImages: string[] = undefined;

  @JsonProperty("ExternalButtonToggle", BooleanConverter, true)
  ExternalButtonToggle: boolean = undefined;

  @JsonProperty("ExternalButtonURL", NullableString, true)
  ExternalButtonURL: string = undefined;
}

@JsonObject("RoomResponseArrayModel")
export class RoomResponseArrayModel {
  @JsonProperty("RoomLists", [RoomResponse], false)
  RoomLists: [RoomResponse] = undefined;

  @JsonProperty("CountLists", IntConverter, true)
  CountLists: number = undefined;
}
