<div class="sign_area">
  <form #loginForm="ngForm">
    <div class="signbox_area ">
      <div class="sign_inner">
        <h3>Sign Up</h3>
        <p>to get Instant Valuation of your car</p>
        <button class="close-btn" mat-dialog-close><i class="material-icons">
            close
          </i>
        </button>
        <div class="form-group">
          <mat-form-field>
            <span matPrefix>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" width="28px" height="24px">
                <path style="fill: #9fa1c3" class="shp0" d="M22.2 9.17L16.2 9.17L16.2 7.79C17.59 7.3 18.6 6.02 18.6 4.5C18.6 2.57 16.98 1 15 1C13.02 1 11.4 2.57 11.4 4.5C11.4 6.02 12.41 7.3 13.8 7.79L13.8 9.17L7.8 9.17C5.15 9.17 3 11.26 3 13.83C3 16.41 5.15 18.5 7.8 18.5L13.8 18.5L13.8 26.67L10.2 26.67C9.54 26.67 9 27.19 9 27.83C9 28.48 9.54 29 10.2 29L19.8 29C20.46 29 21 28.48 21 27.83C21 27.19 20.46 26.67 19.8 26.67L16.2 26.67L16.2 18.5L22.2 18.5C24.85 18.5 27 16.41 27 13.83C27 11.26 24.85 9.17 22.2 9.17ZM15 3.33C15.66 3.33 16.2 3.86 16.2 4.5C16.2 5.14 15.66 5.67 15 5.67C14.34 5.67 13.8 5.14 13.8 4.5C13.8 3.86 14.34 3.33 15 3.33ZM18.6 15L11.4 15C10.74 15 10.2 14.48 10.2 13.83C10.2 13.19 10.74 12.67 11.4 12.67L18.6 12.67C19.26 12.67 19.8 13.19 19.8 13.83C19.8 14.48 19.26 15 18.6 15Z" />
              </svg>
            </span>
            <input matInput placeholder="Name" [(ngModel)]="signUpModel.UserId" type="text" name="userId">
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <span matPrefix>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28px" height="20px" viewBox="0 0 485.211 485.211">
                <path fill="#9fa1c3" d="M485.211,363.906c0,10.637-2.992,20.498-7.785,29.174L324.225,221.67l151.54-132.584
                                        		c5.895,9.355,9.446,20.344,9.446,32.219V363.906z M242.606,252.793l210.863-184.5c-8.653-4.737-18.397-7.642-28.908-7.642H60.651
                                        		c-10.524,0-20.271,2.905-28.889,7.642L242.606,252.793z M301.393,241.631l-48.809,42.734c-2.855,2.487-6.41,3.729-9.978,3.729
                                        		c-3.57,0-7.125-1.242-9.98-3.729l-48.82-42.736L28.667,415.23c9.299,5.834,20.197,9.329,31.983,9.329h363.911
                                        		c11.784,0,22.687-3.495,31.983-9.329L301.393,241.631z M9.448,89.085C3.554,98.44,0,109.429,0,121.305v242.602
                                        		c0,10.637,2.978,20.498,7.789,29.174l153.183-171.44L9.448,89.085z" />

              </svg>
            </span>
            <input matInput placeholder="Email" [(ngModel)]="signUpModel.Email" type="text" name="email">
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <span matPrefix>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28" width="28" height="24">
                <path fill="#9fa1c3" d="M0 25.05L22 25.05L22 28L0 28L0 25.05ZM17 11.3L17 5.7C17 2.56 14.2 0 10.75 0C7.3 0 4.5 2.56 4.5 5.7L4.5 11.3L0 11.3L0 24.07L22 24.07L22 11.3L17 11.3ZM5.5 5.7C5.5 3.1 7.85 0.98 10.75 0.98C13.64 0.98 16 3.1 16 5.7L16 11.3L5.5 11.3L5.5 5.7ZM13 20.14C13 21.22 12.1 22.11 11 22.11C9.9 22.11 9 21.22 9 20.14L9 17.19C9 16.11 9.9 15.23 11 15.23C12.1 15.23 13 16.11 13 17.19L13 20.14Z" />
              </svg>
            </span>
            <input matInput placeholder="Password" [(ngModel)]="signUpModel.Password" type="password" name="password">
          </mat-form-field>
        </div>
        <button mat-flat-button class="sign-btn" color="primary" mat-dialog-close>Start
          Now</button>
        <small>By clicking this button, you agree to <a href="javascript:void(0);">term & conditions</a></small>
      </div>
      <div class="sign-bottom">
        <h4>Already have account?</h4>
        <button (click)="openSignInModal()" mat-flat-button class="">Log In</button>
      </div>
    </div>
  </form>
</div>