import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[numerical]'
})
export class NumericalDirective {

  constructor(private el: ElementRef) { }

  @Input() numerical: boolean;
  @Input() allowNegatives: boolean;
  @Input() allowPeriods: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.numerical !== undefined && this.numerical !== false) {
    // tslint:disable-next-line: deprecation
    const inputElement = e.target as HTMLInputElement;
    const lastInputtedValue = inputElement.value || '';
    const key = e.keyCode;
    const allowedKeys = [46, 8, 9, 27, 13, 110];
    if (this.allowNegatives !== undefined && this.allowNegatives !== false) {
      if (!lastInputtedValue.length) {
        allowedKeys.push(189);
        allowedKeys.push(109);
        allowedKeys.push(173);
      }
    }
    if (this.allowPeriods !== undefined && this.allowPeriods !== false) {
      allowedKeys.push(190);
    }
    if (allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (key === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (key === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (key === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (key >= 35 && key <= 39)) {
        // let it happen, don't do anything
        return;
      }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
        e.preventDefault();
    }
    }
  }
}
