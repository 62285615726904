import { JsonObject, JsonProperty } from "json2typescript";
import { IntConverter, NullableString } from "../essentials";

@JsonObject("HotelProfileModel")
export class HotelProfileModel {
  @JsonProperty("Username", NullableString, false)
  Username: string = undefined;
  @JsonProperty("FirstName", NullableString, false)
  FirstName: string = undefined;

  @JsonProperty("LastName", NullableString, false)
  LastName: string = undefined;

  @JsonProperty("CountryCode", NullableString, false)
  CountryCode: string = undefined;

  @JsonProperty("PhoneNumber", NullableString, false)
  PhoneNumber: string = undefined;

  @JsonProperty("ProfileImage", NullableString, false)
  ProfileImage: string = undefined;

  //@JsonProperty("ImageUpload", File, false)
  // ImageUpload: File = undefined;

  // @JsonProperty("S3Url", NullableString,false)
  // S3Url: string = undefined;
}
@JsonObject("HotelProfileModel1")
export class HotelProfileModel1 {
  @JsonProperty("Username", NullableString, false)
  Username: string = undefined;
  @JsonProperty("FirstName", NullableString, false)
  FirstName: string = undefined;

  @JsonProperty("LastName", NullableString, false)
  LastName: string = undefined;

  @JsonProperty("CountryCode", NullableString, false)
  CountryCode: string = undefined;
  
  @JsonProperty("PhoneNumber", NullableString, false)
  PhoneNumber: string = undefined;

  @JsonProperty("ProfileImage", NullableString, false)
  ProfileImage: string = undefined;
  @JsonProperty("Department", NullableString, false)
  Department: string = undefined;
  @JsonProperty("Role", NullableString, false)
  Role: string = undefined;
  //@JsonProperty("ImageUpload", File, false)
  // ImageUpload: File = undefined;

  // @JsonProperty("S3Url", NullableString,false)
  // S3Url: string = undefined;
}
