import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../services/global.service";
import { ManageGalleryService } from "../../services/manage-gallery.service";
import { MasterService } from "../../services/master.service";
import {
  ManageGalleryModel,
  ManageGalleryArrayModel
} from "../../models/request/manageGallery.model";
import { AmazonWebService } from "../../services/amazon-web.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AddNewImageModalComponent } from "../../modals/add-new-image-modal/add-new-image-modal.component";
import { takeUntil } from "rxjs/operators";
import {
  HotelGalleryTitleModel,
  HotelGalleryTitleLangModel
} from "../../models/request/hotelGalleryTitle.model";
import { SliderShowModalComponent } from "../../modals/slider-show-modal/slider-show-modal.component";
import { ConfirmDialogModalComponent } from "../../modals/confirm-dialog-modal/confirm-dialog-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../globals";
import { SharedService } from "src/app/shared.service";

@Component({
  selector: "app-hotel-gallery",
  templateUrl: "./hotel-gallery.component.html",
  styleUrls: ["./hotel-gallery.component.scss"]
})
export class HotelGalleryComponent implements OnInit {
  uploadSubscribe$;
  updateSubscribe$;
  deletSubscribe$;
  innerBannerShow: boolean = false;
  viewAllShow: boolean = true;
  imageAction: boolean = true;
  launch: boolean;
  customizeGalleryStatus: boolean = false;
  imageGalleryStatus: boolean = false;

  public headingTitle = "Hotel Gallery";
  public buttonText = "VIEW ALL";
  public buttonType = "secondary-btn";

  addStyle = "black";

  checkGalleryCount: Number = 0;
  public manageGalleryArrayModel: ManageGalleryArrayModel = new ManageGalleryArrayModel();

  public hotelGalleryTitleModel: HotelGalleryTitleModel = new HotelGalleryTitleModel();
  public hotelGalleryTitleLangModel: HotelGalleryTitleLangModel = new HotelGalleryTitleLangModel();

  @Input() galleryLocationStatus: string;

  //Loader
  title = "materialApp";
  color = "primary";
  mode = "indeterminate";
  showLoader: boolean = false;

  public manageImageGallery: any[] = [];

  constructor(
    private router: Router,
    public globalService: GlobalService,
    private manageGalleryService: ManageGalleryService,
    private masterService: MasterService,
    private amazonWebService: AmazonWebService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private globals: Globals,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // 
    // 
    // this.headingTitle = this.translate.instant("HotelGallery");
    // 
    this.fetchGalleryTitle();
    if (this.router.url == "/") {
      this.innerBannerShow = false;
      this.viewAllShow = true;
      this.imageAction = false;
      this.customizeGalleryStatus = false;
      this.imageGalleryStatus = false;
      this.launch = this.launchIcon(this.innerBannerShow, this.viewAllShow);

      let reqModel = {
        Lang: this.globals.userLangTranslate,
        Subdomain: this.globalService.getHostname(),
        HomeParam: 1
      };

      this.imageGallery(reqModel);
    }

    if (this.galleryLocationStatus == "galleryFrontView") {
      this.innerBannerShow = true;
      this.viewAllShow = false;
      this.imageAction = false;
      this.customizeGalleryStatus = false;
      this.launch = this.launchIcon(this.innerBannerShow, this.viewAllShow);

      this.imageGalleryStatus = false;

      let reqModel = {
        Lang: this.globals.userLangTranslate,
        Subdomain: this.globalService.getHostname(),
        HomeParam: 0
      };
      this.imageGallery(reqModel);
    }

    if (this.galleryLocationStatus == "manageImageGallery") {
      //Route  imageGallery
      this.innerBannerShow = false;
      this.viewAllShow = false;
      this.imageAction = true;
      this.customizeGalleryStatus = false;
      this.launch = this.launchIcon(this.innerBannerShow, this.viewAllShow);

      this.imageGalleryStatus = true;

      let reqModel = {
        Lang: this.masterService.gethotelLangTranslate(),
        Subdomain: this.globalService.getHostname(),
        HomeParam: 0
      };

      // Initial Image Gallery Binding
      this.imageGallery(reqModel);

      // Subscribe to Image Upload event then  Image Gallery Binding
      this.uploadSubscribe$ = this.amazonWebService.processCompleted.subscribe(
        response => {
          // 
          this.imageGallery(reqModel);
        },
        error => {
          this.showLoader = false;
          this.globalService.handleApiError(error);
          return false;
        }
      );

      // Subscribe to Image Update event then  Image Gallery Binding
      this.updateSubscribe$ = this.masterService.galleryData.subscribe(
        (data: any) => {
          this.imageGallery(reqModel);
        }
      );
    }

    if (this.galleryLocationStatus == "homePageMenu") {
      this.showLoader = false;
      this.innerBannerShow = false;
      this.viewAllShow = false;
      this.imageAction = true;
      this.customizeGalleryStatus = true;
      this.launch = true;

      this.imageGalleryStatus = false;
      let reqModel = {
        Lang: this.masterService.gethotelLangTranslate(),
        Subdomain: this.globalService.getHostname(),
        HomeParam: 1,
        location: "homePageMenu",
      };
      this.imageGallery(reqModel);

      // Subscribe to Image Upload event then  Image Gallery Binding
      this.uploadSubscribe$ = this.amazonWebService.processCompleted.subscribe(
        response => {
          // 
          this.imageGallery(reqModel);
        },
        error => {
          this.showLoader = false;
          this.globalService.handleApiError(error);
          return false;
        }
      );

      // Subscribe to Image Update event then  Image Gallery Binding
      this.updateSubscribe$ = this.masterService.galleryData.subscribe(
        (data: any) => {
          this.imageGallery(reqModel);
        }
      );
    }
  }

  ngOnDestroy() {
    // 
    if (this.uploadSubscribe$) {
      this.uploadSubscribe$.unsubscribe();
    }
    if (this.updateSubscribe$) {
      this.updateSubscribe$.unsubscribe();
    }

    if (this.deletSubscribe$) {
      this.deletSubscribe$.unsubscribe();
    }
  }

  launchIcon(innerBannerShow, viewAllShow) {
    if (innerBannerShow == true || viewAllShow == true) return true;
    else return false;
  }

  switchLanguage(lang) {
    let reqModel = {
      Lang: lang,
      Subdomain: this.globalService.getHostname(),
      HomeParam: 0
    };

    this.imageGallery(reqModel);
  }

  imageGallery(reqModel) {
    this.showLoader = true;
    if (
      this.galleryLocationStatus == "homePageMenu" ||
      this.galleryLocationStatus == "galleryFrontView" ||
      this.galleryLocationStatus == "home"
    ) {
      this.showLoader = false;
      this.manageGalleryService.getImageGallery(reqModel).subscribe(
        response => {
          if (response.Status) {
            this.globalService.emitPermissions(response.AdminPermissions);
            this.manageGalleryArrayModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              ManageGalleryArrayModel
            );
            // 
            this.checkGalleryCount = this.manageGalleryArrayModel.GalleryImages.length;
          } else {
            this.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
    } else {
      this.manageGalleryService.getImageGalleryAdmin(reqModel).subscribe(
        response => {
          if (response.Status) {
            this.showLoader = false;
            this.globalService.emitPermissions(response.AdminPermissions);
            // 
            this.manageGalleryArrayModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              ManageGalleryArrayModel
            );
            let imageGallery = this.manageGalleryArrayModel.GalleryImages;

            this.manageImageGallery = this.manageGalleryArrayModel.GalleryImages;
            // for (let i = 0; i < imageGallery.length; i++) {
            //   this.manageImageGallery.push(imageGallery[i]._id);
            // }

            // 
            this.checkGalleryCount = this.manageGalleryArrayModel.GalleryImages.length;
          } else {
            this.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
    }
  }

  singleImageShow(id) {
    this.globalService.showLoader = true;
    this.manageGalleryService
      .getSingleImageGallery({
        Id: id,
        Lang: this.masterService.getLangSaveProperty()
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.globalService.emitPermissions(response.AdminPermissions);
            // 

            this.dialog.open(AddNewImageModalComponent, {
              width: "440px",
              height: "auto",
              panelClass: "add-image",
              disableClose: true,
              data: response.Data
            });
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  sliderImageShow(image, imageTitle, Id) {
    this.dialog.open(SliderShowModalComponent, {
      width: "440px",
      height: "auto",
      disableClose: false,
      panelClass: "slide-image",
      data: {
        Id: Id,
        clickImage: image,
        imageTitle: imageTitle,
        allImages: this.manageGalleryArrayModel.GalleryImages
      }
    });
  }

  deleteGalleryImage(id, imgUrl) {
    //Split ImageUrl to find image name and type

    let splitImgUrl = imgUrl.split("/");
    let fetchImage = splitImgUrl[splitImgUrl.length - 1];
    let imgType = fetchImage.split(".").pop();

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "delete-dialog";
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("GalleryImageDelete")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // 
      // 

      if (result) {
        this.globalService.showLoader = true;

        //Delete Api Request With Aws Signed Url
        this.amazonWebService.deleteObject(
          fetchImage,
          imgType,
          "private",
          "imageGallery"
        );

        // Subscribe To Delete event after Success Delete Image From Bucket
        this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
          response => {
            if (response.data.Filename) {
              this.manageGalleryService
                .deleteGalleryImage({
                  Id: id,
                  Lang: this.masterService.getLangSaveProperty()
                })
                .subscribe(
                  response => {
                    if (response.Status) {
                      this.globalService.showLoader = false;
                      this.globalService.emitPermissions(
                        response.AdminPermissions
                      );
                      // 
                      this.manageGalleryArrayModel = this.globalService.jsonConvert.deserializeObject(
                        response.Data,
                        ManageGalleryArrayModel
                      );
                      // 
                      this.checkGalleryCount = this.manageGalleryArrayModel.GalleryImages.length;

                      this.globalService.handleSuccessMessage(response.Message);
                    } else {
                      this.globalService.showLoader = false;
                      this.globalService.handleApiError(response);
                    }
                  },
                  error => {
                    this.globalService.showLoader = false;
                    this.globalService.handleApiError(error);
                  }
                );
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      }
    });
  }

  fetchGalleryTitle() {
    this.globalService.showLoader = true;
    this.masterService
      .getGalleryTitle({
        Lang: this.masterService.getuserLangTranslate(),
        Subdomain: this.globalService.getHostname()
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            // 
            this.globalService.showLoader = false;
            this.globalService.emitPermissions(response.AdminPermissions);
            this.hotelGalleryTitleModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              HotelGalleryTitleModel
            );
            // 
            this.buttonType = this.hotelGalleryTitleModel.GalleryButtonType;
            if (this.hotelGalleryTitleModel.HotelGalleryTitleLang.length) {
              this.hotelGalleryTitleLangModel = this.hotelGalleryTitleModel.HotelGalleryTitleLang[0];
              this.headingTitle = this.translate.instant(this.hotelGalleryTitleLangModel.GalleryTitle);
              this.buttonText = this.hotelGalleryTitleLangModel.GalleryButtonText;
            }
          } else {
            this.globalService.showLoader = false;
            //this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          //this.globalService.handleApiError(error);
        }
      );
  }

  deleteHomePageImage(Id) {
    let lists = this.manageGalleryArrayModel.GalleryImages;

    let findIndex = lists.findIndex(list => list._id === Id);

    this.globalService.showLoader = true;
    this.manageGalleryService
      .updateHomeGalleryStatus({
        Lang: this.masterService.getLangSaveProperty(),
        Id: Id
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.manageGalleryArrayModel.GalleryImages.splice(findIndex, 1);
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  saveOrder() {
    this.showLoader = true;
    this.masterService
      .manageOrder({
        Data: this.manageImageGallery
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.showLoader = false;
            this.globalService.handleSuccessMessage(response.Message);
          } else {
            this.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }
}
