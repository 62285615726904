import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../models/response/base.model';

@Injectable()
export class ResetPasswordService {

  constructor(private apiService:ApiService) { }

  reset(reqModel): Observable<ResultModel> {
    const route = '/hotel/reset';
    return this.apiService.post<ResultModel>(route, reqModel);
  }
}
