import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { JwtService } from '../services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class GuestAuthGuard implements  CanActivate{
  constructor(private jwtService: JwtService, private router: Router, public globalService: GlobalService,    
    ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      try{
        if(this.globalService.hasCheckInAuthToken()){   
          if(this.jwtService.isTokenExpired()){
            
            this.globalService.deleteGuestAuthToken();
            this.router.navigate(["/"]);
            return false;
          }
          return true;
        }
        return false;
       }
       catch(Exception){
         
         this.globalService.deleteGuestAuthToken();
         this.router.navigate(["/"]);
         return false;
       }
  }
}
