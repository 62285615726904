import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MasterService } from "../../services/master.service";
import { Globals } from "../../globals";
import { patternValidator } from "src/app/pattern-validator/pattern-validator";
import { takeUntil } from "rxjs/operators";
import { ResturantTypeLangsResponseModel } from "../../models/common-request-response";

@Component({
  selector: "app-add-new-resturant-type-modal",
  templateUrl: "./add-new-resturant-type-modal.component.html",
  styleUrls: ["./add-new-resturant-type-modal.component.scss"]
})
export class AddNewResturantTypeModalComponent implements OnInit {
  addNewResturantType: UntypedFormGroup;
  // Emitter For Adding Room type
  @Output() onAdd = new EventEmitter<any>(true);
  @Output() onUpdate = new EventEmitter<any>(true);

  //Set Popup Status  Parent  to child Communication
  public setPopupStatus = true;
  updateStatus: boolean = false;
  insertStatus: boolean = false;
  updateId: any = null;

  public resturantTypeLangsResponseModel: ResturantTypeLangsResponseModel = new ResturantTypeLangsResponseModel();
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<AddNewResturantTypeModalComponent>,
    private masterService: MasterService,
    public globals: Globals,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  public lang = this.globals.langSaveProperty;
  ngOnInit() {
    this.initForm();

    if (this.data) {
      this.updateId = this.data._id;
      let resturantTypeName = "";
      this.updateStatus = true;

      if (this.data.ResturantTypeLangs && this.data.ResturantTypeLangs.length) {
        resturantTypeName = this.data.ResturantTypeLangs[0].Value;
      }

      this.addNewResturantType.patchValue({
        ResturantTypeName: resturantTypeName
      });
    } else {
      this.insertStatus = true;
    }
  }

  initForm() {
    this.addNewResturantType = this.fb.group({
      ResturantTypeName: [
        "",
        [Validators.required, patternValidator(/^[^<>%$]*$/)]
      ]
    });
  }

  switchLanguage(lang) {
    this.lang = lang;

    if (this.updateId) {
      this.editResturantType(lang);
    }
  }

  editResturantType(lang) {
    this.masterService
      .showSingleResturantType({
        LangSave: lang,
        ResturantTypeId: this.updateId
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.emitPermissions(response.AdminPermissions);

            this.resturantTypeLangsResponseModel = this.globalService.jsonConvert.deserializeObject(
              response.Data,
              ResturantTypeLangsResponseModel
            );

            let resturantTypeName = "";

            if (
              this.resturantTypeLangsResponseModel.ResturantTypeLangs &&
              this.resturantTypeLangsResponseModel.ResturantTypeLangs.length
            ) {
              resturantTypeName = this.resturantTypeLangsResponseModel
                .ResturantTypeLangs[0].Value;
            }

            this.addNewResturantType.patchValue({
              ResturantTypeName: resturantTypeName
            });

            this.globalService.showLoader = false;
          } else {
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  addResturantType(form) {
    if (!form.invalid) {
      this.globalService.showLoader = true;

      if (this.insertStatus) {
        this.masterService
          .addResturantType({
            LangSave: this.lang,
            ResturantTypeName: form.value.ResturantTypeName
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                this.globalService.showLoader = false;

                this.globalService.emitPermissions(response.AdminPermissions);

                // 
                if (response.Data) {
                  this.insertStatus = false;
                  this.updateStatus = true;
                  this.updateId = response.Data;
                  this.onAdd.emit({
                    id: response.Data,
                    value: form.value.ResturantTypeName
                  });
                } else {
                  this.onAdd.emit(false); //If Already Exists
                }

                // this.dialogRef.close();
              } else {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }

      if (this.updateStatus) {
        if (this.updateId) {
          this.masterService
            .updateResturantType({
              LangSave: this.lang,
              ResturantTypeName: form.value.ResturantTypeName,
              ResturantTypeId: this.updateId
            })
            .pipe(takeUntil(this.globalService.componentDestroyed(this)))
            .subscribe(
              response => {
                if (response.Status) {
                  this.globalService.showLoader = false;
                  this.globalService.emitPermissions(response.AdminPermissions);

                  if (response.Data) {
                    this.onUpdate.emit({
                      id: response.Data,
                      value: form.value.ResturantTypeName
                    });
                  } else {
                    this.onUpdate.emit(false); //If Already Exists
                  }
                  this.globalService.handleSuccessMessage(response.Message);
                } else {
                  this.globalService.showLoader = false;
                  this.globalService.handleApiError(response);
                }
              },
              error => {
                this.globalService.showLoader = false;
                this.globalService.handleApiError(error);
              }
            );
        }
      }
    } else {
      this.globalService.validateFormFields(form.form);
    }
  }

  ngOnDestroy() {}

  closeModal() {
    this.dialogRef.close();
  }
}
