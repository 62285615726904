import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-heading-titles',
  templateUrl: './heading-titles.component.html',
  styleUrls: ['./heading-titles.component.scss']
})
export class HeadingTitlesComponent implements OnInit {
  @Input() childHeadingTitle: string;
  @Input() addStyle:string;
  constructor(private router: Router) { }
  
  ngOnInit() {
    if(this.router.url == '/'){
      this.addStyle = 'black';
    }

    if(this.router.url == '/gallery'){
        this.addStyle = 'white';
    }else this.addStyle='red'
  }

}
