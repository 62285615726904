import { Directive, Input,HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLanguage]',
  exportAs: 'language'
})
export class LanguageDirective {
  @Input()
  langSave: string;
  langView: string;
  private element: HTMLInputElement;

  @HostListener('click') onClick() {
      alert("ok");
   }

  constructor(private elRef: ElementRef) { 
    //elRef will get a reference to the element where
    //the directive is placed
    this.element = elRef.nativeElement;

    
  }


}
