import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA,  MatDialogRef } from "@angular/material/dialog";
//  import { IImage } from "ng-simple-slideshow";
import { GalleryLangModel } from "../../models/request/manageGallery.model";

@Component({
  selector: "app-slider-show-modal",
  templateUrl: "./slider-show-modal.component.html",
  styleUrls: ["./slider-show-modal.component.scss"]
})
export class SliderShowModalComponent implements OnInit {
 

  imageUrlArray: (string | any)[] = [];
  
  height: string = "100%";
  minHeight: string;
  arrowSize: string = "30px";
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = false;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = true;
  /*debug: boolean = false;*/
  // backgroundSize: string = 'auto auto';

  backgroundSize = "auto 100%";
  backgroundPosition: string = "center center";
  backgroundRepeat: string = "no-repeat";
  showDots: boolean = true;
  dotColor: string = "#FFF";
  showCaptions: boolean = true;
  captionColor: string = "#FFF";
  captionBackground: string = "rgba(0, 0, 0, .35)";
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = "100%";
  fullscreen: boolean = true;

  public galleryLangModel: GalleryLangModel = new GalleryLangModel();

  constructor(
    private dialogRef: MatDialogRef<SliderShowModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.imageUrlArray = [];
    if (this.data) {
      // 
      // 
      // if (this.data.imageTitle) {
      //   this.imageUrlArray.push({
      //     url: this.data.clickImage,
      //     href:'#'
      //   });
      // } else {
      //   this.imageUrlArray.push({
      //     url: this.data.clickImage,
      //     href: '#'
      //   });
      // }

      this.imageUrlArray.push({
        url: this.data.clickImage,
        href: "#"
      });

      if (this.data.allImages.length == 1) {
        this.showArrows = false;
        this.showDots = false;
        // 
      }
      this.data.allImages.forEach(item => {
        if (item._id) {
          if (item._id != this.data.Id) {
            if (item.ImageTitle && item.ImageTitle[0]) {
              this.galleryLangModel = item.ImageTitle[0];
              this.imageUrlArray.push({
                url: item.Image,
                caption: this.galleryLangModel.Value,
                href: this.data.clickImage
              });
            } else {
              this.imageUrlArray.push({
                url: item.Image,
                href: this.data.clickImage
              });
            }
          }
        } else {
          this.imageUrlArray.push({
            url: item,
            href: "#"
          });
        }
      });
    }
  }
  
  ngAfterViewInit() {
    // if (this.data) {
    //   // 
    //   // 
    //   if (this.data.imageTitle) {
    //     this.imageUrlArray.push({
    //       url: this.data.clickImage,
    //       caption: this.data.imageTitle
    //     });
    //   } else {
    //     this.imageUrlArray.push({ url: this.data.clickImage });
    //   }
    //   if (this.data.allImages.length == 1) {
    //     this.showArrows = false;
    //     this.showDots = false;
    //     // 
    //   }
    //   this.data.allImages.forEach(item => {
    //     if (item._id) {
    //       if (item._id != this.data.Id) {
    //         if (item.ImageTitle && item.ImageTitle[0]) {
    //           this.galleryLangModel = item.ImageTitle[0];
    //           this.imageUrlArray.push({
    //             url: item.Image,
    //             caption: this.galleryLangModel.Value
    //           });
    //         } else {
    //           this.imageUrlArray.push({ url: item.Image });
    //         }
    //       }
    //     }
    //   });
    // }
  }

  closeModal() {
    this.dialogRef.close();
  }

  onFullscreenExit(event) {
    if (event) {
      this.dialogRef.close();
    }
  }
}
