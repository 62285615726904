import { Component, OnInit } from '@angular/core';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { SignupModalComponent } from '../signup-modal/signup-modal.component';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { LoginModel } from '../../models/request/login.model';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'app-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss']
})
export class SigninModalComponent implements OnInit {

  public loginModel: LoginModel = new LoginModel();
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SigninModalComponent>,
    public globalService: GlobalService,
    private userService: UserService,
    private jwtService: JwtService
  ) { }

  ngOnInit() {
  }

  userLogin(form: NgForm) {
    if (!form.invalid) {
      this.globalService.toggleLoader();
      this.userService.signIn(this.loginModel).subscribe(response => {
        if (response.Status) {
          this.closeModal();
          this.jwtService.saveToken(response.Data);
        }
        else {
          this.globalService.handleApiError(response);
        }
        this.globalService.toggleLoader();
      }, error => {
        this.globalService.handleApiError(error);
        this.globalService.toggleLoader();
      })
    }
    else {
      this.globalService.validateFormFields(form.form);
    }
  }

  openSignUpModal(): void {
    this.closeModal();
    this.dialog.open(SignupModalComponent, {
      width: '440px',
      height: 'auto',
      panelClass: 'login-modalbox',
      disableClose: true
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
