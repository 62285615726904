import { Component, OnInit , Optional, Inject} from '@angular/core';
import { MasterService } from '../../services/master.service';
import { GlobalService } from '../../services/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { takeUntil } from 'rxjs/operators';
import {MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-print-restaurant-order',
  templateUrl: './print-restaurant-order.component.html',
  styleUrls: ['./print-restaurant-order.component.scss']
})
export class PrintRestaurantOrderComponent implements OnInit {

  displayedColumns: string[] = ['item', 'qty'];
  dataSource = ELEMENT_DATA;
  requestId;
  viewOrder;
  orderDetails;

  constructor(
    public dialogRef: MatDialogRef<PrintRestaurantOrderComponent>,
    public masterService: MasterService,
    public globalService: GlobalService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.requestId = data.requestId;
    this.viewOrder = data.viewOrder;
  }

  ngOnInit(): void {
    this.fetchOrderDetail();
  }

  fetchOrderDetail(){
    this.globalService.showLoader = true;
    this.masterService
      .getRestaurantOrderDetail({requestId : this.requestId})
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if(response.Status){
            
            this.orderDetails = response.Data[0];
            let dataSourcePrint : PeriodicElement[] = [];
              for (let item of response.Data[0].ResturantItemName) {
                for (let itemName of item.ResturantItems) {
                  for (let id of response.Data[0].Items) {
                    if (itemName._id == id._id) {
                      const quantity = id.Quantity;
                      const itemNameValue = itemName.ResturantItemsNameLang[0].Value;
                      dataSourcePrint.push({
                        item: itemNameValue,
                        qty: Number(quantity),
                      });
                    }
                  }
                }
              }
              this.dataSource = dataSourcePrint.slice(0);
              if(this.viewOrder == false){
                setTimeout(() => {
                  this.convetToPDF();
                }, 1000);
              }else{
                this.globalService.showLoader = false;
              }
              
          }
          else {
            
            this.globalService.showLoader = false;
          }
        },
        error => {
          
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  closeDialog(){
    this.dialogRef.close();
  
  }

  convetToPDF() {
    const data = document.getElementById('contentToConvert');
    
    
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.autoPrint() // Generated PDF
      window.open(pdf.output('bloburl').toString(), '_blank');
      if(this.viewOrder == false){
        setTimeout(() => {
          this.closeDialog();
        }, 2000);
      }
    });
    // this.globalService.showLoader = false;
  }

  ngOnDestroy(){

  }

}


export interface PeriodicElement {
  qty: number;
  item: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];