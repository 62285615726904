<!-- Hotel gllery -->
<div class="inner-loader" *ngIf="showLoader">
    <mat-progress-spinner class="tp-margin" [color]="color" [mode]="mode">
    </mat-progress-spinner>
</div>
<section id="hotel-gallery" class="content-section-outer clearfix">
    <div class="content-wrapper">
        <div class="section-header" *ngIf="viewAllShow && galleryLocationStatus == 'home'">
            <app-heading-titles [childHeadingTitle]="headingTitle"></app-heading-titles>
            <button class="btn" [class]="buttonType" [routerLink]="['' ,'gallery']" >{{ buttonText }}</button>
        </div>
        <div class="main-content-wrapper">

            <div class="row" *ngIf="galleryLocationStatus == 'homePageMenu'">
                <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let obj of  manageGalleryArrayModel.GalleryImages">
                    <div class="delete-image" *ngIf="customizeGalleryStatus"><i class="material-icons" (click)="deleteHomePageImage(obj._id)" style="cursor:pointer;">delete</i></div>
                    <img src="{{obj.Image}}">
                    <div class="img-info-on-hover" *ngIf="launch">
                        <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
              <span *ngFor="let innerObj of obj.ImageTitle">
                <i class="material-icons" (click)="sliderImageShow(obj.Image,innerObj.Value,obj._id)">launch</i>
                <p>{{ innerObj.Value }}</p>
              </span>
                        </span>
                        <i class="material-icons" (click)="singleImageShow(obj._id)"  [givenButton]="'edit'">edit</i>
                        <ng-template #other_imgTitle>
                            <span>
                <i class="material-icons" (click)="sliderImageShow(obj.Image,null,obj._id)">launch</i>
                <p>{{'NotAvailable'|translate}}</p>
              </span>
                        </ng-template>
                    </div>

                </div>
            </div>

            <div class="row" *ngIf="galleryLocationStatus == 'home'">
              <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let obj of  manageGalleryArrayModel.GalleryImages| slice:0:4;" (click)="sliderImageShow(obj.Image,null,obj._id)">
                <div class="delete-image" *ngIf="customizeGalleryStatus"><i class="material-icons" (click)="deleteHomePageImage(obj._id)" style="cursor:pointer;">delete</i></div>
                <img [src]="obj.Image">
                <div class="img-info-on-hover" *ngIf="launch">
                  <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
                    <span *ngFor="let innerObj of obj.ImageTitle">
                      <i class="material-icons" (click)="sliderImageShow(obj.Image,innerObj.Value,obj._id)">launch</i>
                      <p>{{ innerObj.Value }}</p>
                    </span>
                  </span>
                  <ng-template #other_imgTitle>
                    <span>
                      <i class="material-icons" (click)="sliderImageShow(obj.Image,null,obj._id)">launch</i>
                      <p>{{'NotAvailable'|translate}}</p>
                    </span>
                  </ng-template>
                </div>
                <div class="admin-image-action" *ngIf="imageAction">
                  <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
                    <span class="clearfix" *ngFor="let innerObj of obj.ImageTitle">
                      <i class="material-icons" (click)="singleImageShow(obj._id)">edit</i>
                      <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)">delete</i>
                      <p>{{ innerObj.Value }}</p>
                    </span>
                  </span>
                  <span class="clearfix" *ngIf="!obj.ImageTitle.length">
                    <i class="material-icons" (click)="singleImageShow(obj._id)">edit</i>
                    <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)">delete</i>
                    <p>{{'NotAvailable'|translate}}</p>
                  </span>
                </div>
              </div>
            </div>
            

            <div class="row" *ngIf="galleryLocationStatus == 'galleryFrontView'">

                <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let obj of  manageGalleryArrayModel.GalleryImages" (click)="sliderImageShow(obj.Image,null,obj._id)">
                    <div class="delete-image" *ngIf="customizeGalleryStatus"><i class="material-icons" (click)="deleteHomePageImage(obj._id)" style="cursor:pointer;">delete</i></div>
                    <img src="{{obj.Image}}">
                    <div class="img-info-on-hover" *ngIf="launch">
                        <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
              <span *ngFor="let innerObj of obj.ImageTitle">
                <i class="material-icons" (click)="sliderImageShow(obj.Image,innerObj.Value,obj._id)">launch</i>
                <p>{{ innerObj.Value }}</p>
              </span>
                        </span>
                        <ng-template #other_imgTitle>
                            <span>
                <i class="material-icons" (click)="sliderImageShow(obj.Image,null,obj._id)">launch</i>
                <p>{{'NotAvailable'|translate}}</p>
              </span>
                        </ng-template>
                    </div>
                    <div class="admin-image-action" *ngIf="imageAction">
                        <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
              <span class="clearfix" *ngFor="let innerObj of obj.ImageTitle">
                <i class="material-icons" (click)="singleImageShow(obj._id)">edit</i>
                <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)">delete</i>
                <p>{{ innerObj.Value }}</p>
              </span>
                        </span>

                        <span class="clearfix" *ngIf="!obj.ImageTitle.length">
              <i class="material-icons" (click)="singleImageShow(obj._id)">edit</i>
              <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)">delete</i>
              <p>{{'NotAvailable'|translate}}</p>
            </span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="galleryLocationStatus == 'manageImageGallery'" dnd-sortable-container [sortableData]="manageImageGallery">

                <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let obj of  manageGalleryArrayModel.GalleryImages;let i = index" dnd-sortable [sortableIndex]="i" (onDropSuccess)="saveOrder()">
                    <div class="delete-image" *ngIf="customizeGalleryStatus" [appAccessCheck]="'image-gallery'" [givenButton]="'delete'"><i class="material-icons" (click)="deleteHomePageImage(obj._id)" style="cursor:pointer;">delete</i></div>
                    <img src="{{obj.Image}}">
                    <div class="img-info-on-hover" *ngIf="launch">
                        <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
              <span *ngFor="let innerObj of obj.ImageTitle">
                <i class="material-icons" (click)="sliderImageShow(obj.Image,innerObj.Value,obj._id)"
                  [appAccessCheck]="'image-gallery'" [givenButton]="'show'">launch</i>
                <p>{{ innerObj.Value }}</p>
              </span>
                        </span>
                        <ng-template #other_imgTitle>
                            <span>
                <i class="material-icons" (click)="sliderImageShow(obj.Image,null,obj._id)">launch</i>
                <p>{{'NotAvailable'|translate}}</p>
              </span>
                        </ng-template>
                    </div>
                    <div class="admin-image-action" *ngIf="imageAction">
                        <span *ngIf="obj.ImageTitle && obj.ImageTitle.length;else  other_imgTitle">
              <span class="clearfix" *ngFor="let innerObj of obj.ImageTitle">
                <i class="material-icons" (click)="singleImageShow(obj._id)" [appAccessCheck]="'image-gallery'"
                  [givenButton]="'edit'">edit</i>
                <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)"
                  [appAccessCheck]="'image-gallery'" [givenButton]="'delete'">delete</i>
                <p>{{ innerObj.Value }}</p>
              </span>
                        </span>

                        <span class="clearfix" *ngIf="!obj.ImageTitle.length">
              <i class="material-icons" (click)="singleImageShow(obj._id)" [appAccessCheck]="'image-gallery'"
                [givenButton]="'edit'">edit</i>
              <i class="material-icons" (click)="deleteGalleryImage(obj._id,obj.Image)"
                [appAccessCheck]="'image-gallery'" [givenButton]="'delete'">delete</i>
              <p>{{'NotAvailable'|translate}}</p>
            </span>
                    </div>
                </div>
            </div>
            <!-- <button class="btn btn-primary" type="button" *ngIf="galleryLocationStatus == 'manageImageGallery' && manageImageGallery.length >1" (click)="saveOrder()">
        <i class="material-icons">add</i>
        {{'SaveOrder'|translate}}
      </button> -->
        </div>
    </div>
</section>
<!-- //Hotel gllery -->
