import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }
  deserialize(date: any): Date {
    if (date == null) {
      return date;
    } else {
      return new Date(date);
    }
  }
}

@JsonConverter
export class IntConverter implements JsonCustomConvert<String> {
  serialize(string: string): any {
    if (isNaN(parseInt(string))) {
      return null;
    } else {
      return parseInt(string);
    }
  }
  deserialize(id: any): any {
    if (isNaN(parseInt(id))) {
      return null;
    } else {
      return parseInt(id);
    }
  }
}

@JsonConverter
export class FloatConverter implements JsonCustomConvert<any> {
  serialize(id: any): any {
    if (isNaN(parseFloat(id))) {
      return null;
    } else {
      return parseFloat(id);
    }
  }
  deserialize(id: any): any {
    if (isNaN(parseFloat(id))) {
      return null;
    } else {
      return parseFloat(id);
    }
  }
}

@JsonConverter
export class NullableString implements JsonCustomConvert<String> {
  serialize(string: string): any {
    if (string == null) {
      return "";
    } else {
      return string.toString();
    }
  }
  deserialize(string: any): any {
    if (string == null) {
      return "";
    } else {
      return string.toString();
    }
  }
}

@JsonConverter
export class BooleanConverter implements JsonCustomConvert<String> {
  serialize(string: any): any {
    if (string == true) {
      return 1;
    } else {
      return 0;
    }
  }
  deserialize(string: any): any {
    if (string == null || string == false) {
      return false;
    } else {
      return true;
    }
  }
}

@JsonConverter
export class NumberStringConvertor implements JsonCustomConvert<String> {
  serialize(value: any): any {
    return String(value);
  }
  deserialize(value: any): any {
    return String(value);
  }
}

@JsonConverter
export class ObjectConverter implements JsonCustomConvert<Object> {
  serialize(value: any): any {
    return Object(value);
  }
  deserialize(value: any): any {
    return Object(value);
  }
}
