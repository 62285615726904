<div class="order-details-guest-wrapper"  *ngFor="let order of RestaurantOrder ; let i = index">
    <div class="order-id-guest d-flex justify-content-between">
        <div class="d-flex">
            <span class="font-16 nunito">Order</span>
            <span class="font-16 fw-600 ml-5 nunito">{{order.orderId}}</span>
        </div>

        <span class="order-date-guest font-14 nunito">{{ order.CreatedAt| date:'dd MMM, hh:mm a' }}</span>


    </div>
    <div class="guest-amount-detail d-flex nunito black" >
        <span class="font-16" *ngIf="order.PaymentStatus === 2">Amount to be paid</span>
            <span class="font-16 fw-600 ml-12 d-flex align-items-center" *ngIf="order.PaymentStatus === 2"> <span class="secondary-text" [innerHTML]="currencyLogo!=null?currencyLogo:currencyAbri"></span>
            {{ totalAmounts[i] }}</span>
        
       
    </div>

    <div class="order-details-guest-link d-flex align-items-center justify-content-between flex-wrap">
        <div class="order-details-guest d-flex flex-column black">
            <h3 class="font-14 nunito fw-600">Restaurant Name</h3>
            <span class="mtop-5 nunito font-14">{{ order.ResturantName?order.ResturantName[0].Value:'' }}</span>
        </div>
        <div class="order-details-guest d-flex flex-column black">
            <h3 class="font-14 nunito fw-600">Payment status</h3>
    <span ngClass="{{order.PaymentStatus === 1 ?'order-status-guest d-flex align-items-center mtop-5 nunito font-14':'order-status-guest-status d-flex align-items-center mtop-5 nunito font-14 '}}">{{ order.PaymentStatus === 1 ? 'Paid' : 'Unpaid' }}</span>
        </div>
        <div class="order-details-guest d-flex flex-column  black">
            <h3 class="font-14 nunito fw-600 nunito font-14">Items Ordered</h3>
            <span class="mtop-5">{{ order.Items.length }}</span>
        </div>
        <div class="order-details-guest d-flex flex-column black">
            <h3 class="font-14 nunito fw-600">Assigned Staff</h3>
            <ng-container *ngFor="let staff of order.AssignedStaffNames; let last = last">
                <span class="mtop-5 nunito font-14">{{ staff? staff :'Not Assigned' }}{{ !last ? ', ' : '' }}</span>
            </ng-container>
        </div>

    </div>

    <a  [routerLink]="'/guest/restaurant-payment/sucess/order-track/'+order._id"  class="more-details-option nunito font-16">To know more you can click here</a>
</div>