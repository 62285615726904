<div class="sign_area d-flex justify-content-center align-items-center" style="height:100vh">
  <form #loginForm="ngForm" style="width:400px; margin:auto;">
    <!-- <img src="assets/images/logo.png" style="margin:30px auto; display:block;"> -->
    <div class="signbox_area ">
      <div class="sign_inner">
        <h3>Log in</h3>
        <p>Enter your login details below</p>
        <button class="close-btn" mat-dialog-close>
          <i class="material-icons">close</i>
        </button>
        <div class="form-group">
          <mat-form-field>
            <span matPrefix>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28px" height="20px" viewBox="0 0 485.211 485.211">
                <g>
                  <path fill="#9fa1c3" d="M485.211,363.906c0,10.637-2.992,20.498-7.785,29.174L324.225,221.67l151.54-132.584
                                            c5.895,9.355,9.446,20.344,9.446,32.219V363.906z M242.606,252.793l210.863-184.5c-8.653-4.737-18.397-7.642-28.908-7.642H60.651
                                            c-10.524,0-20.271,2.905-28.889,7.642L242.606,252.793z M301.393,241.631l-48.809,42.734c-2.855,2.487-6.41,3.729-9.978,3.729
                                            c-3.57,0-7.125-1.242-9.98-3.729l-48.82-42.736L28.667,415.23c9.299,5.834,20.197,9.329,31.983,9.329h363.911
                                            c11.784,0,22.687-3.495,31.983-9.329L301.393,241.631z M9.448,89.085C3.554,98.44,0,109.429,0,121.305v242.602
                                            c0,10.637,2.978,20.498,7.789,29.174l153.183-171.44L9.448,89.085z" />
                </g>
              </svg>
            </span>
            <input matInput placeholder="Username" [(ngModel)]="loginModel.UserId" type="text" name="UserId" required>
            <!-- <mat-error *ngFor="let error of globalService.containFormError(loginForm, 'UserId')">
              {{error}}
            </mat-error> -->
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <span matPrefix>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28" width="28" height="24">
                <path fill="#9fa1c3" d="M0 25.05L22 25.05L22 28L0 28L0 25.05ZM17 11.3L17 5.7C17 2.56 14.2 0 10.75 0C7.3 0 4.5 2.56 4.5 5.7L4.5 11.3L0 11.3L0 24.07L22 24.07L22 11.3L17 11.3ZM5.5 5.7C5.5 3.1 7.85 0.98 10.75 0.98C13.64 0.98 16 3.1 16 5.7L16 11.3L5.5 11.3L5.5 5.7ZM13 20.14C13 21.22 12.1 22.11 11 22.11C9.9 22.11 9 21.22 9 20.14L9 17.19C9 16.11 9.9 15.23 11 15.23C12.1 15.23 13 16.11 13 17.19L13 20.14Z" />
              </svg>
            </span>
            <input matInput placeholder="Password" [(ngModel)]="loginModel.Password" type="password" name="Password"
              required>
            <!-- <mat-error *ngFor="let error of globalService.containFormError(loginForm, 'Password')">
              {{error}}
            </mat-error> -->
          </mat-form-field>
        </div>
        <button mat-flat-button class="sign-btn" color="primary" (click)="userLogin(loginForm)">Start
          Now</button>
        <small>Need help? <a href="javascript:void(0);">Click Here</a></small>
      </div>
      <!-- <div class="sign-bottom">
        <h4>Don't have account?</h4>
        <button (click)="openSignUpModal()" mat-flat-button class="">Sign Up</button>
      </div> -->
    </div>
  </form>
</div>