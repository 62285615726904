import { JsonObject, JsonProperty } from 'json2typescript';
import { IntConverter, NullableString } from '../essentials';

@JsonObject("LoginModel")
export class LoginModel {
    @JsonProperty("UserId", IntConverter, true)
    UserId: number = undefined;

    @JsonProperty("Password", NullableString, true)
    Password: number = undefined;
}