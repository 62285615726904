import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UiKitComponent } from "./ui-kit/ui-kit.component";
import { MaterialModule } from "./material/material.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { LanguageLoader } from "./core/classes/CustomLoader";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
// import { RatingModule } from "ng-starrating";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { GoogleMapsModule } from "@angular/google-maps";
import { CarouselModule } from "ngx-owl-carousel-o";
// import {  } from '@ctrl/ngx-emoji-mart';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [AppComponent, UiKitComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    MaterialModule,
    CKEditorModule,
    GoogleMapsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LanguageLoader
      }
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SuperAdminModule,
    CarouselModule 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
