<app-loader [ShowLoader]="globalService.showLoader"></app-loader>
<div class="inner-loader" *ngIf="showLoader">
    <mat-progress-spinner class="tp-margin" [color]="color" [mode]="mode">
    </mat-progress-spinner>
</div>
<form [formGroup]="addNewHotelGuide" class="form-wrapper">
    <div class="modal-header clearfix">
        <h3 *ngIf="insertStatus">{{ 'Addnewguide'|translate }}</h3>
        <h3 *ngIf="updateStatus">{{ 'Updateguide'|translate }}</h3>

        <div class="lang-drop-menu">
            <span>{{ 'EditTextFor'|translate }}:</span>
            <app-language-save [popupStatus]='setPopupStatus' (selectedLang)="switchLanguage($event)"></app-language-save>
        </div>
        <button class="material-icons" mat-dialog-close>close</button>
    </div>
    <div class="modal-body-content">
        <mat-form-field class="col-lg-12 pad0">
            <mat-label>{{'Title'|translate}}</mat-label>
            <input matInput type="text" formControlName="TitleGuide">
        </mat-form-field>
        <div class="col-lg-12 pad0">
          <p class="label-info"><strong>{{ 'UploadPdfFile'|translate }}</strong></p>
          <file-upload (upload)="upload($event)" [locationStatus]="'pdfUpload'" [imgUrl]="imgUrl" [fileName]="currentFileName">
          </file-upload>
          <!-- <div class="uload-image">
              <div class="upload">
                  <span><i class="material-icons">file_upload</i>{{ 'Click to upload pdf file'|translate }}</span>
                  <input #file type="file" (change)="preview(file.files)" />
              </div>
          </div> -->
        </div>
        <div class="textarea-field-cs">
            <!-- <mat-form-field class="col-lg-12 pad0">
                <textarea matInput placeholder="Leave a comment" formControlName="HotelGuideDescription"></textarea>
            </mat-form-field> -->
            <ckeditor class="ckeditor-custom" [editor]="Editor" [formControl]="addNewHotelGuide.controls['HotelGuideDescription']" [config]="config">
            </ckeditor>
        </div>
        <!-- <div class="col-lg-12 pad0">
            <p class="label-info"><strong>{{ 'Uploaded pdf file'|translate }}</strong></p>

            <div class="uploaded-file-pdf">
                <span class="file-type-img"><img src="assets/images/pdf-icon.png" alt="Pdf Icon"></span>
                <span class="file-name">this_is_file_name.pdf</span>
                <i class="material-icons">close</i>
            </div>
        </div> -->
    </div>
    <div class="modal-footer-content">
        <a (click)="add(addNewHotelGuide)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="insertStatus">{{'Submit'|translate}}</a>

        <a (click)="add(addNewHotelGuide)" class="waves-effect waves-light btn modal-close green modal-trigger submit-btn" *ngIf="updateStatus">{{'Update'|translate}}</a>

        <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn">
            <i class="material-icons">close</i>{{'Cancel'|translate}}
        </a>
    </div>
</form>
