import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MasterSuperComponent } from './components/master-super/master-super.component';

@NgModule({
  declarations: [DashboardComponent, MasterSuperComponent],
  imports: [
    CommonModule,
    SuperAdminRoutingModule
  ]
})
export class SuperAdminModule { }
