import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../models/response/base.model';

@Injectable()
export class ForgetPasswordService {

    constructor(private apiService:ApiService) { }

    forget(reqModel): Observable<ResultModel> {
      const route = '/hotel/forget';
      return this.apiService.post<ResultModel>(route, reqModel);
    }

  
}
